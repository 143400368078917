import React, { useContext, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Drawer,
  Fab,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import useStyles from "./styles";
import api from "../../../services/api";

import { RangeContext } from "../../client/range";
import Dashboard from "../../../components/dashboard";
import {
  ArrowForwardIosOutlined,
  FilterAltOutlined,
  InfoOutlined,
} from "@mui/icons-material";

export default function HomeAdm() {
  const [snackbar, setSnackbar] = useState(null);
  const { Range, setRange } = useContext(RangeContext);
  const [loading, setLoading] = useState(false);
  const [loadingPrefix, setLoadingPrefix] = useState(false);
  const [loadingRecentEvents, setLoadingRecentEvents] = useState(false);
  const [loadingNetworkSummary, setLoadingNetworkSummary] = useState(false);
  const [loadingOpTraffic, setLoadingOpTraffic] = useState(false);
  const [loadingOpTrafficPps, setLoadingOpTrafficPps] = useState(false);
  const [fistRender, setFistRender] = useState(false);
  const [controller, setController] = useState(null);
  const [rangeManipulate, setRangeManipulate] = useState(null);
  const [openDrawerMobile, setOpenDrawerMobile] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [loadingClentes, setLoadingClientes] = useState(false);
  const theme = useTheme();
  const styles = useStyles();

  function CircularProgressWithLabel({ value = 0 }) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          variant={value <= 0 || value >= 60 ? "indeterminate" : "determinate"}
          value={value * (5 / 3)}
          sx={{ color: "secondary" }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" component="div" color="primary">
            {value <= 0 || value >= 60 ? 0 : `${Math.round(value)}`}
          </Typography>
        </Box>
      </Box>
    );
  }

  // Uso de api para receber a lista de clientes
  async function apiGetClientes() {
    setLoadingClientes(true);
    try {
      const response = await api.get("/cliente/home/getClient");
      setClientes(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingClientes(false);
    }
  }

  // Filtragem de clientes a serem listados
  const clientesUnicos = new Map();
  clientes.forEach((cliente) => {
    if (!clientesUnicos.has(cliente.as)) {
      clientesUnicos.set(cliente.as, cliente);
    }
  });
  const clientesFiltrado = Array.from(clientesUnicos.values());

  // Definição de valores padrão
  const [data, setData] = useState({
    mitigation_bps: [],
    mitigation_pps: [],
  });

  const [networkSummary, setNetworkSummary] = useState([
    { networkSummary: [], networkSummaryPps: [] },
  ]);
  const [prefix, setPrefix] = useState([]);
  const [recentEvents, setRecentEvents] = useState([]);

  useEffect(() => {
    setRange({
      range: "hour",
      unidade: "bps",
      cliente: null,
      timer: 0,
      timerStatus: false,
      escala: "padrao",
    });
    setRangeManipulate(JSON.parse(JSON.stringify(Range)));
    apiGetClientes();

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
  }, []);

  // Redefinição de valores de tráfego
  function initialValues() {
    setData({
      mitigation_bps: [],
      mitigation_pps: [],
    });
    setNetworkSummary([{ networkSummary: [], networkSummaryPps: [] }]);
    setPrefix([]);
    setRecentEvents([]);
  }

  // Temporizador
  useEffect(() => {
    if (fistRender) {
      fetchData(); // Requisição inicial sem sinal
      setFistRender(false);
    }

    const timer = setInterval(() => {
      if (Math.round(Range.timer) >= 60 && !loading) {
        fetchData(); // Requisição periódica
      }
      if (!loading) {
        setRange((prevRange) => ({
          ...prevRange,
          timer: prevRange.timer + 1,
        }));
      }
    }, 1000); // Chama a cada 1 segundo

    return () => {
      clearInterval(timer); // Limpar o intervalo ao desmontar
    };
  }, [fistRender, loading, Range.timer]);

  // Requisição ao efetuar mudanças
  useEffect(() => {
    //setRange(JSON.parse(JSON.stringify(rangeManipulate)));
    if (Range.cliente?.id) {
      setFistRender(true);
    }
  }, [Range?.cliente?.id, Range?.range]);

  // Requisição dos dados das dashs
  const fetchData = async () => {
    if (controller) {
      controller.abort(); // Abortar a requisição anterior
    }

    const newController = new AbortController(); // Criar novo AbortController
    const signal = newController.signal; // Obter o sinal

    setController(newController); // Guardar o novo controller no estado

    try {
      if (Range?.cliente?.id) {
        setLoading(true);
        setRange((prev) => ({ ...prev, timerStatus: true, timer: -100 }));
        await Promise.all([
          apiHandleNetworkSummary(signal),
          //apiHandleRecentEvents(signal),
          apiHandleOpTraffic(signal),
          apiHandlePrefix(signal),
        ]);
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Erro durante a solicitação de rotas"
        }`,
        severity: "error",
      });
    } finally {
      setLoading(false);

      setRange((prev) => ({ ...prev, timerStatus: true, timer: 0 }));

      setFistRender(false);
    }
  };

  async function apiHandleOpTraffic(signal) {
    try {
      setLoadingRecentEvents(true);
      setLoadingOpTraffic(true);
      const response = await api.post(
        "/zabbix/findHistory",
        {
          clienteId: Range?.cliente?.id || "",
          range: Range.range || "hour",
        },
        { signal }
      );
      if (response.data.status === "Error") {
        console.log(
          "Error: Não foi possível carregar os valores de tráfego bps"
        );
      } else {
        if (response.data) {
          setData((prevData) => ({
            ...prevData,
            mitigation_bps: response?.data?.traffic || [],
            mitigation_pps: response?.data?.trafficPps || [],
          }));
          setRecentEvents(response?.data?.wanguardTable || []);
        }
      }
    } catch (error) {
      if (error.name === "CanceledError") {
        console.log("Requisição de tráfego bps cancelada");
      } else {
        setSnackbar({
          children: `Error:${
            error?.response?.data?.message ||
            "Não foi possível se buscar valores de tráfego"
          } `,
          severity: "error",
        });
      }
    } finally {
      setLoadingOpTraffic(false);
      setLoadingRecentEvents(false);
    }
  }

  /*   async function apiHandleOpTrafficPps(signal) {
    try {
      setLoadingOpTrafficPps(true);
      const response = await api.post(
        "/zabbix/findHistoryPps",
        {
          clienteId: Range?.cliente?.id || "",
          range: Range.range || "hour",
        },
        { signal }
      );
      if (response.data.status === "Error") {
        console.log("Error: Não foi possível carregar os valores");
      } else {
        if (response.data) {
          setData((prevData) => ({
            ...prevData,
            mitigation_pps: response?.data || [],
          }));
        }
      }
    } catch (error) {
      if (error.name === "CanceledError") {
        console.log("Requisição de tráfego pps cancelada");
      } else {
        setSnackbar({
          children: `Error:${
            error?.response?.data?.message ||
            "Não foi possível se buscar valores de tráfego pps"
          } `,
          severity: "error",
        });
      }
    } finally {
      setLoadingOpTrafficPps(false);
    }
  } */

  async function apiHandleNetworkSummary(signal) {
    try {
      setLoadingNetworkSummary(true);
      const response = await api.post(
        "/interno/nexus/networkSummary",
        {
          clienteId: Range?.cliente?.id || "",
          range: Range.range || "hour",
        },
        { signal }
      );
      if (response.data.status === "Error") {
        console.log("Error: Não foi possível carregar os valores");
      } else {
        if (response.data) {
          setNetworkSummary(() => ({
            ...networkSummary,
            networkSummary: response.data.networkSummary || [],
            networkSummaryPps: response.data.networkSummaryPps || [],
          }));
        }
      }
    } catch (error) {
      if (error.name === "CanceledError") {
        console.log("Requisição de networkSummary cancelada");
      } else {
        setSnackbar({
          children: `Error:${
            error?.message || "Não foi possível buscar informações da rede"
          } `,
          severity: "error",
        });
      }
    } finally {
      setLoadingNetworkSummary(false);
    }
  }

  /* async function apiHandleRecentEvents(signal) {
    try {
      setLoadingRecentEvents(true);
      const response = await api.post(
        "/wanguard/findTable",
        {
          clienteId: Range?.cliente?.id || "",
          range: Range.range || "hour",
        },
        { signal }
      );
      if (response.data.status === "Error") {
        console.log("Error: Não foi possível carregar os valores");
      } else {
        if (response.data) {
          setRecentEvents(response.data);
        }
      }
    } catch (error) {
      if (error.name === "CanceledError") {
        console.log("Requisição de eventos recentes cancelada");
      } else {
        setSnackbar({
          children: `Error:${
            error?.message || "Não foi possível buscar eventos Recentes"
          } `,
          severity: "error",
        });
      }
    } finally {
      setLoadingRecentEvents(false);
    }
  }
 */
  async function apiHandlePrefix(signal) {
    try {
      setLoadingPrefix(true);
      const response = await api.post(
        "/interno/nexus/prefix",
        {
          clienteId: Range?.cliente?.id || "",
        },
        { signal }
      );
      if (response.data.status === "Error") {
        console.log("Error: Não foi possível carregar os valores");
      } else {
        if (response.data) {
          setPrefix(() => response.data || []);
        }
      }
    } catch (error) {
      if (error.name === "CanceledError") {
        console.log("Requisição de prefixos cancelada");
      } else {
        setSnackbar({
          children: `Error:${
            error?.message || "Não foi possível buscar prefixos"
          } `,
          severity: "error",
        });
      }
    } finally {
      setLoadingPrefix(false);
    }
  }

  return (
    <Box sx={styles.container}>
      {/* Barra de filtros */}
      {windowWidth > 744 && (
        <Box sx={styles.filterBar}>
          <Box sx={styles.filterGroup}>
            <Box sx={styles.filterGroupItem}>
              <Typography sx={styles.filterTypography}>Cliente</Typography>
              <Box>
                <Autocomplete
                  sx={{ ...styles.formControl, minWidth: "280px" }}
                  value={Range?.cliente}
                  onChange={(event, newValue) => {
                    setRange({
                      ...Range,
                      cliente: newValue,
                      range: "hour",
                    });
                  }}
                  size="small"
                  options={clientesFiltrado}
                  getOptionLabel={(option) => option.nomeFantasia}
                  renderInput={(params) => <TextField {...params} />}
                />
                {loadingClentes && <LinearProgress />}
              </Box>
            </Box>
            <Box sx={styles.filterGroupItem}>
              <Typography sx={styles.filterTypography}>
                Filtro de tempo
              </Typography>
              <FormControl sx={styles.formControl} size="small">
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  value={Range?.range || ""}
                  onChange={(event) => {
                    setRange({
                      ...Range,
                      range: event.target.value,
                    });
                  }}
                >
                  <MenuItem value={"hour"}>1 Hora</MenuItem>
                  <MenuItem value={"2hours"}>2 Horas</MenuItem>
                  <MenuItem value={"4hours"}>4 Horas</MenuItem>
                  <MenuItem value={"6hours"}>6 Horas</MenuItem>
                  <MenuItem value={"12hours"}>12 Horas</MenuItem>
                  <MenuItem value={"day"}>Dia</MenuItem>
                  <MenuItem value={"week"}>Semana</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{ display: "flex", flexGrow: 1, justifyContent: "flex-end" }}
            >
              {window.location.pathname === "/interno/dash" && (
                <>
                  {windowWidth <= 1370 ? (
                    <>
                      {/* Filtros Modo Desk */}
                      {Range?.timerStatus && Range?.cliente?.id && (
                        <CircularProgressWithLabel
                          value={parseInt(Range?.timer) || 0}
                        />
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Box>
          </Box>
          <Box sx={styles.filterGroup}>
            {window.location.pathname === "/interno/dash" && (
              <>
                {windowWidth > 1370 ? (
                  <>
                    {/* Filtros Modo Desk */}
                    {Range?.timerStatus && Range?.cliente?.id && (
                      <CircularProgressWithLabel
                        value={parseInt(Range?.timer) || 0}
                      />
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
            <Box sx={styles.filterGroupItem}>
              <Typography sx={styles.filterTypography}>Unidade</Typography>
              <FormControl sx={styles.formControl} size="small">
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  value={Range?.unidade || ""}
                  onChange={(event) => {
                    setRange({
                      ...Range,
                      unidade: event.target.value,
                    });
                  }}
                >
                  <MenuItem value={"bps"}>bps</MenuItem>
                  <MenuItem value={"pps"}>pps</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <FormControl
              sx={{
                height: "40px",
                transform: "translateY(-8px)",
              }}
              onChange={(event) => {
                setRangeManipulate({
                  ...rangeManipulate,
                  escala: event.target.value,
                });
                setRange({
                  ...Range,
                  escala: event.target.value,
                });
              }}
            >
              <FormLabel sx={styles.labelCaption}>Escala</FormLabel>
              <RadioGroup
                defaultValue={Range?.escala}
                name="radio-buttons-group"
                sx={{
                  transform: "translateY(-8px)",
                }}
                row
              >
                <FormControlLabel
                  value="padrao"
                  control={<Radio />}
                  label={
                    <Box sx={styles.filterMenuRadioRow}>
                      <Typography sx={styles.filterMenuRadioTypography}>
                        Maior valor
                      </Typography>
                      <Tooltip
                        title={
                          <Box sx={styles.filterMenuTooltip}>
                            Define a escala para tráfego limpo e tráfego sujo de
                            acordo com o maior valor entre os dois.
                          </Box>
                        }
                        arrow
                      >
                        <InfoOutlined sx={styles.iconInfoOutlined} />
                      </Tooltip>
                    </Box>
                  }
                />

                <FormControlLabel
                  sx={{ marginRight: 0 }}
                  value="individual"
                  control={<Radio />}
                  label={
                    <Box sx={styles.filterMenuRadioRow}>
                      <Typography sx={styles.filterMenuRadioTypography}>
                        Individual
                      </Typography>
                      <Tooltip
                        title={
                          <Box sx={styles.filterMenuTooltip}>
                            Define a escala de exibição do gráfico
                            individualmente, com base no maior valor presente em
                            cada gráfico.
                          </Box>
                        }
                        arrow
                      >
                        <InfoOutlined sx={styles.iconInfoOutlined} />
                      </Tooltip>
                    </Box>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      )}
      {/* Botao Filtro Mobile */}
      {windowWidth < 745 && (
        <Tooltip title={"Filtro"} placement="top-start">
          <Fab
            onClick={() => {
              setOpenDrawerMobile(true);
              setRangeManipulate(JSON.parse(JSON.stringify(Range)));
            }}
            size="small"
            fontSize="large"
            color="primary"
            sx={{
              position: "fixed",
              bottom: "30px",
              right: "30px",
            }}
          >
            <FilterAltOutlined />
          </Fab>
        </Tooltip>
      )}
      {/* Filtros Mobile */}
      <Drawer
        open={openDrawerMobile}
        onClose={() => {
          setOpenDrawerMobile(false);
          setRange(JSON.parse(JSON.stringify(rangeManipulate)));
        }}
        anchor={"right"}
      >
        <Paper sx={styles.mobileDrawerPaper}>
          <Box sx={styles.mobileDrawerBox}>
            <Box sx={styles.mobileDrawerHeader}>
              <IconButton
                sx={{ marginRight: "auto" }}
                onClick={() => {
                  setOpenDrawerMobile(false);
                  if (rangeManipulate?.cliente?.id) {
                    setFistRender(true);
                  }
                  setRange(JSON.parse(JSON.stringify(rangeManipulate)));
                }}
              >
                <ArrowForwardIosOutlined fontSize="small" />
              </IconButton>
              <Typography
                sx={{
                  position: "absolute",
                  marginRight: "auto",
                }}
                variant="mySubtitle2"
              >
                Filtrar cliente
              </Typography>
            </Box>

            <Autocomplete
              sx={styles.mobileDrawerFormControl}
              value={rangeManipulate?.cliente}
              onChange={(event, newValue) => {
                setRangeManipulate({
                  ...rangeManipulate,
                  cliente: newValue,
                  range: "hour",
                });
              }}
              size="small"
              options={clientesFiltrado}
              getOptionLabel={(option) => option.nomeFantasia}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Cliente"
                  variant="filled"
                  color="primary"
                />
              )}
            />

            <FormControl sx={styles.mobileDrawerFormControl} size="small">
              <InputLabel
                id="demo-simple-select-label"
                sx={{ marginTop: "11px" }}
              >
                Filtro de tempo
              </InputLabel>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={rangeManipulate?.range || ""}
                onChange={(event) => {
                  setRangeManipulate({
                    ...rangeManipulate,
                    range: event.target.value,
                  });
                }}
                label="Filtro de tempo"
                sx={{ color: "primary" }}
                variant="filled"
              >
                <MenuItem value={"hour"}>1 Hora</MenuItem>
                <MenuItem value={"2hours"}>2 Horas</MenuItem>
                <MenuItem value={"4hours"}>4 Horas</MenuItem>
                <MenuItem value={"6hours"}>6 Horas</MenuItem>
                <MenuItem value={"12hours"}>12 Horas</MenuItem>
                <MenuItem value={"day"}>Dia</MenuItem>
                <MenuItem value={"week"}>Semana</MenuItem>
                {/* <MenuItem value={"month"}>Mês</MenuItem> */}
              </Select>
            </FormControl>

            <FormControl sx={styles.mobileDrawerFormControl} size="small">
              <InputLabel
                id="demo-simple-select-label"
                sx={{ marginTop: "11px" }}
              >
                Unidade
              </InputLabel>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={rangeManipulate?.unidade || ""}
                onChange={(event) => {
                  setRangeManipulate({
                    ...rangeManipulate,
                    unidade: event.target.value,
                  });
                }}
                label="Unidade"
                sx={{ color: "primary" }}
                variant="filled"
              >
                <MenuItem value={"bps"}>bps</MenuItem>
                <MenuItem value={"pps"}>pps</MenuItem>
              </Select>
            </FormControl>

            <FormControl
              sx={styles.filterMenuRadioGroup}
              onChange={(event) => {
                setRangeManipulate({
                  ...rangeManipulate,
                  escala: event.target.value,
                });
              }}
            >
              <FormLabel>Escala</FormLabel>
              <RadioGroup
                defaultValue={rangeManipulate?.escala || "padrao"}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="padrao"
                  control={<Radio />}
                  label={
                    <Box sx={styles.filterMenuRadioRow}>
                      <Typography sx={styles.filterMenuRadioTypography}>
                        Maior valor
                      </Typography>
                      <Tooltip
                        title={
                          <Box sx={styles.filterMenuTooltip}>
                            Define a escala para tráfego limpo e tráfego sujo de
                            acordo com o maior valor entre os dois.
                          </Box>
                        }
                        arrow
                      >
                        <InfoOutlined sx={styles.iconInfoOutlined} />
                      </Tooltip>
                    </Box>
                  }
                />

                <FormControlLabel
                  value="individual"
                  control={<Radio />}
                  label={
                    <Box sx={styles.filterMenuRadioRow}>
                      <Typography sx={styles.filterMenuRadioTypography}>
                        Individual
                      </Typography>
                      <Tooltip
                        title={
                          <Box sx={styles.filterMenuTooltip}>
                            Define a escala de exibição do gráfico
                            individualmente, com base no maior valor presente em
                            cada gráfico.
                          </Box>
                        }
                        //placement="top-start"
                        arrow
                      >
                        <InfoOutlined sx={styles.iconInfoOutlined} />
                      </Tooltip>
                    </Box>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Paper>
      </Drawer>
      {/* Dashboard */}
      <Dashboard
        loadingOpTraffic={loadingOpTraffic}
        loadingOpTrafficPps={loadingOpTrafficPps}
        data={data}
        prefix={prefix}
        loading={loading}
        loadingPrefix={loadingPrefix}
        loadingNetworkSummary={loadingNetworkSummary}
        networkSummary={networkSummary}
        recentEvents={recentEvents}
        loadingRecentEvents={loadingRecentEvents}
        snackbar={snackbar}
        setSnackbar={setSnackbar}
      />
    </Box>
  );
}
