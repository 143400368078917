import { React, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  useTheme,
  Paper,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import validarIp from "../../../../utils/validadorIp";
import useStyles from "./styles";
import apiCliente from "../../../../services/apiCliente";

export default function VlanCliente(params) {
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const [vlans, setVlans] = useState({});
  const styles = useStyles(theme);
  useEffect(() => {
    getAllVlans();
  }, []);

  async function getAllVlans() {
    setLoading(true);
    try {
      const response = await apiCliente.post("/solicitacoes/get/vlan", {
        clienteId: JSON.parse(
          localStorage.getItem("userCliente")
        )?.usuarioCliente?.clientId?.toString(),
      });
      setVlans(response?.data);
      setLoading(false);
    } catch (e) {
      console.error("erro ao buscar prefixos ss", e);
    }
  }

  return (
    <Box sx={{ width: "100%" }}>
      {loading ? (
        <Box sx={styles.containerLoading}>
          {" "}
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          {Array.isArray(vlans) &&
            vlans?.map(
              (vlan, index) =>
                validarIp(vlan?.ipLocal, "justIpv4") === 1 &&
                validarIp(vlan?.ipCliente, "justIpv4") === 1 && (
                  <Grid item lg={12} xl={6}>
                    <Paper
                      variant="outlined"
                      key={vlan}
                      value={index}
                      sx={styles.paper}
                    >
                      <Box sx={styles.cardHeader}>
                        <Box>
                          <Typography
                            variant="caption"
                            sx={styles.cardSubTitle}
                          >
                            Título da Vlan
                          </Typography>
                          <Typography>{vlan?.titulo}</Typography>
                        </Box>
                      </Box>
                      <Box sx={styles.cardRow}>
                        <Box sx={styles.cardItem}>
                          <Typography
                            variant="caption"
                            sx={styles.cardSubTitle}
                          >
                            IP Local
                          </Typography>

                          <Tooltip title={vlan?.ipLocal}>
                            <span
                            /*  onClick={() => {
                                  handleCopy(vlan?.ipLocal);
                                }} */
                            >
                              <Typography sx={styles.cardText}>
                                {vlan?.ipLocal}
                              </Typography>
                            </span>
                          </Tooltip>
                        </Box>
                        <Box sx={styles.cardItem}>
                          <Typography
                            variant="caption"
                            sx={styles.cardSubTitle}
                          >
                            IP Cliente
                          </Typography>

                          <Tooltip title={vlan?.ipCliente}>
                            <span
                            /* onClick={() => {
                                  handleCopy(vlan?.ipCliente);
                                }} */
                            >
                              <Typography sx={styles.cardText}>
                                {vlan?.ipCliente}
                              </Typography>
                            </span>
                          </Tooltip>
                        </Box>
                      </Box>
                      <Box sx={styles.cardRow}>
                        <Box sx={styles.cardItem}>
                          <Typography
                            variant="caption"
                            sx={styles.cardSubTitle}
                          >
                            ASN Vlan
                          </Typography>
                          <Tooltip title={vlan?.asnVlan}>
                            <span
                            /* onClick={() => {
                                  handleCopy(vlan?.asnVlan);
                                }} */
                            >
                              <Typography sx={styles.cardText}>
                                {vlan?.asnVlan}
                              </Typography>
                            </span>
                          </Tooltip>
                        </Box>
                        <Box sx={styles.cardItem}>
                          <Typography
                            variant="caption"
                            sx={styles.cardSubTitle}
                          >
                            Vlan ID
                          </Typography>
                          <Tooltip title={vlan?.vlanId}>
                            <span
                            /*  onClick={() => {
                                  handleCopy(vlan?.vlanId);
                                }} */
                            >
                              <Typography sx={styles.cardText}>
                                {vlan?.vlanId}
                              </Typography>
                            </span>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                )
            )}
        </Grid>
      )}
    </Box>
  );
}
