const styles = (theme) => ({
  container: {
    display: "flex",
    alignItems: "start",
    flexDirection: "column",
    width: "100%",
    gap: "1.5rem",
  },
  tabs: {
    marginTop: "-11px",
  },
  paperCliente: {
    borderRadius: 1,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "20px",
    minWidth: "70vw",
    flexDirection: "column",
  },
  containerPaper: {
    padding: "21px",
    display: "flex",
    flexDirection: "column",
    gap: "13px",
    bgcolor: theme.palette.mode === "dark" ? "transparent" : "background.paper",
    width: "83%",
  },
});

export default styles;
