const styles = (props) => ({
  boxContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 2.5,
  },
  searchField: { width: "280px" },
  boxDataGrid: { width: "100%" },
  dataGrid: {
    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
      py: "5px",
    },
    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
      py: "5px",
    },
    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
      py: "22px",
    },
    fontSize: 15,
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
      outline: "none !important",
    },
  },
  boxCellView: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    height: "48px",
  },
  typographCellView: {
    fontSize: "15px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  mobile: {
    listItem: {
      display: "flex",
      flexDirection: "row",
      padding: 0,
    },
    content: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      padding: "16px",
    },
    contentRow: { display: "flex", flexDirection: "row", gap: 1 },
    typography: { opacity: "0.8" },
  },
});

export default styles;
