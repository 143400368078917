const styles = (theme) => ({
  boxContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    left: 0,
    right: 0,
    mr: "auto",
    ml: "auto",
  },
  paperContainer: {
    backgroundColor: theme.palette.mode === "dark" && "transparent",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    borderRadius: 3,
    width: "100%",
  },
  TextField: {
    width: "300px",
  },
  paperModal: {
    padding: "10px",
    borderRadius: 3,
  },
  dialogContent: { display: "flex", flexDirection: "column", gap: "20px" },
  dataGrid: {
    display: "grid",
    fontSize: 15,
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
      outline: "none !important",
    },
    ".MuiDataGrid-cell": { display: "flex", alignItems: "center" },
  },
});
export default styles;
