import {
  Box,
  Paper,
  Snackbar,
  Alert,
  Stack,
  Button,
  Tooltip,
  IconButton,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import api from "../../../../../services/api";
import { Add, Delete, Edit } from "@mui/icons-material";
import EditModalPort from "./editModalPort";
import CreateModalPort from "./createModalPort";
import useStyles from "./styles";
import { ptBR } from "@mui/x-data-grid/locales";
import InternoModalDeleteCliente from "../../../../../components/internoModalDeleteCliente";

export default function Ports(params) {
  const { clienteId } = params;
  const rows = [{ id: 14 }];
  //modal
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  //modal delete
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  //tabela
  const [ports, setPorts] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [snackbar, setSnackbar] = useState("");
  const [selectedRow, setSelectedRow] = useState([]);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [portEdit, setPortEdit] = useState({});
  const theme = useTheme();
  const styles = useStyles(theme);
  const deleteBody =
    "O item será deletado permanentemente. Deseja deletar o item?";
  const confirmDelete = "Excluir";
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      hide: true,
    },
    {
      field: "port",
      headerName: "Porta",
      width: 200,
    },
    {
      field: "operator",
      headerName: "Operador",
      width: 100,
    },
    {
      field: "void",
      headerName: "",
      width: 100,
      flex: 1,
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,

      renderCell: (params) => {
        const onClick = (e) => {
          const thisRow = params.row;

          hanldeUpDateSelectRow(thisRow);
          setOpenEditModal(true);
        };
        function hanldeUpDateSelectRow(thisRow) {
          setSelectedRow(thisRow);
          setPortEdit(thisRow);
        }
        const onDelete = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const thisRow = params.row;

          setOpenDeleteModal(true);
          setSelectedRow(thisRow);
          setPortEdit(thisRow);
        };

        return (
          <Box key={params.id}>
            <Tooltip title={"Editar"} id="basic-button" onClick={onClick}>
              <IconButton size="small">
                <Edit color="primary" />
              </IconButton>
            </Tooltip>

            <IconButton onClick={onDelete}>
              <Delete color={"secondary"} />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const limitPort = (number) => {
    let value = number;
    // Limpa os caracteres especiais de value
    value = value.replace(/[^0-9]|(?<=^.*)-/g, ""); // Remove non-digits
    // Limita o valor entre 0 and 65535
    value = Math.max(0, Math.min(65535, parseInt(value, 10)));
    return value;
  };
  useEffect(() => {
    handleApiGetPorts();
  }, []);
  function handleNewPort(newPort) {
    setPorts((ports) => [...ports, newPort]);
  }
  function handleEditPorts(portEditado) {
    setPorts((ports) =>
      ports.map((port) => {
        if (port.id === portEditado.id) {
          return portEditado;
        } else {
          return port;
        }
      })
    );
  }
  function handleDeletePort(id) {
    setPorts((ports) => ports.filter((port) => port.id !== id));
  }
  async function handleApiGetPorts() {
    try {
      setLoadingTable(true);
      const response = await api.get("/interno/findMany/port");

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar dados",
          severity: "error",
        });
      } else {
        if (response.data) {
          setPorts(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingTable(false);
    }
  }
  async function handleApiDeletePort() {
    try {
      setLoadingTable(true);

      setLoadingDelete(true);
      const response = await api.post("/interno/delete/port", {
        id: selectedRow.id,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível excluir dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados excluídos com sucesso",
          severity: "success",
        });
        if (response.data) {
          handleDeletePort(response.data.id);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingTable(false);

      setOpenDeleteModal(false);
      setLoadingDelete(false);
    }
  }
  return (
    <>
      <Box sx={styles.boxContainer}>
        <Paper sx={styles.paperContainer} elevation={3} variant={"outlined"}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            {/*  <Typography variant="h6">Ports Flow</Typography>*/}
            <Box></Box>
            <Button
              variant="contained"
              disabled={loadingTable}
              startIcon={<Add />}
              onClick={() => {
                setOpenCreateModal(true);
              }}
            >
              Adicionar
            </Button>
          </Stack>
          <DataGrid
            sx={styles.datagrid}
            autoHeight={true}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={ports}
            columns={columns}
            initialState={{
              ...rows.initialState,
              columns: {
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  id: false,
                },
              },
            }}
            loading={loadingTable}
            disableColumnMenu
            disableRowSelectionOnClick={true}
          />{" "}
        </Paper>
      </Box>{" "}
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}{" "}
      <CreateModalPort
        setOpenCreateModal={setOpenCreateModal}
        openCreateModal={openCreateModal}
        setSnackbar={setSnackbar}
        setLoadingTable={setLoadingTable}
        styles={styles}
        handleNewPort={handleNewPort}
        limitPort={limitPort}
        cliente={clienteId}
      />
      <EditModalPort
        setOpenEditModal={setOpenEditModal}
        openEditModal={openEditModal}
        setSnackbar={setSnackbar}
        setLoadingTable={setLoadingTable}
        styles={styles}
        selectedRow={selectedRow}
        handleEditPorts={handleEditPorts}
        limitPort={limitPort}
        setPortEdit={setPortEdit}
        portEdit={portEdit}
      />
      <InternoModalDeleteCliente
        openDeleteModal={openDeleteModal}
        load={loadingDelete}
        setOpenDeleteModal={setOpenDeleteModal}
        execute={handleApiDeletePort}
        severity={"error"}
        contentText={deleteBody}
        confirmText={confirmDelete}
      />
    </>
  );
}
