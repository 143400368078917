const styles = (theme) => ({
  container: { display: "flex", flexDirection: "column", gap: 3 },
  header: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  containerPaper: {
    backgroundColor: theme.palette.mode === "dark" && "transparent",
    padding: "20px",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    bgcolor: "transparent",
    gap: 2,
    borderRadius: "10px",
    padding: "20px",
  },
  cardHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  cardContent: { display: "flex", flexDirection: "column", gap: "4px" },
  cardStack: { width: "160px" },
  cardSubTitle: { opacity: 0.6, fontSize: "13px", fontWeight: 500 },
  cardText: {
    fontSize: "15px",
    fontWeight: 500,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    cursor: "pointer",
  },
});

export default styles;
