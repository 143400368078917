const styles = (theme) => ({
  container: {
    display: "flex",
    alignItems: "start",
    flexDirection: "column",
    width: "100%",
    gap: "1.5rem",
  },
  tabs: {
    marginTop: "-11px",
  },
  paperCliente: {
    borderRadius: 1,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "20px",
    minWidth: "70vw",
    flexDirection: "column",
  },
  containerPaper: {
    borderRadius: "15px",
    padding: "0px 10px",
  },
});

export default styles;
