import { Box, Paper, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import Router from "./router";
import Interfaces from "./interfaces";

import CDNs from "./cdn";
import Ports from "./port";
import useStyles from "./styles";

export default function EasyFlow() {
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const styles = useStyles(theme);
  return (
    <Paper
      sx={styles.containerPaper}
      elevation={3}
      variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
    >
      <Box sx={styles.container}>
        <Typography variant="mySubtitle">Configurações EasyFlow</Typography>
        <Tabs
          scrollButtons={true}
          value={value}
          sx={styles.tabs}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <Tab
            label={"Router"}
            iconPosition="start"
            value={0}
            sx={styles.tab}
          />
          <Tab
            label={"Interfaces"}
            iconPosition="start"
            value={1}
            sx={styles.tab}
          />

          <Tab label={"CDN's"} iconPosition="start" value={2} sx={styles.tab} />
          <Tab label={"Ports"} iconPosition="start" value={3} sx={styles.tab} />
        </Tabs>

        {value === 0 && <Router key={0} clienteId={""} />}
        {value === 1 && <Interfaces clienteId={""} key={1} />}
        {value === 2 && <CDNs clienteId={""} key={2} />}
        {value === 3 && <Ports clienteId={""} key={3} />}
      </Box>
    </Paper>
  );
}
