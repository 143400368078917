import { Box } from "@mui/system";
import { useTheme } from "@mui/system";
import useStyles from "./styles";
import { Paper, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Computize from "./computize";
import Clientes from "./clientes";
import { useNavigate } from "react-router-dom";
export default function AdminPanel() {
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const styles = useStyles(theme);
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    let result = JSON.parse(localStorage.getItem("user"));

    handleUsuarioId();

    if (handleUsuarioId() === false) {
      navigate("/interno/login");
    }
  }, []);

  const handleUsuarioId = () => {
    let result = JSON.parse(localStorage.getItem("user"));
    // Verifica se a propriedade rlPermissoesUsuario existe e é um array
    if (Array.isArray(result?.usuario?.rlPermissoesUsuario)) {
      // Itera sobre o array para verificar se algum objeto contém a propriedade codigoPermissao com valor "ADM"
      for (let permissao of result?.usuario?.rlPermissoesUsuario) {
        if (permissao?.permissao?.codigoPermissao.toUpperCase() === "ADM") {
          return true;
        }
      }
      return false;
    }
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.containerTabs}>
        <TabContext value={value}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2.6 }}>
            <Box sx={styles.containerTab}>
              <TabList onChange={handleChange}>
                <Tab label="COMPUTIZE" value={0} />
                <Tab label="CLIENTES" value={1} />
              </TabList>
            </Box>

            <TabPanel value={0} sx={{ padding: 0 }}>
              <Computize />
            </TabPanel>
            <TabPanel value={1} sx={{ padding: 0 }}>
              <Clientes />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </Box>
  );
}
