import { Box, Tab, Tabs, useTheme } from "@mui/material";
import { useState } from "react";
import Blocos from "./blocos";
import ASNs from "./asn";
import useStyles from "./styles";
import { useOutletContext } from "react-router-dom";

export default function RelatoriosFlow() {
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const styles = useStyles(theme);
  const [sb, clienteInfo, si] = useOutletContext();

  return (
    <>
      <Box sx={styles.container}>
        <Tabs
          scrollButtons={true}
          value={value}
          sx={styles.tabs}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <Tab
            label={"Blocos"}
            iconPosition="start"
            value={0}
            sx={styles.tab}
          />
          <Tab label={"ASN's"} iconPosition="start" value={1} sx={styles.tab} />
        </Tabs>

        {value === 0 && <Blocos clienteId={clienteInfo?.id} key={0} />}
        {value === 1 && <ASNs clienteId={clienteInfo?.id} key={1} />}
      </Box>
    </>
  );
}
