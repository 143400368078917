import { Svg, View, Line, Text } from "@react-pdf/renderer";
import { styles } from "./styles";
export const Footer = ({ relatorio }) => (
  <View fixed style={styles.footer}>
    <Svg style={styles.footerLine}>
      <Line
        x1="0"
        y1="0"
        x2="800"
        y2="0"
        strokeWidth={1.5}
        stroke="rgb(0,0,0)"
      />
    </Svg>
    <View style={styles.footerText}>
      <Text>{"Computize Network, todos os direitos reservados"}</Text>
      {/* <Text render={({ pageNumber }) => `${pageNumber}`} /> */}
    </View>
  </View>
);
