const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "1070px",
    gap: 3,
  },
  containerPaper: {
    backgroundColor: theme.palette.mode === "dark" && "transparent",
    padding: "20px",
  },
  content: { display: "flex", flexDirection: "column", gap: 3, width: "100%" },
  textfield: {
    width: "49%",
    minWidth: "250px",
    "@media (max-width: 1040px)": {
      width: "100%",
    },
  },
  asTextField: {
    width: "49%",
    minWidth: "250px",
    "@media (max-width: 1040px)": {
      width: "100%",
    },
    "& .MuiInputLabel-root.Mui-error": {
      color: "secondary.main",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      border: "3px solid",
      borderColor: "secondary.main",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "secondary.main",
    },
  },
  boxForm: { display: "flex", flexDirection: "column", gap: 3, width: "100%" },
});

export default styles;
