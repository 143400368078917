import { ContentCopy, Settings } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import useStyles from "./styles";
import api from "../../../../../services/api";
import { useOutletContext } from "react-router-dom";

export default function GerarConfig() {
  const theme = useTheme();
  const styles = useStyles(theme);
  const [setSnackbar, clienteInfo, setClienteInfo] = useOutletContext();
  const [errors, setErrors] = useState({});
  const [console, setConsole] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setSnackbar({
      children: "Campo copiado!",
      severity: "info",
    });
  };

  async function handleApiscrit() {
    try {
      setLoading(true);
      const response = await api.post("/juniper/script", {
        clienteId: clienteInfo.id || "",
      });
      setConsole(response.data || "");
    } catch (error) {
      setSnackbar({
        children: "Erro ao gerar configurações",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Paper
      sx={styles.containerPaper}
      elevation={3}
      variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
    >
      <Box sx={styles.container}>
        <Box sx={styles.header}>
          <Typography variant="mySubtitle">Gerar Configurações</Typography>
          <Button
            variant="contained"
            startIcon={<Settings />}
            size="medium"
            onClick={handleApiscrit}
          >
            Configurar
          </Button>
        </Box>
        <Box sx={styles.constentBox}>
          <TextField
            id="filled-terminal"
            label="Console"
            name="console"
            multiline
            rows={23}
            fullWidth
            variant="filled"
            autoComplete="off"
            value={console}
            disabled
            // InputProps={{
            // endAdornment: (
            //   <IconButton
            //     aria-label="content copy"
            //     onMouseDown={(event) => {
            //       event.preventDefault();
            //     }}
            //   >
            //     <ContentCopy />
            //   </IconButton>
            // ),
            // sx: {
            //   alignItems: "flex-start",
            // },
            // }}
          />

          <IconButton
            aria-label="content copy"
            onMouseDown={(event) => {
              event.preventDefault();
            }}
            onClick={() => {
              handleCopy(console);
            }}
            sx={styles.IconButton}
          >
            <Tooltip title={"clique para copiar"}>
              <ContentCopy />
            </Tooltip>
          </IconButton>
        </Box>
      </Box>
    </Paper>
  );
}
