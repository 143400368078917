const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "850px",
    gap: 3,
    width: "100%",
    mt: "16px",
  },
  containerPaper: {
    backgroundColor: theme.palette.mode === "dark" && "transparent",
    padding: "20px",
  },
  tituloComponent: {
    fontSize: "21px",
  },
  paper: {
    padding: "40px",
    margin: "20px 0",
    backgroundColor: "transparent",
  },
  containerCheck: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    width: "100%",
  },
  valorMbps: {
    width: "100%",
    maxWidth: "235px",
    "@media (max-width: 1040px)": {
      margin: "0 0 14px 0",
      maxWidth: "100%",
    },
  },
  divider: {
    margin: "30px 0",
  },
  subtitle: {
    margin: "0 0 10px 0",
    fontSize: "16px",
  },
  radioGrupo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  stack: {
    minHeight: "75px",
    display: "flex",
    flexDirection: "row",
    spacing: "20px",
    gap: "10px",
    "@media (max-width: 1040px)": {
      flexDirection: "column",
    },
  },
  containerExcedente: {
    margin: "20px 0",
  },
  formInput: {
    margin: "0 10px",
  },
  titlePercentil: {
    width: "200px",
    marginTop: "20px",
  },
  button: {
    margin: "0 10px",
  },
  containerButton: {
    width: "100%",
    padding: "10px",
  },
  containerDate: {
    display: "flex",
    gap: "10px",
    gap: "10px",
    "@media (max-width: 1040px)": {
      flexDirection: "column",
    },
  },
});
export default styles;
