import { Add, Delete } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  IconButton,
  Paper,
  Snackbar,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import { useEffect, useState } from "react";
import InternoModalDeleteCliente from "../../../../../components/internoModalDeleteCliente";
import api from "../../../../../services/api";

export default function ASN(params) {
  const { setChanged = () => {}, clienteInfo } = params;
  const [editAsn, setEditAsn] = useState(false);
  const [asns, setAsns] = useState([]);
  const [asnsManipulation, setAsnsManipulation] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const [errors, setErrors] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteLoad, setDeleteLoad] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    if (asns) {
      setAsnsManipulation(JSON.parse(JSON.stringify(asns)));
    }
  }, [asns]);

  useEffect(() => {
    handleApiGetAsns();
  }, []);

  async function handleApiGetAsns() {
    try {
      const response = await api.post("/interno/juniper/asn", {
        clienteId: clienteInfo?.id,
      });

      setAsns(response.data);
    } catch (error) {
      setSnackbar({
        children: `Error:${
          error?.message || "Não foi possível se buscar os asns"
        } `,
        severity: "error",
      });
    }
  }

  async function handleApiUpdateAsns() {
    try {
      setDeleteLoad(true);
      const response = await api.post("/interno/juniper/edit/asn", {
        asns: asnsManipulation,
        clienteId: clienteInfo?.id,
      });
      setAsns(response.data);
      setEditAsn(false);
      setSnackbar({
        children: "Asns atualizados com sucesso",
        severity: "success",
      });
      setChanged(false);
    } catch (error) {
      setSnackbar({
        children: `Error:${
          error?.message || "Não foi possível atualizar os asns"
        } `,
        severity: "error",
      });
    } finally {
      setDeleteLoad(false);
      setOpenDeleteModal(false);
    }
  }

  /*  function handleBlur(e) {
    const { name, value } = e.target;

    try {
      if (name === "as") {
        validarAs(value);
      }
      setErrors((prev) => ({ ...prev, [name]: "" }));
    } catch (err) {
      setErrors((prev) => ({ ...prev, [name]: err.errors[0].message }));
    }
  } */
  /*  async function validarAs(as) {
    try {
      // setLoadingASN(!editMode);
      //Checa se é vazio
      if (as?.trim() === "") {
        throw new Error("ASN não encontrado");
      }
      const response = await axios.get(
        "https://stat.ripe.net/data/as-overview/data.json",
        {
          params: {
            resource: as,
          },
        }
      ); //268659

      if (!response?.data?.data?.announced) {
        throw new Error("ASN não encontrado");
      }
      //sucesso
    } catch (error) {
      console.error(error);
      setErrors((prev) => ({
        ...prev,
        as:
          error?.message === "ASN não encontrado"
            ? error?.message
            : error?.message === "ASN é obrigatório"
            ? error?.message
            : "ASN inválido",
      }));
    } finally {
      // setLoadingASN(false);
      //setChecouAs(true);
    }
  } */

  function deleteAsn(index) {
    setChanged(true);
    const array = [...asnsManipulation];
    array.splice(index, 1);
    setAsnsManipulation(array);
  }

  function addComunidade() {
    const array = [...asnsManipulation];
    array.push({ id: parseInt(Math.random() * 1000000), asn: "" });
    setAsnsManipulation(array);
  }

  function validateFields() {
    const newErrors = {};
    asnsManipulation.forEach((asn, index) => {
      if (asn.asn.trim() === "") {
        newErrors[index] = true;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  function handleSave() {
    if (validateFields()) {
      // Obter os IDs das asns que foram manipuladas
      const comunidadesManipulationIds = asnsManipulation?.map((com) => com.id);

      // Filtrar as asns que foram removidas
      const comunidadesRemovidas = asns?.map(
        (com) => !comunidadesManipulationIds.includes(com.id)
      );

      // Se tiver alguma removida
      if (comunidadesRemovidas.includes(true)) {
        setOpenDeleteModal(true);
      } else {
        handleApiUpdateAsns(asnsManipulation);
      }
    }
  }

  return (
    <Box
      sx={{
        padding: "21px 0 0 0",
        display: "flex",
        flexDirection: "column",
        gap: "13px",
        width: "100%",
      }}
      variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        {/*  <Typography sx={{ fontSize: "21px" }}>
          Configurações de ASN's
        </Typography> */}
        {editAsn && (
          <Button variant="outlined" endIcon={<Add />} onClick={addComunidade}>
            Adicionar ASN
          </Button>
        )}
      </Box>
      <Box
        sx={{
          padding: "21px 0 0 0",
          display: "flex",
          flexDirection: "column",
          gap: "13px",
          bgcolor: "transparent",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "13px",
            flexWrap: "wrap",
            alignItems: "flex-start",
            minHeight: "48px",
          }}
        >
          {asnsManipulation.map((asn, index) => (
            <Box key={asn.id} sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                id="as"
                name="as"
                variant="filled"
                label="ASN"
                value={asn.asn}
                onChange={(e) => {
                  setChanged(true);
                  const array = [...asnsManipulation];
                  array[index].asn = e.target.value;
                  setAsnsManipulation(array);
                  setErrors((prev) => ({ ...prev, [index]: false }));
                }}
                size="small"
                autoComplete="off"
                error={!!errors[index]}
                helperText={errors[index] ? "Este campo é obrigatório" : ""}
                // onBlur={handleBlur}
                disabled={!editAsn}
                sx={{ width: "250px" }}
              />
              {editAsn ? (
                <IconButton onClick={() => deleteAsn(index)}>
                  <Delete color="primary" />
                </IconButton>
              ) : (
                <Box sx={{ width: "40px" }}></Box>
              )}
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "13px",
            justifyContent: "flex-end",
          }}
        >
          {!editAsn ? (
            <Button
              size="small"
              onClick={() => {
                setEditAsn(true);
              }}
            >
              Editar
            </Button>
          ) : (
            <>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  setChanged(false);
                  setEditAsn(false);
                  setAsnsManipulation(JSON.parse(JSON.stringify(asns)));
                  setErrors({});
                }}
              >
                Cancelar
              </Button>
              <Button variant="contained" size="small" onClick={handleSave}>
                Salvar
              </Button>
            </>
          )}
        </Box>
      </Box>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
      <InternoModalDeleteCliente
        openDeleteModal={openDeleteModal}
        load={deleteLoad}
        setOpenDeleteModal={setOpenDeleteModal}
        execute={() => {
          handleApiUpdateAsns(asnsManipulation);
        }}
        severity={"error"}
        contentText={
          "Uma ou mais ASN's foram removidos, se você prosseguir com a operação, as informações serão excluídas permanentemente!"
        }
        confirmText={"CONFIRMAR"}
      />
    </Box>
  );
}
