const styles = (props) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    //width: "75.5vw",
    //height: "500px",
    borderRadius: "12px",
    position: "relative",
    bgcolor: "background.default",
    maxWidth: "77.6vw",
    maxHeight: "80vh",
    overflow: "auto",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
    with: 1,
    padding: "20px",
  },
  avatar: { width: "80px", height: "80px" },
  headerContent: { display: "flex", flexDirection: "column" },
  headerLine: { display: "flex", flexDirection: "row", gap: 1 },
  headerItem: { display: "flex", flexDirection: "column", width: "160px" },
  headerSubtitle: { opacity: 0.6, fontSize: "13px", fontWeight: 500 },
  headerText: {
    fontSize: "15px",
    fontWeight: 500,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    padding: "20px 20px 20px 20px",
    gap: 2,
  },
  contentHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    position: "relative",
    justifyContent: "space-between",
  },
  boxMap: {
    display: "flex",
    flexDirection: "row",
    gap: 1,
    flexWrap: "wrap",
  },
  boxTextField: { minWidth: "200px" },
});

export default styles;
