import { React, useEffect, useState } from "react";
import {
  Box,
  Stack,
  TextField,
  IconButton,
  Button,
  Grid,
  Paper,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { z } from "zod";
import useStyles from "./styles";
import validarIp from "../../../../../../utils/validadorIp";
import InternoModalDeleteCliente from "../../../../../../components/internoModalDeleteCliente";
import { useOutletContext } from "react-router-dom";
import api from "../../../../../../services/api";

// Esquemas de validação usando Zod
const businessDataSchema = z.object({
  // IRR: z.string().min(1, "IRR é obrigatório"),
  prefixos: z.string().ip("Prefixos inválido"),
});

const ipSchema = z.object({
  ip: z.string().min(1, "IP é obrigatório"),
});

export default function PrefixosIpv4(params) {
  const { setChanged = () => {}, clienteInfo = {} } = params;
  const [setSnackbar] = useOutletContext();
  const styles = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const deleteBody =
    "Um ou mais prefixos foram removidos, se você prosseguir com a operação, as informações serão excluídas permanentemente!";
  const confirmDelete = "CONFIRMAR";

  // Estado para dados do formulário e erros de validação
  const [prefixos, setPrefixos] = useState([]);
  const [prefixosManipulate, setPrefixosManipulate] = useState([]);
  const [errors, setErrors] = useState({});

  //fetch data
  useEffect(() => {
    if (prefixos) {
      setPrefixosManipulate(JSON.parse(JSON.stringify(prefixos)));
    }
  }, [prefixos]);

  useEffect(() => {
    handleApiGetPrefixos();
  }, [clienteInfo]);

  async function handleApiGetPrefixos() {
    try {
      setLoading(true);
      const response = await api.post("/interno/find/prefixos", {
        clienteId: clienteInfo?.id || "",
      });

      setPrefixos(response?.data?.filter((item) => item?.tipo === 0));
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possivel se conectar ao servidor",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  // Uso de prefixos
  const handleAddPrefixo = () => {
    const array = [...prefixosManipulate];
    array.push({
      id: (Math.random() * 1000000)?.toString(),
      prefixo: "",
      tipo: 0,
    });
    setPrefixosManipulate(array);
  };

  function handleDelete(index) {
    const array = [...prefixosManipulate];
    array.splice(index, 1);
    setPrefixosManipulate(array);
  }

  // Checa se todos os campos são válidos
  function validateFields() {
    const newErrors = {};
    prefixosManipulate.forEach((prefixo, index) => {
      if (prefixo?.prefixo?.trim() === "") {
        newErrors[index] = true;
      } else {
        const isValid = validarIp(prefixo?.prefixo, "justIpv4Mask");
        if (isValid !== 1 && prefixo?.prefixo?.trim() !== "") {
          newErrors[index] = true;
        }
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  // Somente submete o formulário se todos os campos forem válidos
  function handleSave() {
    if (validateFields()) {
      // Obtem todos os prefixos exceto os vazios
      let array = prefixosManipulate?.filter((item) => item?.prefixo !== "");

      // Obter os IDs dos prefixos que foram manipulados
      const prefixosIDs = array?.map((pref) => pref.id);

      // Filtrar os prefixos que foram removidos
      const prefixosRemovidos = prefixos?.map(
        (pref) => !prefixosIDs?.includes(pref.id)
      );

      // Se tiver alguma removida
      if (prefixosRemovidos.includes(true)) {
        setOpenDeleteModal(true);
      } else {
        handleUpdatePrefixos();
      }
    }
  }

  // Função para submeter o formulário
  async function handleUpdatePrefixos() {
    try {
      setLoading(true);
      // Obtem todos os prefixos exceto os vazios
      let array = prefixosManipulate?.filter((item) => item?.prefixo !== "");

      // Chama a api sem passar os prefixos vazios
      const response = await api?.put("/interno/update/prefixo", {
        prefixos: array,
        clienteId: clienteInfo?.id || "",
        tipo: 0,
      });

      //sucesso
      setPrefixos(response?.data);
      setErrors({});
      setEditMode(false);
      setChanged(false);
      setSnackbar({
        children: "Prefixo editado com sucesso!",
        severity: "success",
      });
    } catch (error) {
      console.log(error);
      const newErrors = {};
      error?.errors?.forEach((err) => {
        newErrors[err.path[0]] = err.message;
      });
      setSnackbar({
        children: "Error: Não foi possivel se conectar ao servidor",
        severity: "error",
      });
    } finally {
      setLoading(false);
      setOpenDeleteModal(false);
    }
  }

  return (
    <Box>
      <Box sx={styles.container}>
        <Paper variant="outlined" sx={styles.paper}>
          <Box
            sx={{
              display: "flex",
              width: 1,
              justifyContent: "flex-end",
              mb: "8px",
              minHeight: "37px",
            }}
          >
            {editMode && (
              <Button
                variant="outlined"
                startIcon={<Add />}
                size="medium"
                onClick={() => {
                  handleAddPrefixo();
                }}
                disabled={loading}
              >
                NOVO PREFIXO
              </Button>
            )}
          </Box>

          <Grid container spacing={2}>
            {Array.isArray(prefixosManipulate) &&
              prefixosManipulate?.map((prefixo, index) => (
                <Grid item xs={12} md={6} lg={4} xl={3} key={prefixo?.id}>
                  <Stack flexGrow={1} direction={"row"}>
                    <TextField
                      name="prefixos"
                      label="Prefixo"
                      variant="filled"
                      autoComplete="off"
                      size="small"
                      value={prefixo?.prefixo}
                      onChange={(e) => {
                        setChanged(true);
                        const array = [...prefixosManipulate];
                        array[index].prefixo = e.target.value;
                        setPrefixosManipulate(array);
                        setErrors((prev) => ({ ...prev, [index]: false }));
                        console.log(prefixos);
                      }}
                      error={!!errors[index]}
                      helperText={errors[index] ? "Prefixo inválido" : ""}
                      disabled={!editMode}
                    />
                    {editMode && (
                      <IconButton
                        onClick={() => {
                          setChanged(true);
                          handleDelete(index);
                        }}
                        sx={styles.iconButtonPrefixos}
                      >
                        <Delete color="primary" />
                      </IconButton>
                    )}
                  </Stack>
                </Grid>
              ))}
          </Grid>

          <Box>
            <Stack direction={"row"} sx={styles.stackButtons}>
              {editMode ? (
                <>
                  <Button
                    onClick={() => {
                      setEditMode(false);
                      setChanged(false);
                      //modificar para dados recebidos no carregamento ou ultimo post
                      setPrefixosManipulate(
                        JSON.parse(JSON.stringify(prefixos))
                      );

                      setErrors({});
                    }}
                    disabled={loading}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleSave}
                    disabled={loading}
                  >
                    Salvar
                  </Button>
                </>
              ) : (
                <Button
                  onClick={() => {
                    setEditMode(true);
                  }}
                  color="primary"
                  disabled={loading}
                >
                  Editar
                </Button>
              )}
            </Stack>
          </Box>
        </Paper>
        <InternoModalDeleteCliente
          openDeleteModal={openDeleteModal}
          deleteLoad={loading}
          setOpenDeleteModal={setOpenDeleteModal}
          execute={handleUpdatePrefixos}
          severity={"error"}
          contentText={deleteBody}
          confirmText={confirmDelete}
        />
      </Box>
    </Box>
  );
}
