import { Add, Delete, Edit, MoreVert } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import useStyles from "./styles";
import { useEffect, useState } from "react";
import ModalAdicionar from "./modalAdicionar";
import ModalEditar from "./modalEditar";
import InternoModalDeleteCliente from "../../../../../components/internoModalDeleteCliente";
import api from "../../../../../services/api";

export default function Tunnel(params) {
  const { clienteInfo } = params;
  const theme = useTheme();
  const styles = useStyles(theme);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [snackbar, setSnackbar] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openAdicionar, setOpenAdicionar] = useState(false);
  const [openEditar, setOpenEditar] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteLoad, setDeleteLoad] = useState(false);
  const deleteBody =
    "Se você prosseguir com a operação, o item e todas as informações associadas serão excluídas permanentemente!";
  const confirmDelete = "EXCLUIR";
  const open = Boolean(anchorEl);

  //tuneis
  const [data, setData] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);

  //fetch data
  useEffect(() => {
    handleApiGetPrefixos();
  }, [clienteInfo]);
  async function handleApiGetPrefixos() {
    try {
      const response = await api.post("/interno/find/tunnels", {
        clienteId: clienteInfo?.id || "",
      });
      setData(response?.data);
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possivel se conectar ao servidor",
        severity: "error",
      });
    }
  }

  function handleAddTunnel(response) {
    let newForm = [...data];
    const newArray = data;
    newArray.push({
      id: response?.id, //substituir por retorno do banco
      nome: response?.nome,
      ipTunnelLocal: response?.ipTunnelLocal,
      ipTunnelCliente: response?.ipTunnelCliente,
      ipSource: response?.ipSource,
      ipDestiny: response?.ipDestiny,
      idTunnelCliente: response?.idTunnelCliente,
    });
    newForm = newArray;
    setData(newForm);
  }

  async function handleDelete() {
    try {
      setLoading(true);
      const response = await api.post("/interno/delete/tunnel", {
        id: selectedCard?.id,
      });

      // on success
      setErrors({});
      setDeleteLoad(true);
      setDeleteLoad(false);
      setOpenDeleteModal(false);

      let newArray = [...data];
      newArray = newArray.filter((data) => data.id !== selectedCard.id);
      setData(newArray);

      // let newForm = { ...data };
      // const newArray = data;
      // newArray.splice(response.data, 1);
      // newForm = newArray;
      // setData(newForm);
      setSnackbar({
        children: "Túnel removido com sucesso!",
        severity: "success",
      });
      handleClose();
    } catch (error) {
      console.log(error);
      const newErrors = {};
      error?.errors?.forEach((err) => {
        newErrors[err.path[0]] = err.message;
      });
      setErrors(newErrors);
      setSnackbar({
        children: "Error: Não foi possivel se conectar ao servidor",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  function handleUpdated(formData) {
    let newArray = data?.map((data) => {
      if (data?.id === formData?.id) {
        return formData;
      }
      return data;
    });
    setData(newArray);
  }

  //anchor/menu

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setSnackbar({
      children: "Campo copiado!",
      severity: "info",
    });
  };

  // Encontrar ultimo tunel para sugerir o titulo correto
  const findLast = () => {
    const regexNums = /-(\d{3})-/;
    let last = 0;
    let titulo = "";
    Array.isArray(data) &&
      data?.map((tunel) => {
        const nums = tunel?.nome?.match(regexNums) || "";
        const num = parseInt(nums[1]);
        if (num > last) {
          titulo = tunel?.nome;
          last = num;
        }
      });
    return titulo;
  };

  return (
    <Paper
      sx={styles.containerPaper}
      elevation={3}
      variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
    >
      <Box sx={styles.container}>
        <Box sx={styles.header}>
          <Typography variant="mySubtitle">Configurações de Túnel</Typography>
          <Button
            variant="contained"
            startIcon={<Add />}
            size="medium"
            onClick={() => {
              setOpenAdicionar(true);
            }}
            disabled={loading}
          >
            NOVO TÚNEL
          </Button>
        </Box>
        <Grid container spacing={2}>
          {Array.isArray(data) &&
            data.map((tunnel, index) => (
              <Grid item lg={12} xl={6}>
                <Paper
                  variant="outlined"
                  key={tunnel}
                  value={index}
                  sx={styles.paper}
                >
                  <Box sx={styles.cardHeader}>
                    <Box>
                      <Typography variant="caption" sx={styles.cardSubTitle}>
                        Título do Túnel
                      </Typography>
                      <Typography>{tunnel?.nome}</Typography>
                    </Box>
                    <IconButton
                      onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setSelectedCard(tunnel);
                      }}
                      disabled={loading}
                    >
                      <MoreVert />
                    </IconButton>
                  </Box>
                  <Box sx={styles.cardContent}>
                    <Box sx={styles.cardRow}>
                      <Stack direction={"column"} sx={styles.cardStack}>
                        <Typography sx={styles.cardSubTitle}>
                          Tunnel Local
                        </Typography>
                        <Tooltip title={tunnel?.ipTunnelLocal}>
                          <span
                            onClick={() => {
                              handleCopy(tunnel?.ipTunnelLocal);
                            }}
                          >
                            <Typography sx={styles.cardText}>
                              {tunnel?.ipTunnelLocal}
                            </Typography>
                          </span>
                        </Tooltip>
                      </Stack>

                      <Stack direction={"column"} sx={styles.cardStack}>
                        <Typography sx={styles.cardSubTitle}>
                          Tunnel Cliente
                        </Typography>
                        <Tooltip title={tunnel?.ipTunnelCliente}>
                          <span
                            onClick={() => {
                              handleCopy(tunnel?.ipTunnelCliente);
                            }}
                          >
                            <Typography sx={styles.cardText}>
                              {tunnel?.ipTunnelCliente}
                            </Typography>
                          </span>
                        </Tooltip>
                      </Stack>
                    </Box>
                    <Box sx={styles.cardRow}>
                      <Stack direction={"column"} sx={styles.cardStack}>
                        <Typography sx={styles.cardSubTitle}>Source</Typography>
                        <Tooltip title={tunnel?.ipSource}>
                          <span
                            onClick={() => {
                              handleCopy(tunnel?.ipSource);
                            }}
                          >
                            <Typography sx={styles.cardText}>
                              {tunnel?.ipSource}
                            </Typography>
                          </span>
                        </Tooltip>
                      </Stack>
                      <Stack direction={"column"} sx={styles.cardStack}>
                        <Typography sx={styles.cardSubTitle}>
                          Destination
                        </Typography>
                        <Tooltip title={tunnel?.ipDestiny}>
                          <span
                            onClick={() => {
                              handleCopy(tunnel?.ipDestiny);
                            }}
                          >
                            <Typography sx={styles.cardText}>
                              {tunnel?.ipDestiny}
                            </Typography>
                          </span>
                        </Tooltip>
                      </Stack>
                    </Box>
                    <Box sx={styles.cardRow}>
                      <Stack direction={"column"} sx={styles.cardStack}>
                        <Typography sx={styles.cardSubTitle}>
                          Tunnel ID
                        </Typography>
                        <Tooltip title={tunnel?.idTunnelCliente}>
                          <span
                            onClick={() => {
                              handleCopy(tunnel?.idTunnelCliente);
                            }}
                          >
                            <Typography sx={styles.cardText}>
                              {tunnel?.idTunnelCliente}
                            </Typography>
                          </span>
                        </Tooltip>
                      </Stack>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            ))}
        </Grid>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              setOpenEditar(true);
              setAnchorEl(null);
            }}
          >
            <Edit sx={{ marginRight: "16px" }}></Edit> Editar
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              setOpenDeleteModal(true);
              setAnchorEl(null);
            }}
          >
            <Delete sx={{ marginRight: "16px" }}></Delete> Deletar
          </MenuItem>
        </Menu>
        <ModalAdicionar
          openAdicionar={openAdicionar}
          setOpenAdicionar={setOpenAdicionar}
          handleAddTunnel={handleAddTunnel}
          setSnackbar={setSnackbar}
          suggestedTitle={findLast() || ""}
          clienteInfo={clienteInfo}
        />
        <ModalEditar
          openEditar={openEditar}
          setOpenEditar={setOpenEditar}
          setSnackbar={setSnackbar}
          selectedCard={selectedCard}
          handleUpdated={handleUpdated}
          clienteInfo={clienteInfo}
        />
        <InternoModalDeleteCliente
          openDeleteModal={openDeleteModal}
          deleteLoad={deleteLoad}
          setOpenDeleteModal={setOpenDeleteModal}
          execute={handleDelete}
          severity={"error"}
          contentText={deleteBody}
          confirmText={confirmDelete}
        />

        {!!snackbar && (
          <Snackbar
            open
            onClose={() => setSnackbar(null)}
            autoHideDuration={2500}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert {...snackbar} onClose={() => setSnackbar(null)} />
          </Snackbar>
        )}
      </Box>
    </Paper>
  );
}

const initialData = [
  {
    id: 1,
    nome: "CPEZ-JP-001-AS263284-MAXXIMO",
    ipTunnelLocal: "198.19.232.5",
    ipTunnelCliente: "198.19.232.6",
    ipSource: "FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF",
    ipDestiny: "2804:404:404",
    idTunnelCliente: "ID-1",
  },
  {
    id: 2,
    nome: "ZEPXS-JP-001-AS263284-MAXXIMO",
    ipTunnelLocal: "198.19.232.5",
    ipTunnelCliente: "198.19.232.6",
    ipSource: "FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF",
    ipDestiny: "2804:404:404",
    idTunnelCliente: "ID-1",
  },
  {
    id: 3,
    nome: "ZEPXS-JP-001-AS263284-MAXXIMO",
    ipTunnelLocal: "198.19.232.5",
    ipTunnelCliente: "198.19.232.6",
    ipSource: "FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF",
    ipDestiny: "2804:404:404",
    idTunnelCliente: "ID-1",
  },
  {
    id: 4,
    nome: "ZEPXS-JP-001-AS263284-MAXXIMO",
    ipTunnelLocal: "198.19.232.5",
    ipTunnelCliente: "198.19.232.6",
    ipSource: "FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF",
    ipDestiny: "2804:404:404",
    idTunnelCliente: "ID-1",
  },
  {
    id: 5,
    nome: "ZEPXS-JP-001-AS263284-MAXXIMO",
    ipTunnelLocal: "198.19.232.5",
    ipTunnelCliente: "198.19.232.6",
    ipSource: "FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF",
    ipDestiny: "2804:404:404",
    idTunnelCliente: "ID-1",
  },
  {
    id: 6,
    nome: "CPEZ-JP-001-AS263284-MAXXIMO",
    ipTunnelLocal: "198.19.232.5",
    ipTunnelCliente: "198.19.232.6",
    ipSource: "FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF",
    ipDestiny: "2804:404:404",
    idTunnelCliente: "ID-1",
  },
  {
    id: 7,
    nome: "ZEPXS-JP-001-AS263284-MAXXIMO",
    ipTunnelLocal: "198.19.232.5",
    ipTunnelCliente: "198.19.232.6",
    ipSource: "FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF",
    ipDestiny: "2804:404:404",
    idTunnelCliente: "ID-1",
  },
  {
    id: 8,
    nome: "ZEPXS-JP-001-AS263284-MAXXIMO",
    ipTunnelLocal: "198.19.232.5",
    ipTunnelCliente: "198.19.232.6",
    ipSource: "FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF",
    ipDestiny: "2804:404:404",
    idTunnelCliente: "ID-1",
  },
  {
    id: 9,
    nome: "ZEPXS-JP-001-AS263284-MAXXIMO",
    ipTunnelLocal: "198.19.232.5",
    ipTunnelCliente: "198.19.232.6",
    ipSource: "FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF",
    ipDestiny: "2804:404:404",
    idTunnelCliente: "ID-1",
  },
  {
    id: 10,
    nome: "ZEPXS-JP-001-AS263284-MAXXIMO",
    ipTunnelLocal: "198.19.232.5",
    ipTunnelCliente: "198.19.232.6",
    ipSource: "FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF",
    ipDestiny: "2804:404:404",
    idTunnelCliente: "ID-1",
  },
];
