import { Image, View, Text, Line, Svg } from "@react-pdf/renderer";
import { styles } from "./styles";
// import mwIcon from "../../../../../assets/Logo5.png";
// import cellIcon from "../../../../../assets/easyReport/icon/cell.png";
// import siteIcon from "../../../../../assets/easyReport/icon/site.png";
// import instaIcon from "../../../../../assets/easyReport/icon/insta.png";

import logoComputize from "../../assets/imgs/logos/logoComputizeRelatorio.jpg";
// import logoComputize from "../../assets/imgs/logos/computizeLogoRelatorio.png";
import cellIcon from "../../assets/icons/whatsapp.png";
import siteIcon from "../../assets/icons/mail.png";
import instaIcon from "../../assets/icons/insta.png";

const Contatos = () => {
  return (
    <View
      style={[
        styles.alingRowCenter,
        { marginTop: "-5px", marginBottom: "5px" },
      ]}
    >
      <View style={styles.alingRowCenter}>
        <Image style={styles.iconContato} src={cellIcon} />
        <Text style={[styles.header]}>{"(11) 9 3055 - 3770"}</Text>
      </View>
      <View style={styles.alingRowCenter}>
        <Image
          style={[styles.iconContato, { marginLeft: "15px" }]}
          src={siteIcon}
        />
        <Text style={[styles.header]}>{"contato@computizenetwork.com"}</Text>
      </View>
      <View style={styles.alingRowCenter}>
        <Image
          style={[styles.iconContato, { marginLeft: "15px" }]}
          src={instaIcon}
        />
        <Text style={[styles.header]}>{"@computize_network"}</Text>
      </View>
    </View>
  );
};

export const HeaderPdf = ({ relatorio }) => (
  <>
    <View fixed>
      <Image style={styles.logoComputize} src={logoComputize} />
      <Contatos />
      <Text style={[{ fontSize: 16 }, styles.title]}>
        {relatorio?.titulo?.toUpperCase()}
      </Text>
      <Svg height="1" width="100%" style={{ marginTop: -5, marginBottom: 10 }}>
        <Line
          x1="0"
          y1="0"
          x2="800"
          y2="0"
          strokeWidth={1.5}
          stroke="#212B36"
        />
      </Svg>
    </View>
  </>
);
