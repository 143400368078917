import {
  Box,
  Paper,
  Tooltip,
  Typography,
  Stack,
  useTheme,
  Divider,
  Chip,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import dayjs from "dayjs";
import api from "../../../services/api";
import { useEffect, useRef, useState } from "react";
import LineChartComponent from "./line";
import useStyles from "./styles";

//Funcao para buscar os ultimos 90 dias e realizar analise com os dados da api
//Essa funcao serve para que seja renderizado todos os 90 dias, pois caso a API nao tenha
// dados referente ao 90 dias, o mesmo vai renderizar os dados da cor cinza
const dateGenerate = () => {
  const ultimos90Dias = [];
  for (let i = 0; i < 90; i++) {
    const data = new Date();
    data.setDate(data.getDate() - i);
    const objeto = {
      data: dayjs(
        data
          .toLocaleString("en-US", {
            timeZoneName: "longOffset",
            timeZone: "America/Sao_paulo",
          })
          .split(",")[0]
      ).format("YYYY-MM-DD"), // Formato YYYY-MM-DD
    };

    ultimos90Dias.unshift(objeto);
  }
  return ultimos90Dias;
};

export default function Status() {
  const theme = useTheme();
  const styles = useStyles(theme);
  const [nameApis, setNameApis] = useState([]);
  const [dataApis, setDataApis] = useState({});
  const [tempo, setTempo] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [day, setDay] = useState(30);
  const [dataGrafico, setDataGrafico] = useState([]);
  const [dias, setDias] = useState(dateGenerate);
  const [loading, setLoading] = useState(true);
  const [timeRequestdata, setTimeRequestdata] = useState([]);
  const [dataApisFilter, setDataApisFilter] = useState([]);
  const ref = useRef(null);

  //Ao iniciar o componente, chamar a funcao getDataApi() para receber os dados da API
  useEffect(() => {
    setLoading(true);
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    getDataApi(); //Chamada da funcao para buscar dados das APIs no backend
  }, []);

  //Ao receber os dados da API, fazer um filtro de apenas os dados que exista
  useEffect(() => {
    const apis = Object.keys(dataApis); //['brbyte', 'wanguard', 'zabbix', 'nexus']

    //Filtrando dados da api de acordo com cada chave e depois organizando por data
    //Nem toda API terá dados dos ultimos 90 dias, por isso necessario comparar apiData?.id  existe
    setDataApisFilter(
      dias?.map((dia, index) =>
        apis.map((api) =>
          dataApis?.[api].filter(
            (apiData) =>
              dayjs(apiData?.createAt)?.format("DD/MM/YYYY") ===
                dayjs(dia?.data)?.format("DD/MM/YYYY") && apiData?.id !== null
          )
        )
      )
    );

    //Eetado para identificar o nome das APIs que foram identificadas no backend
    setNameApis(Object.keys(dataApis));
  }, [dataApis]);

  //Hook para realizar a renderização dos dados em diferentes telas diferentes
  //Aqui se encontra as funcionalidades de alterar a quantidade de dias que esta
  // sendo monitorado de acordo com o tamanho da tela

  useEffect(() => {
    //setar tempo multiplos de 30 de acordo com o tamanho da tela

    const a = -520.3654371226432;
    const b = 86.34673864454844;
    let func = ((x) => ((a + b * Math.log(x)) / 10).toFixed(0)) || 0;

    setTempo(
      parseInt(
        10 * func(windowWidth) <= 30
          ? 30
          : 10 * func(windowWidth) >= 90
          ? 90
          : 10 * func(windowWidth)
      )
    );
  }, [windowWidth]);
  useEffect(() => {
    const hoje = new Date();
    const trintaDias = new Date(hoje);
    trintaDias.setDate(hoje.getDate() - 30);
    //buscar chaves referente a cada api (nome de cada api)
    const myKeys = Object.keys(dataApis);
    //Verificar o tamamnho do array que contem as chaves de cada api
    const keysLength = myKeys?.length || 0;

    setTimeRequestdata(
      Array.isArray(myKeys) && keysLength > 0
        ? dataApis[myKeys[0]]?.map((api, index) => {
            let data = {};
            myKeys?.map((key) => {
              data[key + "TimeRequest"] =
                dataApis[key]?.[index]?.timeRequest || null;
            });

            return { date: api?.createAt, ...data };
          })
        : []
    );

    //Hook para receber os dados para os grafico de tempo de resposta
    setDataGrafico(
      timeRequestdata?.filter(
        (data) =>
          new Date(data?.date) <= hoje && new Date(data?.date) >= trintaDias
      )
    );
  }, [dataApis]);

  //Funcao para buscar dados da API
  const getDataApi = async () => {
    try {
      const response = await api.get("/interno/metricas");
      setDataApis(response?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  //Realizar filtro de tempo para grafico de tempo de respostas das APIs
  const handleChange = (event) => {
    setDay(event.target.value);

    const hoje = new Date();
    const seteDias = new Date(hoje);
    const trintaDias = new Date(hoje);
    const vinteQuatroHoras = new Date(hoje.getTime() - 24 * 60 * 60 * 1000);
    seteDias.setDate(hoje.getDate() - 7);
    trintaDias.setDate(hoje.getDate() - 30);

    if (event.target.value === 30) {
      setDataGrafico(
        timeRequestdata?.filter(
          (data) =>
            new Date(data?.date) <= hoje && new Date(data?.date) >= trintaDias
        )
      );
    } else if (event.target.value === 7) {
      setDataGrafico(
        timeRequestdata?.filter(
          (data) =>
            new Date(data?.date) <= hoje && new Date(data?.date) >= seteDias
        )
      );
    } else if (event.target.value === 1) {
      setDataGrafico(
        timeRequestdata?.filter(
          (data) =>
            new Date(data?.date) <= hoje &&
            new Date(data?.date) >= vinteQuatroHoras
        )
      );
    }
  };
  //Funcao para analisar a atividade de um dia em especifico
  //Funcao necessaria para realizar a analise da atividade da API de todos os dias
  const analiseAtividadeApiDay = (dataApi, requisicoes) => {
    if (requisicoes) {
      return parseFloat((dataApi * 100) / requisicoes).toFixed(2);
    } else {
      return 0;
    }
  };

  //Funcao para analisar a atividade de todos os dias
  const analiseAtividadeApi = (data, indiceAPi) => {
    let requisicoesTotal = [];
    let requestFilter = [];

    //Verificar requisições que tiveram exito
    const requestSuccess =
      Array.isArray(data) &&
      data.map(
        (apis) =>
          apis[indiceAPi]?.filter(
            (api) => api?.httpCode === 200 || api?.httpCode === 201
          )?.length
      );

    //Verificar quantas requisições teve em cada dia dos ultimos 90 dias
    const requestDay =
      Array.isArray(data) &&
      data.map((apis) => apis[indiceAPi]?.filter((data) => data)?.length);

    //Verificar em quais dias ocorreu alguam requisição.
    //Caso no dia verificado seja identificado pelo menos uma requisição (sucesso ou nao)
    //Adicionar quantas requisições ocorreu no dia (requisicoesTotal) e quantas tiveram sucesso (requestFilter)
    Array.isArray(requestDay) &&
      requestDay?.forEach((requisicoesDay, index) => {
        if (requisicoesDay !== 0) {
          requisicoesTotal.push(requisicoesDay);
          requestFilter.push(requestSuccess[index]);
        }
      });

    //O for vai realizar a analise de cada dia que ocorreu pelo menos uma requisição e adicionar analiseDiario
    let analiseDiario = 0;
    for (let i = 0; i < requisicoesTotal?.length; i++) {
      analiseDiario += parseFloat(
        analiseAtividadeApiDay(requestFilter[i], requisicoesTotal[i])
      );
    }
    //Retornar a analise de todos os dias que tiveram pelo menos uma requisição
    const analiseCompleto = (analiseDiario / requisicoesTotal?.length).toFixed(
      2
    );
    //Caso a API seja recente e nao tem dados de nenhum dia, o mesmo vai retornar como 0.00% sobre a atividade da API
    return isNaN(analiseCompleto) ? 0.0 : analiseCompleto;
  };

  //funcao para chamar api com os dados a cada 5 minutos
  //setInterval(getDataApi, 60 * 1000);

  return (
    <Box sx={styles.containerComponent}>
      <Typography sx={styles.tituloComponent}>MÉTRICAS DO SISTEMA</Typography>
      {loading ? (
        <Box sx={styles.containerLoading}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Paper sx={styles.containerPaper} variant="outlined" ref={ref}>
            {nameApis.map((apiName, index) => {
              return (
                <Box sx={styles.containerApi}>
                  <Box sx={styles.containerTituloApi}>
                    <Box
                      sx={styles.containerColorApi}
                      bgcolor={
                        index === 0
                          ? theme.palette.mode === "dark"
                            ? "#b354b9"
                            : "#8f2a96"
                          : index === 1
                          ? "#07A2ED"
                          : index === 2
                          ? "#F07E11 "
                          : index === 3
                          ? theme.palette.mode === "dark"
                            ? "#516E3A "
                            : "#77a154"
                          : "black"
                      }
                    />

                    <Typography sx={styles.apiTitulo}>
                      {apiName[0].toUpperCase() + apiName?.substring(1)}
                    </Typography>
                  </Box>

                  <Box sx={styles.containerAnaliseApi}>
                    {Array.isArray(dataApisFilter?.slice(-tempo)) &&
                      dataApisFilter
                        ?.slice(-tempo)
                        ?.map((dataApi, indexApi) => {
                          return (
                            <Box>
                              <Tooltip
                                arrow
                                title={
                                  <Stack>
                                    {dataApi[index]?.length > 0 ? (
                                      <>
                                        <Box sx={styles.containerData}>
                                          <Typography sx={styles.tituloData}>
                                            {dataApi[index]?.length > 0
                                              ? dataApi &&
                                                dayjs(
                                                  dataApi[index][0]?.createAt
                                                ).format("DD/MM/YYYY")
                                              : ""}
                                          </Typography>
                                        </Box>

                                        <Box sx={styles.containerRequisicao}>
                                          <Box
                                            sx={
                                              styles.containerStatusRequisicao
                                            }
                                          ></Box>

                                          <Typography
                                            sx={styles.tituloRequisicao}
                                          >
                                            Requisições bem-sucessidas:{" "}
                                            {
                                              dataApi[index]?.filter(
                                                (data) =>
                                                  data?.httpCode === 200 ||
                                                  data?.httpCode === 201
                                              ).length
                                            }{" "}
                                          </Typography>
                                        </Box>

                                        <Box sx={styles.containerFalha}>
                                          <Box
                                            sx={styles.containerStatusFalha}
                                          ></Box>
                                          <Typography
                                            sx={styles.tituloRequisicao}
                                          >
                                            Requisições com falhas:{" "}
                                            {dataApi[index]?.length -
                                              dataApi[index]?.filter(
                                                (data) =>
                                                  data?.httpCode === 200 ||
                                                  data?.httpCode === 201
                                              )?.length}
                                          </Typography>
                                        </Box>
                                        {dataApi[index]?.filter(
                                          (data) =>
                                            data?.httpCode !== 200 &&
                                            data?.httpCode !== 201
                                        )?.length !== 0 && (
                                          <Box sx={styles.containerIncidentes}>
                                            <Typography sx={styles.tituloData}>
                                              Incidentes do dia
                                            </Typography>
                                            {dataApi[index]
                                              ?.filter(
                                                (data) =>
                                                  data?.httpCode !== 200 &&
                                                  data?.httpCode !== 201
                                              )
                                              ?.map((api) => (
                                                <>
                                                  <Box
                                                    sx={
                                                      styles.containerStatusIncidentes
                                                    }
                                                  >
                                                    <Typography
                                                      sx={
                                                        styles.tituloRequisicao
                                                      }
                                                    >
                                                      {dayjs(
                                                        api?.createAt
                                                      ).format(
                                                        "DD/MM/YY HH:mm"
                                                      )}
                                                    </Typography>
                                                    <Typography
                                                      sx={
                                                        styles.tituloRequisicao
                                                      }
                                                    >
                                                      Status: {api?.httpCode}
                                                    </Typography>
                                                  </Box>
                                                  <Divider></Divider>
                                                </>
                                              ))}
                                          </Box>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <Box sx={styles.containerSemDados}>
                                          <Typography sx={styles.tituloData}>
                                            {dayjs(
                                              dias[indexApi]?.data
                                            )?.format("DD/MM/YYYY")}
                                          </Typography>
                                        </Box>

                                        <Typography
                                          sx={styles.tituloRequisicao}
                                        >
                                          Sem dados para esse período
                                        </Typography>
                                      </>
                                    )}
                                  </Stack>
                                }
                              >
                                <Box
                                  bgcolor={
                                    dataApi[index]?.length > 0
                                      ? analiseAtividadeApiDay(
                                          dataApi[index]?.filter(
                                            (data) =>
                                              data?.httpCode === 200 ||
                                              data?.httpCode === 201
                                          ).length,
                                          dataApi[index]?.length
                                        ) > 90
                                        ? "#3ba55c"
                                        : analiseAtividadeApiDay(
                                            dataApi[index]?.filter(
                                              (data) =>
                                                data?.httpCode === 200 ||
                                                data?.httpCode === 201
                                            ).length,
                                            dataApi[index]?.length
                                          ) > 80
                                        ? theme.palette.mode === "dark"
                                          ? "#ac9f14"
                                          : "#ccbd17"
                                        : "secondary.main"
                                      : "#b3bac5"
                                  }
                                  sx={styles.containerDia}
                                />
                              </Tooltip>
                            </Box>
                          );
                        })}
                  </Box>
                  <Box sx={styles.containerAnaliseDias}>
                    <Box sx={{ minWidth: "60px" }}>
                      <Typography sx={styles.tituloDias}>
                        Há {tempo} dias
                      </Typography>
                    </Box>
                    <Box sx={styles.containerDivider}>
                      <Divider variant="fullWidth">
                        <Chip
                          label={`${analiseAtividadeApi(
                            dataApisFilter?.slice(-tempo),
                            index
                          )}% em atividade`}
                          size="small"
                          sx={{
                            bgcolor:
                              analiseAtividadeApi(
                                dataApisFilter?.slice(-tempo),
                                index
                              ) > 90
                                ? "#3ba55c"
                                : analiseAtividadeApi(
                                    dataApisFilter?.slice(-tempo),
                                    index
                                  ) > 80
                                ? theme.palette.mode === "dark"
                                  ? "#ac9f14"
                                  : "#ccbd17"
                                : "secondary.main",
                            color: "white",
                          }}
                        />
                      </Divider>
                    </Box>
                    <Box sx={styles.containerHoje}>
                      <Typography sx={styles.tituloDias}>Hoje</Typography>
                    </Box>
                  </Box>
                </Box>
              );
            })}

            <Box>
              <Box sx={styles.containerRespostaApi}>
                <Typography sx={styles.tituloRespostaApi}>
                  Tempo de Resposta API
                </Typography>
                <Box>
                  <FormControl size="small" sx={styles.containerForm}>
                    <Select
                      value={day}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value={30}>Últimos 30 dias</MenuItem>
                      <MenuItem value={7}>Últimos 7 dias</MenuItem>
                      <MenuItem value={1}>Últimas 24 horas</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Box sx={styles.containerGrafico}>
                <LineChartComponent dataApi={dataGrafico} />
              </Box>
            </Box>
          </Paper>
        </>
      )}
    </Box>
  );
}
