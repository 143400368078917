import {
  Paper,
  Stack,
  Button,
  Modal,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../../../../services/api";
import validateIp from "../../../../../../utils/validadorIp";

export default function EditModalRouter(params) {
  const {
    setOpenEditModal,
    openEditModal,
    setSnackbar,
    setLoadingTable,
    styles,
    selectedRow,
    setErrorName,
    errorName,
    comparaOutrosNomes,
    handleEditRouters,
    routerEdit,
    setRouterEdit = () => {},
  } = params;
  const [disableEditButton, setDisableEditButton] = useState(false);
  //alert modal

  useEffect(() => {
    if (
      comparaOutrosNomes(
        routerEdit?.nome_bgp?.trim(),
        selectedRow?.nome_bgp?.trim()
      ) ||
      !routerEdit?.nome_bgp?.trim() ||
      !routerEdit?.modo ||
      validateIp(routerEdit?.ip_flows) === 0 ||
      !routerEdit?.taxa_amostragem_v4 ||
      !routerEdit?.taxa_amostragem_v6 ||
      validateIp(routerEdit?.snmp_ip) === 0 ||
      !routerEdit?.snmp_community ||
      !routerEdit?.snmp_port
    ) {
      setDisableEditButton(true);
    } else {
      setDisableEditButton(false);
    }
  }, [routerEdit]);
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && disableEditButton === false) {
      handleApiPutRouter();
    }
  };
  const handleHelperText = (ip) => {
    if (ip) {
      return validateIp(ip) === 1
        ? "O endereço IP é válido!"
        : validateIp(ip) === 2
        ? "O endereço IP é válido!"
        : "Formato de IP inválido";
    }
    return null;
  };
  async function handleApiPutRouter() {
    try {
      setLoadingTable(true);
      setDisableEditButton(true);
      const response = await api.post("/interno/update/router", {
        id: selectedRow.id,
        nome_bgp: routerEdit?.nome_bgp,
        modo: routerEdit?.modo,
        ip_flows: routerEdit?.ip_flows,
        taxa_amostragem_v4: parseInt(routerEdit?.taxa_amostragem_v4),
        taxa_amostragem_v6: parseInt(routerEdit?.taxa_amostragem_v6),
        snmp_ip: routerEdit?.snmp_ip,
        snmp_community: routerEdit?.snmp_community,
        snmp_port: parseInt(routerEdit?.snmp_port),
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados Salvos com sucesso",
          severity: "success",
        });
        if (response.data) {
          handleEditRouters(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingTable(false);
      setDisableEditButton(false);
      setOpenEditModal(false);
    }
  }
  return (
    <>
      <Modal
        open={openEditModal}
        onClose={() => {
          setOpenEditModal(false);
          setErrorName(false);
        }}
        sx={{ alignItems: "center", justifyContent: "center", display: "flex" }}
      >
        <Paper sx={styles.paperModal} elevation={3}>
          <DialogTitle color="primary">Edite Router </DialogTitle>{" "}
          <DialogContent sx={styles.dialogContent}>
            <Stack direction="row" spacing={"20px"} sx={{ mt: 1 }}>
              <TextField
                sx={{ width: "100%" }}
                id="textfield-nome-bgp"
                label="Nome Router"
                value={routerEdit?.nome_bgp}
                error={errorName}
                helperText={errorName ? "Nome é uma chave única" : null}
                onChange={(e) => {
                  if (
                    comparaOutrosNomes(
                      e.target.value?.trim(),
                      selectedRow.nome_bgp?.trim()
                    )
                  ) {
                    setErrorName(true);
                  } else {
                    setErrorName(false);
                  }
                  setRouterEdit((prev) => ({
                    ...prev,
                    nome_bgp: e.target.value,
                  }));
                }}
              />
            </Stack>
            <Stack direction="row" spacing={"20px"}>
              <FormControl sx={styles.TextField}>
                <InputLabel>Modo</InputLabel>
                <Select
                  value={routerEdit?.modo}
                  label="Modo"
                  onChange={(e) => {
                    setRouterEdit((prev) => ({
                      ...prev,
                      modo: e.target.value,
                    }));
                  }}
                >
                  <MenuItem value={"nfcapd"}>{"nfcapd"}</MenuItem>
                  <MenuItem value={"sfcapd"}>{"sfcapd"}</MenuItem>
                </Select>
              </FormControl>
              <TextField
                sx={styles.TextField}
                id="textfield-ip"
                label="Source IP"
                value={routerEdit?.ip_flows}
                error={
                  routerEdit?.ip_flows
                    ? !validateIp(routerEdit?.ip_flows)
                    : false
                }
                helperText={handleHelperText(routerEdit?.ip_flows)}
                onChange={(e) =>
                  setRouterEdit((prev) => ({
                    ...prev,
                    ip_flows: e.target.value,
                  }))
                }
              />
            </Stack>
            <Stack direction="row" spacing={"20px"}>
              <TextField
                sx={styles.TextField}
                id="textfield-taxa-amostragem-v4"
                label="Amostragem V4"
                type="number"
                value={routerEdit?.taxa_amostragem_v4}
                onChange={(e) =>
                  setRouterEdit((prev) => ({
                    ...prev,
                    taxa_amostragem_v4: e.target.value,
                  }))
                }
              />
              <TextField
                sx={styles.TextField}
                id="textfield-taxa-amostragem-v6"
                label="Amostragem V6"
                type="number"
                value={routerEdit?.taxa_amostragem_v6}
                onChange={(e) =>
                  setRouterEdit((prev) => ({
                    ...prev,
                    taxa_amostragem_v6: e.target.value,
                  }))
                }
              />
            </Stack>
            <Stack direction="row" spacing={"20px"}>
              <TextField
                sx={{ width: "100%" }}
                id="textfield-snmp-community"
                label="SNMP Community"
                value={routerEdit?.snmp_community}
                onChange={(e) =>
                  setRouterEdit((prev) => ({
                    ...prev,
                    snmp_community: e.target.value,
                  }))
                }
              />
            </Stack>
            <Stack direction="row" spacing={"20px"}>
              <TextField
                sx={styles.TextField}
                id="textfield-snmp-ip"
                label="SNMP IP"
                value={routerEdit?.snmp_ip}
                error={
                  routerEdit?.snmp_ip ? !validateIp(routerEdit?.snmp_ip) : false
                }
                helperText={handleHelperText(routerEdit?.snmp_ip)}
                onChange={(e) =>
                  setRouterEdit((prev) => ({
                    ...prev,
                    snmp_ip: e.target.value,
                  }))
                }
              />
              <TextField
                sx={styles.TextField}
                id="textfield-snmp-port"
                label="SNMP Port"
                type="number"
                value={routerEdit?.snmp_port}
                onKeyDown={handleKeyDown}
                onChange={(e) =>
                  setRouterEdit((prev) => ({
                    ...prev,
                    snmp_port: e.target.value,
                  }))
                }
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              onClick={() => {
                setOpenEditModal(false);
                setErrorName(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={disableEditButton}
              onClick={() => {
                handleApiPutRouter();
              }}
            >
              Editar
            </Button>
          </DialogActions>
        </Paper>
      </Modal>
    </>
  );
}
