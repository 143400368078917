const styles = (theme) => ({
  container: {
    width: "100%",
    alignItems: "center",
    flexDirection: "column",
    display: "flex",
    gap: 1,
  },
  containerTabs: { width: "83%" },
});

export default styles;
