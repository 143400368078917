import { bgcolor, margin, padding, spacing } from "@mui/system";

const styles = (theme) => ({
  containerModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    backgroundColor: "background.paperOutlined",
    width: "530px",
  },
  Avatar: {
    width: "90px",
    height: "90px",
  },
  BoxAvatar: {
    width: "126px",
    height: "126px",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    bgcolor: "background.paperOutlined",
    marginLeft: "40px",
  },
  StackFoto: {
    alignItems: "center",
    height: "220px",
    position: "absolute",
    marginTop: "10px",
    marginRight: "320px",
  },
  boxAreaTituloModalPerfil: {
    position: "relative",
    width: "100%",
    height: "80px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  boxAreaModalPerfil: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",

    padding: "10px",
  },
  boxAreaConteudoModalPerfil: {
    backgroundColor: "background.default",
    width: "100%",
    minHeight: "388px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "12px",
    padding: "30px",
    paddingTop: "10px",
  },
  boxAreaButtomModalPerfil: {
    /* background: "pink", */
    width: "100%",
    height: "36px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
  },
  containerButtonSalvar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    mr: "-21px",
    mt: "-6px",
    gap: "10px",
  },
  TextField: {
    fontSize: "16px",
    fontWeight: "600",
  },
  divider: {
    width: "100%",
    margin: "10px 0px",
  },
  textBold: {
    fontWeight: "bold",
  },
  boxStatusItem: {
    position: "relative",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    gap: "6px",
    mt: "-1px",
  },
  person: {
    color: "primary.main",
    mt: "-3px",
    fontSize: "22px",
  },
  buttonSave: {
    color: "primary.main",
  },
  buttonCancelar: {
    color: "secondary.main",
  },
});

export default styles;
