import {
  Box,
  Paper,
  Snackbar,
  Alert,
  Stack,
  Button,
  Tooltip,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import api from "../../../../../services/api";
import { Add, Delete, Edit } from "@mui/icons-material";
import EditModalInterfaces from "./editModalInterfaces";
import CreateModalInterfaces from "./createModalInterfaces";
import useStyles from "./styles";
import { ptBR } from "@mui/x-data-grid/locales";
import { useTheme } from "@emotion/react";
import InternoModalDeleteCliente from "../../../../../components/internoModalDeleteCliente";

export default function Interfaces(params) {
  const { clienteId } = params;
  const rows = [{ id: 14 }];
  //modal
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [routers, setRouters] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  //modal delete
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  //tabela
  const [interfaces, setInterfaces] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const [loadingTable, setLoadingTable] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const deleteBody =
    "O item será deletado permanentemente. Deseja deletar o item?";
  const confirmDelete = "Excluir";
  const theme = useTheme();
  const styles = useStyles(theme);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      hide: true,
    },
    {
      field: "exportador",
      headerName: "Exportador",
      width: 200,
    },
    {
      field: "exportadorId",
      headerName: "RouterId",
      minWidth: 290,
      hide: true,
    },
    {
      field: "interface",
      headerName: "Interface",
      width: 180,
    },
    {
      field: "index",
      headerName: "Index",
      flex: 3,
    },

    {
      field: "actions",
      headerName: "",
      sortable: false,

      renderCell: (params) => {
        const onClick = (e) => {
          const thisRow = params.row;
          hanldeUpDateSelectRow(thisRow);
          setOpenEditModal(true);
        };
        function hanldeUpDateSelectRow(thisRow) {
          setSelectedRow(thisRow);
        }
        const onDelete = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const thisRow = params.row;
          setOpenDeleteModal(true);
          setSelectedRow(thisRow);
        };

        return (
          <Box key={params.id}>
            <Tooltip title={"Editar"} id="basic-button" onClick={onClick}>
              <IconButton size="small">
                <Edit color="primary" />
              </IconButton>
            </Tooltip>

            <IconButton onClick={onDelete}>
              <Delete color={"secondary"} />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const handleInterfaces = (interfaces) => {
    const novoArray = interfaces.map((valorInterface) => {
      return {
        id: valorInterface.id,
        interface: valorInterface.interface,
        index: valorInterface.index,
        exportador: valorInterface.router?.nome_bgp,
        exportadorId: valorInterface.router?.id,
      };
    });
    setInterfaces(novoArray);
  };
  useEffect(() => {
    handleApiGetInterfaces();
    handleApiGetRouters();
  }, []);

  function handleNewInterface(newInterface) {
    const novoObjInterface = {
      id: newInterface?.id,
      interface: newInterface?.interface,
      index: newInterface?.index,
      exportador: newInterface?.router?.nome_bgp,
      exportadorId: newInterface?.router?.id,
    };

    setInterfaces((interfaces) => [...interfaces, novoObjInterface]);
  }
  function handleEditInterfaces(interfaceEditada) {
    const novoObjInterface = {
      id: interfaceEditada.id,
      interface: interfaceEditada.interface,
      index: interfaceEditada.index,
      exportador: interfaceEditada.router?.nome_bgp,
      exportadorId: interfaceEditada?.router?.id,
    };
    setInterfaces((interfaces) =>
      interfaces.map((interfaceFlow) => {
        if (interfaceFlow.id === interfaceEditada.id) {
          return novoObjInterface;
        } else {
          return interfaceFlow;
        }
      })
    );
  }
  function handleDeleteInterfaces(id) {
    setInterfaces((interfaces) =>
      interfaces.filter((interfaceFlow) => interfaceFlow.id !== id)
    );
  }
  async function handleApiGetInterfaces() {
    try {
      setLoadingTable(true);
      const response = await api.get("/interno/findMany/interface");

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar dados",
          severity: "error",
        });
      } else {
        if (response.data) {
          handleInterfaces(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingTable(false);
    }
  }
  async function handleApiGetRouters() {
    try {
      setLoadingTable(true);
      const response = await api.get("/interno/findMany/router");

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar dados",
          severity: "error",
        });
      } else {
        if (response.data) {
          setRouters(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingTable(false);
    }
  }

  async function handleApiDeleteInterfaces() {
    try {
      setLoadingTable(true);

      const response = await api.post("/interno/delete/interface", {
        id: selectedRow.id,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível excluir dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados excluídos com sucesso",
          severity: "success",
        });
        if (response.data) {
          handleDeleteInterfaces(response.data.id);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingTable(false);

      setOpenDeleteModal(false);
    }
  }
  return (
    <>
      <Box sx={styles.boxContainer}>
        <Paper sx={styles.paperContainer} elevation={3} variant={"outlined"}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            {/* <Typography variant="h6">Interfaces Flow</Typography>*/}
            <Box></Box>
            <Button
              variant="contained"
              disabled={loadingTable}
              startIcon={<Add />}
              onClick={() => {
                setOpenCreateModal(true);
              }}
            >
              Adicionar
            </Button>
          </Stack>
          <DataGrid
            sx={styles.datagrid}
            autoHeight={true}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={interfaces}
            columns={columns}
            initialState={{
              ...rows.initialState,
              columns: {
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  id: false,
                },
              },
            }}
            loading={loadingTable}
            disableColumnMenu
            //checkboxSelection
            disableRowSelectionOnClick={true}
          />{" "}
        </Paper>
      </Box>{" "}
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
      <CreateModalInterfaces
        setOpenCreateModal={setOpenCreateModal}
        openCreateModal={openCreateModal}
        setSnackbar={setSnackbar}
        handleNewInterface={handleNewInterface}
        setLoadingTable={setLoadingTable}
        styles={styles}
        routers={routers}
        clienteId={clienteId}
      />
      <EditModalInterfaces
        setOpenEditModal={setOpenEditModal}
        openEditModal={openEditModal}
        setSnackbar={setSnackbar}
        setLoadingTable={setLoadingTable}
        styles={styles}
        selectedRow={selectedRow}
        routers={routers}
        handleEditInterfaces={handleEditInterfaces}
      />
      <InternoModalDeleteCliente
        openDeleteModal={openDeleteModal}
        deleteLoad={false}
        setOpenDeleteModal={setOpenDeleteModal}
        execute={handleApiDeleteInterfaces}
        severity={"error"}
        contentText={deleteBody}
        confirmText={confirmDelete}
      />
    </>
  );
}
