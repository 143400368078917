import { Add, Delete, Edit, MoreVert } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import useStyles from "./styles";
import ModalAdicionar from "./modalAdicionar";
import ModalEditar from "./modalEditar";
import InternoModalDeleteCliente from "../../../../../components/internoModalDeleteCliente";
import api from "../../../../../services/api";
import validarIp from "../../../../../utils/validadorIp";
import { useTheme } from "@emotion/react";

export default function Sessoes(params) {
  const { clienteInfo } = params;
  const theme = useTheme();
  const styles = useStyles(theme);
  const [snackbar, setSnackbar] = useState(null);
  const [openAdicionar, setOpenAdicionar] = useState();
  const [editMode, setEditMode] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteLoad, setDeleteLoad] = useState(false);
  const [errors, setErrors] = useState({});
  const deleteBody =
    "Se você prosseguir com a operação, o item e todas as informações associadas serão excluídas permanentemente!";
  const confirmDelete = "EXCLUIR";
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMenuId, setSelectedMenuId] = useState("basic-buttom");
  const open = Boolean(anchorEl);
  const [selectedCard, setSelectedCard] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleApiGetTunnels();
    handleApiGetVlans();
  }, [clienteInfo]);
  // data tunnel
  const [dataTunnelIpv4, setDataTunnelIpv4] = useState([]);
  async function handleApiGetTunnels() {
    try {
      setLoading(true);
      const response = await api.post("/interno/find/tunnels", {
        clienteId: clienteInfo?.id || "",
      });
      setDataTunnelIpv4(response.data);
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possivel se conectar ao servidor",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  }
  // data vlan
  async function handleApiGetVlans() {
    try {
      const response = await api.post("/interno/find/vlans", {
        clienteId: clienteInfo?.id || "",
      });
      setDataVlanIpv4(response.data);
      setDataVlanIpv6(response.data);
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possivel se conectar ao servidor",
        severity: "error",
      });
    }
  }
  //vlan ipv4
  const [dataVlanIpv4, setDataVlanIpv4] = useState([]);
  //vlan ipv6
  const [dataVlanIpv6, setDataVlanIpv6] = useState([]);

  // ao adicionar com sucesso
  function handleAddSession(response) {
    let newForm = [...dataVlanIpv6];
    const newArray = dataVlanIpv6;

    newArray.push({
      id: response?.id,
      titulo: response?.titulo,
      ipLocal: response?.ipLocal,
      ipCliente: response?.ipCliente,
      asnVlan: response?.asnVlan,
      vlanId: response?.vlanId,
    });
    newForm = newArray;
    setDataVlanIpv6(newForm);
  }

  //ao atualizar com sucesso
  function handleUpdated(formData) {
    let newArray = dataVlanIpv6.map((dataVlanIpv6) => {
      if (dataVlanIpv6.id === formData.id) {
        return formData;
      }
      return dataVlanIpv6;
    });
    setDataVlanIpv6(newArray);
  }

  //remover card
  async function handleDelete() {
    setDeleteLoad(true);
    try {
      const response = await api.post("/interno/delete/vlan", {
        id: selectedCard?.id,
      });
      console.log(response);
      // on success
      if (response?.data?.id === selectedCard?.id) {
        let newArray = [...dataVlanIpv6];
        newArray = newArray.filter(
          (dataVlanIpv6) => dataVlanIpv6?.id !== selectedCard?.id
        );
        setDataVlanIpv6(newArray);
      } else {
        setSnackbar({
          children: "Error: Não foi possivel se conectar ao servidor",
          severity: "error",
        });
        return;
      }

      setOpenDeleteModal(false);
      setSnackbar({
        children: "Sessão removida com sucesso!",
        severity: "success",
      });
      handleClose();
    } catch (error) {
      console.log(error);
      const newErrors = {};
      error?.errors?.forEach((err) => {
        newErrors[err.path[0]] = err.message;
      });
      setErrors(newErrors);
      setSnackbar({
        children: "Error: Não foi possivel se conectar ao servidor",
        severity: "error",
      });
    } finally {
      setDeleteLoad(false);
    }
  }
  //Change geral tabs handler
  const [geralValue, setGeralValue] = useState(0);
  const handleGeralChange = (event, newValue) => {
    setGeralValue(newValue);
  };

  function a12yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  //Change vlan tabs handler
  const [value, setValue] = useState(0);
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  //anchor/menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setSnackbar({
      children: "Campo copiado!",
      severity: "info",
    });
  };

  // Encontrar Ultima vlan para sugerir o titulo correto
  const findLast = () => {
    const regexNums = /-(\d{3})-/;
    let last = 0;
    let titulo = "";
    Array.isArray(dataVlanIpv6) &&
      dataVlanIpv6?.map((vlan) => {
        const nums = vlan?.titulo?.match(regexNums) || "";
        const num = parseInt(nums[1]);
        if (num > last) {
          titulo = vlan?.titulo;
          last = num;
        }
      });
    return titulo;
  };

  return (
    <Paper
      sx={styles.containerPaper}
      elevation={3}
      variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
    >
      <Box sx={styles.container}>
        <Box sx={styles.header}>
          <Typography variant="mySubtitle">Configurações de Sessão</Typography>

          {value === 1 && geralValue === 1 && (
            <Button
              variant="contained"
              startIcon={<Add />}
              size="medium"
              onClick={() => {
                setOpenAdicionar(true);
              }}
              disabled={loading}
            >
              NOVA SESSÂO
            </Button>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Tabs
            value={geralValue}
            onChange={handleGeralChange}
            aria-label="basics tabs config"
          >
            <Tab label="Túnel" {...a12yProps(0)} sx={{ fontSize: "14px" }} />
            <Tab label="Vlan" {...a12yProps(1)} sx={{ fontSize: "14px" }} />
          </Tabs>
          {geralValue === 1 && (
            <Box>
              <Select
                size="small"
                value={value}
                defaultValue={value}
                onChange={handleChange}
              >
                <MenuItem value={0}>IPV4</MenuItem>
                <MenuItem value={1}>IPV6</MenuItem>
              </Select>
            </Box>
          )}
        </Box>

        {geralValue === 0 && (
          <Grid container spacing={2}>
            {Array.isArray(dataTunnelIpv4) &&
              dataTunnelIpv4.map((ipv4, index) => (
                <Grid item lg={12} xl={6}>
                  <Paper
                    variant="outlined"
                    key={ipv4}
                    value={index}
                    sx={styles.paper}
                  >
                    <Box sx={styles.cardHeader}>
                      <Box>
                        <Typography variant="capition" sx={styles.cardSubTitle}>
                          Título
                        </Typography>
                        <Typography>{ipv4?.nome}</Typography>
                      </Box>
                    </Box>
                    <Box sx={styles.cardContent}>
                      <Box sx={styles.cardRow}>
                        <Stack direction={"column"} sx={styles.cardStack}>
                          <Typography sx={styles.cardSubTitle}>
                            Source
                          </Typography>
                          <Tooltip>
                            <span
                              onClick={() => {
                                handleCopy(ipv4?.ipSource);
                              }}
                            >
                              <Typography sx={styles.cardText}>
                                {ipv4?.ipSource}
                              </Typography>
                            </span>
                          </Tooltip>
                        </Stack>
                        <Stack direction={"column"} sx={styles.cardStack}>
                          <Typography sx={styles.cardSubTitle}>
                            Destination
                          </Typography>
                          <Tooltip>
                            <span
                              onClick={() => {
                                handleCopy(ipv4?.ipDestiny);
                              }}
                            >
                              <Typography sx={styles.cardText}>
                                {ipv4?.ipDestiny}
                              </Typography>
                            </span>
                          </Tooltip>
                        </Stack>
                      </Box>
                      <Box sx={styles.cardRow}>
                        <Stack direction={"column"} sx={styles.cardStack}>
                          <Typography sx={styles.cardSubTitle}>
                            Tunnel ID
                          </Typography>
                          <Tooltip>
                            <span
                              onClick={() => {
                                handleCopy(ipv4?.idTunnelCliente);
                              }}
                            >
                              <Typography sx={styles.cardText}>
                                {ipv4?.idTunnelCliente}
                              </Typography>
                            </span>
                          </Tooltip>
                        </Stack>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              ))}
          </Grid>
        )}
        {geralValue === 1 && (
          <>
            {value === 0 && (
              <Grid container spacing={2}>
                {Array.isArray(dataVlanIpv4) &&
                  dataVlanIpv4.map(
                    (ipv4, index) =>
                      validarIp(ipv4?.ipLocal, "justIpv4") === 1 &&
                      validarIp(ipv4?.ipCliente, "justIpv4") === 1 && (
                        <Grid item lg={12} xl={6}>
                          <Paper
                            variant="outlined"
                            key={ipv4}
                            value={index}
                            sx={styles.paper}
                          >
                            <Box sx={styles.cardHeader}>
                              <Box>
                                <Typography
                                  variant="capition"
                                  sx={styles.cardSubTitle}
                                >
                                  Título
                                </Typography>
                                <Typography>{ipv4?.titulo}</Typography>
                              </Box>
                            </Box>
                            <Box sx={styles.cardContent}>
                              <Box sx={styles.cardRow}>
                                <Stack
                                  direction={"column"}
                                  sx={styles.cardStack}
                                >
                                  <Typography sx={styles.cardSubTitle}>
                                    Source
                                  </Typography>
                                  <Tooltip>
                                    <span
                                      onClick={() => {
                                        handleCopy(ipv4?.ipLocal);
                                      }}
                                    >
                                      <Typography sx={styles.cardText}>
                                        {ipv4?.ipLocal}
                                      </Typography>
                                    </span>
                                  </Tooltip>
                                </Stack>
                                <Stack
                                  direction={"column"}
                                  sx={styles.cardStack}
                                >
                                  <Typography sx={styles.cardSubTitle}>
                                    Destination
                                  </Typography>
                                  <Tooltip>
                                    <span
                                      onClick={() => {
                                        handleCopy(ipv4?.ipCliente);
                                      }}
                                    >
                                      <Typography sx={styles.cardText}>
                                        {ipv4?.ipCliente}
                                      </Typography>
                                    </span>
                                  </Tooltip>
                                </Stack>
                              </Box>
                              <Box sx={styles.cardRow}>
                                <Stack
                                  direction={"column"}
                                  sx={styles.cardStack}
                                >
                                  <Typography sx={styles.cardSubTitle}>
                                    ASN Vlan
                                  </Typography>
                                  <Tooltip>
                                    <span
                                      onClick={() => {
                                        handleCopy(ipv4?.asnVlan);
                                      }}
                                    >
                                      <Typography sx={styles.cardText}>
                                        {ipv4?.asnVlan}
                                      </Typography>
                                    </span>
                                  </Tooltip>
                                </Stack>
                                <Stack
                                  direction={"column"}
                                  sx={styles.cardStack}
                                >
                                  <Typography sx={styles.cardSubTitle}>
                                    Vlan ID
                                  </Typography>
                                  <Tooltip>
                                    <span
                                      onClick={() => {
                                        handleCopy(ipv4?.vlanId);
                                      }}
                                    >
                                      <Typography sx={styles.cardText}>
                                        {ipv4?.vlanId}
                                      </Typography>
                                    </span>
                                  </Tooltip>
                                </Stack>
                              </Box>
                            </Box>
                          </Paper>
                        </Grid>
                      )
                  )}
              </Grid>
            )}
            {value === 1 && (
              <Grid container spacing={2}>
                {Array.isArray(dataVlanIpv6) &&
                  dataVlanIpv6.map(
                    (ipv6, index) =>
                      validarIp(ipv6?.ipLocal, "justIpv6") === 1 &&
                      validarIp(ipv6?.ipCliente, "justIpv6") === 1 && (
                        <Grid item lg={12} xl={6}>
                          <Paper
                            variant="outlined"
                            key={ipv6}
                            value={index}
                            sx={styles.paper}
                          >
                            <Box sx={styles.cardHeader}>
                              <Box>
                                <Typography
                                  variant="capition"
                                  sx={styles.cardSubTitle}
                                >
                                  Título
                                </Typography>
                                <Typography>{ipv6?.titulo}</Typography>
                              </Box>
                              <IconButton
                                id="basic-button"
                                onClick={(event) => {
                                  setAnchorEl(event.currentTarget);
                                  setSelectedCard(ipv6);
                                }}
                                disabled={loading}
                              >
                                <MoreVert />
                              </IconButton>
                            </Box>
                            <Box sx={styles.cardContent}>
                              <Box sx={styles.cardRow}>
                                <Stack
                                  direction={"column"}
                                  sx={styles.cardStack}
                                >
                                  <Typography sx={styles.cardSubTitle}>
                                    Source
                                  </Typography>
                                  <Tooltip>
                                    <span
                                      onClick={() => {
                                        handleCopy(ipv6?.ipLocal);
                                      }}
                                    >
                                      <Typography sx={styles.cardText}>
                                        {ipv6?.ipLocal}
                                      </Typography>
                                    </span>
                                  </Tooltip>
                                </Stack>
                                <Stack
                                  direction={"column"}
                                  sx={styles.cardStack}
                                >
                                  <Typography sx={styles.cardSubTitle}>
                                    Destination
                                  </Typography>
                                  <Tooltip>
                                    <span
                                      onClick={() => {
                                        handleCopy(ipv6?.ipCliente);
                                      }}
                                    >
                                      <Typography sx={styles.cardText}>
                                        {ipv6?.ipCliente}
                                      </Typography>
                                    </span>
                                  </Tooltip>
                                </Stack>
                              </Box>
                              <Box sx={styles.cardRow}>
                                <Stack
                                  direction={"column"}
                                  sx={styles.cardStack}
                                >
                                  <Typography sx={styles.cardSubTitle}>
                                    ASN Vlan
                                  </Typography>
                                  <Tooltip>
                                    <span
                                      onClick={() => {
                                        handleCopy(ipv6?.asnVlan);
                                      }}
                                    >
                                      <Typography sx={styles.cardText}>
                                        {ipv6?.asnVlan}
                                      </Typography>
                                    </span>
                                  </Tooltip>
                                </Stack>
                                <Stack
                                  direction={"column"}
                                  sx={styles.cardStack}
                                >
                                  <Typography sx={styles.cardSubTitle}>
                                    Vlan ID
                                  </Typography>
                                  <Tooltip>
                                    <span
                                      onClick={() => {
                                        handleCopy(ipv6?.vlanId);
                                      }}
                                    >
                                      <Typography sx={styles.cardText}>
                                        {ipv6?.vlanId}
                                      </Typography>
                                    </span>
                                  </Tooltip>
                                </Stack>
                              </Box>
                            </Box>
                          </Paper>
                        </Grid>
                      )
                  )}
              </Grid>
            )}
          </>
        )}
        <Menu
          id={`basic-menu-${selectedMenuId}`}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          aria-labelledby={selectedMenuId}
        >
          <MenuItem
            onClick={() => {
              setEditMode(true);
              setAnchorEl(null);
            }}
          >
            <Edit sx={{ marginRight: "16px" }}></Edit> Editar
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              setOpenDeleteModal(true);
              setAnchorEl(null);
            }}
          >
            <Delete sx={{ marginRight: "16px" }}></Delete> Deletar
          </MenuItem>
        </Menu>

        <ModalAdicionar
          openAdicionar={openAdicionar}
          setOpenAdicionar={setOpenAdicionar}
          handleAddSession={handleAddSession}
          setSnackbar={setSnackbar}
          clienteId={clienteInfo?.id}
          suggestedTitle={findLast() || ""}
        />
        <ModalEditar
          openEditar={editMode}
          setOpenEditar={setEditMode}
          handleUpdated={handleUpdated}
          selectedCard={selectedCard}
          setSnackbar={setSnackbar}
          clienteId={clienteInfo?.id}
        />
        <InternoModalDeleteCliente
          openDeleteModal={openDeleteModal}
          deleteLoad={deleteLoad}
          setOpenDeleteModal={setOpenDeleteModal}
          execute={handleDelete}
          severity={"error"}
          contentText={deleteBody}
          confirmText={confirmDelete}
        />
        {!!snackbar && (
          <Snackbar
            open
            onClose={() => setSnackbar(null)}
            autoHideDuration={2500}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert {...snackbar} onClose={() => setSnackbar(null)} />
          </Snackbar>
        )}
      </Box>
    </Paper>
  );
}

const initialDataIpv4 = [
  {
    id: 1,
    titulo: "CPEZ-JP-001-AS263284-MAXXIMO",
    ipSource: "FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF",
    ipDestination: "2804:404:404",
    asVlan: "268659",
    tunnelId: "5654821",
  },
  {
    id: 2,
    titulo: "CPEZ-JP-001-AS263284-MAXXIMO",
    ipSource: "FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF",
    ipDestination: "2804:404:404",
    asVlan: "268659",
    tunnelId: "5654821",
  },
  {
    id: 3,
    titulo: "CPEZ-JP-001-AS263284-MAXXIMO",
    ipSource: "FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF",
    ipDestination: "2804:404:404",
    asVlan: "268659",
    tunnelId: "5654821",
  },
  {
    id: 4,
    titulo: "CPEZ-JP-001-AS263284-MAXXIMO",
    ipSource: "FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF",
    ipDestination: "2804:404:404",
    asVlan: "268659",
    tunnelId: "5654821",
  },
  {
    id: 5,
    titulo: "CPEZ-JP-001-AS263284-MAXXIMO",
    ipSource: "FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF",
    ipDestination: "2804:404:404",
    asVlan: "268659",
    tunnelId: "5654821",
  },
  {
    id: 6,
    titulo: "CPEZ-JP-001-AS263284-MAXXIMO",
    ipSource: "FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF",
    ipDestination: "2804:404:404",
    asVlan: "268659",
    tunnelId: "5654821",
  },
  {
    id: 7,
    titulo: "CPEZ-JP-001-AS263284-MAXXIMO",
    ipSource: "FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF",
    ipDestination: "2804:404:404",
    asVlan: "268659",
    tunnelId: "5654821",
  },
  {
    id: 8,
    titulo: "CPEZ-JP-001-AS263284-MAXXIMO",
    ipSource: "FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF",
    ipDestination: "2804:404:404",
    asVlan: "268659",
    tunnelId: "5654821",
  },
  {
    id: 9,
    titulo: "CPEZ-JP-001-AS263284-MAXXIMO",
    ipSource: "FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF",
    ipDestination: "2804:404:404",
    asVlan: "268659",
    tunnelId: "5654821",
  },
  {
    id: 10,
    titulo: "CPEZ-JP-001-AS263284-MAXXIMO",
    ipSource: "FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF",
    ipDestination: "2804:404:404",
    asVlan: "268659",
    tunnelId: "5654821",
  },
];

const initialDataIpv6 = [
  {
    id: 1,
    titulo: "CPEZ-JP-001-AS263284-MAXXIMO",
    ipSource: "FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF",
    ipDestination: "2804:404:404",
    asVlan: "268659",
    idVlan: "ID-N",
  },
  {
    id: 2,
    titulo: "ZEPXS-JP-001-AS263284-MAXXIMO",
    ipSource: "2804:391c:13:750::18",
    ipDestination: "2804:404:404",
    asVlan: "268659",
    idVlan: "ID-N",
  },
  {
    id: 3,
    titulo: "ZEPXS-JP-001-AS263284-MAXXIMO",
    ipSource: "2804:391c:13:750::18",
    ipDestination: "2804:404:404",
    asVlan: "268659",
    idVlan: "ID-N",
  },
  {
    id: 4,
    titulo: "ZEPXS-JP-001-AS263284-MAXXIMO",
    ipSource: "2804:391c:13:750::18",
    ipDestination: "2804:404:404",
    asVlan: "268659",
    idVlan: "ID-N",
  },
  {
    id: 5,
    titulo: "ZEPXS-JP-001-AS263284-MAXXIMO",
    ipSource: "2804:391c:13:750::18",
    ipDestination: "2804:404:404",
    asVlan: "268659",
    idVlan: "ID-N",
  },
  {
    id: 6,
    titulo: "CPEZ-JP-001-AS263284-MAXXIMO",
    ipSource: "FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF:FFFF",
    ipDestination: "2804:404:404",
    asVlan: "268659",
    idVlan: "ID-N",
  },
  {
    id: 7,
    titulo: "ZEPXS-JP-001-AS263284-MAXXIMO",
    ipSource: "2804:391c:13:750::18",
    ipDestination: "2804:404:404",
    asVlan: "268659",
    idVlan: "ID-N",
  },
  {
    id: 8,
    titulo: "ZEPXS-JP-001-AS263284-MAXXIMO",
    ipSource: "2804:391c:13:750::18",
    ipDestination: "2804:404:404",
    asVlan: "268659",
    idVlan: "ID-N",
  },
  {
    id: 9,
    titulo: "ZEPXS-JP-001-AS263284-MAXXIMO",
    ipSource: "2804:391c:13:750::18",
    ipDestination: "2804:404:404",
    asVlan: "268659",
    idVlan: "ID-N",
  },
  {
    id: 10,
    titulo: "ZEPXS-JP-001-AS263284-MAXXIMO",
    ipSource: "2804:391c:13:750::18",
    ipDestination: "2804:404:404",
    asVlan: "268659",
    idVlan: "ID-N",
  },
];
