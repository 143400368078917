import { Add, Delete } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  IconButton,
  Paper,
  Snackbar,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../../services/api";
import InternoModalDeleteCliente from "../../../../components/internoModalDeleteCliente";

export default function Planos(params) {
  const { setChanged = () => {} } = params;
  const [editPlano, setEditPlano] = useState(false);
  const [planos, setPlanos] = useState([]);
  const [planosManipulation, setPlanosManipulation] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const [errors, setErrors] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteLoad, setDeleteLoad] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (planos) {
      setPlanosManipulation(JSON.parse(JSON.stringify(planos)));
    }
  }, [planos]);

  useEffect(() => {
    handleApiGetPlanos();
  }, []);

  async function handleApiGetPlanos() {
    try {
      const response = await api.get("/findMany/planos");

      setPlanos(response.data);
    } catch (error) {
      setSnackbar({
        children: `Error:${
          error?.message || "Não foi possível se buscar as planos"
        } `,
        severity: "error",
      });
    }
  }

  async function handleApiUpdatePlanos() {
    try {
      setDeleteLoad(true);
      const response = await api.put("/editMany/planos", {
        planos: planosManipulation,
      });
      setPlanosManipulation(response.data);
      setEditPlano(false);
      setSnackbar({
        children: "Comunidades atualizadas com sucesso",
        severity: "success",
      });
      setChanged(false);
    } catch (error) {
      setSnackbar({
        children: `Error:${
          error?.message || "Não foi possível atualizar as planos"
        } `,
        severity: "error",
      });
    } finally {
      setDeleteLoad(false);
      setOpenDeleteModal(false);
    }
  }

  function deletePlano(index) {
    setChanged(true);
    const array = [...planosManipulation];
    array.splice(index, 1);
    setPlanosManipulation(array);
  }

  function addPlano() {
    const array = [...planosManipulation];
    array.push({ id: parseInt(Math.random() * 1000000), nome: "" });
    setPlanosManipulation(array);
  }

  function validateFields() {
    const newErrors = {};
    planosManipulation.forEach((plano, index) => {
      if (plano.nome.trim() === "") {
        newErrors[index] = true;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  function handleSave() {
    if (validateFields()) {
      // Obter os IDs das planos que foram manipuladas
      const planosManipulationIds = planosManipulation?.map((com) => com.id);

      // Filtrar as planos que foram removidas
      const planosRemovidos = planos?.map(
        (com) => !planosManipulationIds.includes(com.id)
      );

      // Se tiver alguma removida
      if (planosRemovidos.includes(true)) {
        setOpenDeleteModal(true);
      } else {
        handleApiUpdatePlanos(planosManipulation);
      }
    }
  }

  return (
    <Paper
      sx={{
        padding: "21px",
        display: "flex",
        flexDirection: "column",
        gap: "13px",
        bgcolor:
          theme.palette.mode === "dark" ? "transparent" : "background.paper",
        width: "83%",
      }}
      variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ fontSize: "21px" }}>
          Configurações de planos
        </Typography>
        {editPlano && (
          <Button variant="outlined" endIcon={<Add />} onClick={addPlano}>
            Adicionar plano
          </Button>
        )}
      </Box>
      <Paper
        sx={{
          padding: "21px",
          display: "flex",
          flexDirection: "column",
          gap: "13px",
          bgcolor: "transparent",
        }}
        variant="outlined"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "13px",
            flexWrap: "wrap",
            alignItems: "flex-start",
          }}
        >
          {planosManipulation.map((plano, index) => (
            <Box key={plano.id} sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                variant="filled"
                value={plano.nome}
                label="Plano"
                onChange={(e) => {
                  setChanged(true);
                  const array = [...planosManipulation];
                  array[index].nome = e.target.value;
                  setPlanosManipulation(array);
                  setErrors((prev) => ({ ...prev, [index]: false }));
                }}
                size="small"
                autoComplete="off"
                error={!!errors[index]}
                helperText={errors[index] ? "Este campo é obrigatório" : ""}
                disabled={!editPlano}
                sx={{ width: "250px" }}
              />
              {editPlano ? (
                <IconButton onClick={() => deletePlano(index)}>
                  <Delete />
                </IconButton>
              ) : (
                <Box sx={{ width: "40px" }}></Box>
              )}
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "13px",
            justifyContent: "flex-end",
          }}
        >
          {!editPlano ? (
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                setEditPlano(true);
                setPlanos(planosManipulation);
              }}
            >
              Editar
            </Button>
          ) : (
            <>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  setChanged(false);
                  setEditPlano(false);
                  setPlanosManipulation(JSON.parse(JSON.stringify(planos)));
                  setErrors({});
                }}
              >
                Cancelar
              </Button>
              <Button variant="contained" size="small" onClick={handleSave}>
                Salvar
              </Button>
            </>
          )}
        </Box>
      </Paper>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
      <InternoModalDeleteCliente
        openDeleteModal={openDeleteModal}
        load={deleteLoad}
        setOpenDeleteModal={setOpenDeleteModal}
        execute={() => {
          handleApiUpdatePlanos(planosManipulation);
        }}
        severity={"error"}
        contentText={
          "Um ou mais planos foram removidos, se você prosseguir com a operação, as informações serão excluídas permanentemente!"
        }
        confirmText={"CONFIRMAR"}
      />
    </Paper>
  );
}
