import {
  Paper,
  Stack,
  Button,
  Modal,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../../../../services/api";
import validateIp from "../../../../../../utils/validadorIp";
export default function CreateModalRouter(params) {
  const {
    setOpenCreateModal,
    openCreateModal,
    setSnackbar,
    setLoadingTable,
    styles,
    setErrorName,
    errorName,
    handleNewRouter,
    comparaNome,
    clienteId,
  } = params;
  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const [nomeBgp, setNomeBgp] = useState("");
  const [modo, setModo] = useState("");
  const [ipFlows, setIpFlows] = useState("");
  const [taxaAmostragemV4, setTaxaAmostragemV4] = useState("");
  const [taxaAmostragemV6, setTaxaAmostragemV6] = useState("");
  const [snmpIp, setSnmpIp] = useState("");
  const [snmpCommunity, setSnmpCommunity] = useState("");
  const [snmpPort, setSnmpPort] = useState("");

  useEffect(() => {
    if (
      comparaNome(nomeBgp) ||
      !nomeBgp?.trim() ||
      !modo ||
      validateIp(ipFlows) === 0 ||
      !taxaAmostragemV4 ||
      !taxaAmostragemV6 ||
      validateIp(snmpIp) === 0 ||
      !snmpCommunity ||
      !snmpPort
    ) {
      setDisableCreateButton(true);
    } else {
      setDisableCreateButton(false);
    }
  }, [
    nomeBgp,
    modo,
    ipFlows,
    taxaAmostragemV4,
    taxaAmostragemV6,
    snmpIp,
    snmpCommunity,
    snmpPort,
  ]);
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && disableCreateButton === false) {
      handleApiPostRouter();
    }
  };

  const handleHelperText = (ip) => {
    if (ip) {
      return validateIp(ip) === 1
        ? "O endereço IP é válido!"
        : validateIp(ip) === 2
        ? "O endereço IP é válido!"
        : "Formato de IP inválido";
    }
    return null;
  };
  function clearModal() {
    setIpFlows("");
    setModo("");
    setNomeBgp("");
    setTaxaAmostragemV4("");
    setTaxaAmostragemV6("");
    setSnmpIp("");
    setSnmpCommunity("");
    setSnmpPort("");
  }
  async function handleApiPostRouter() {
    try {
      setLoadingTable(true);
      setDisableCreateButton(true);
      const response = await api.post("/interno/create/router", {
        nome_bgp: nomeBgp,
        modo,
        ip_flows: ipFlows,
        taxa_amostragem_v4: parseInt(taxaAmostragemV4),
        taxa_amostragem_v6: parseInt(taxaAmostragemV6),
        snmp_ip: snmpIp,
        snmp_community: snmpCommunity,
        snmp_port: parseInt(snmpPort),
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados Salvos com sucesso",
          severity: "success",
        });
        if (response.data) {
          handleNewRouter(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingTable(false);
      setDisableCreateButton(false);
      setOpenCreateModal(false);
      clearModal();
    }
  }
  return (
    <>
      <Modal
        open={openCreateModal}
        onClose={() => {
          setOpenCreateModal(false);
          clearModal();
        }}
        sx={{ alignItems: "center", justifyContent: "center", display: "flex" }}
      >
        <Paper sx={styles.paperModal} elevation={3}>
          <DialogTitle color="primary">Novo Routers </DialogTitle>{" "}
          <DialogContent sx={styles.dialogContent}>
            <Stack direction="row" spacing={"20px"} sx={{ mt: 1 }}>
              <TextField
                sx={{ width: "100%" }}
                id="textfield-nome-bgp"
                label="Nome Router"
                value={nomeBgp}
                error={errorName}
                helperText={errorName ? "Nome é uma chave única" : null}
                onChange={(e) => {
                  if (comparaNome(e.target.value)) {
                    setErrorName(true);
                  } else {
                    setErrorName(false);
                  }
                  setNomeBgp(e.target.value);
                }}
              />
            </Stack>
            <Stack direction="row" spacing={"20px"}>
              <FormControl sx={styles.TextField}>
                <InputLabel>Modo</InputLabel>
                <Select
                  value={modo}
                  label="Modo"
                  onChange={(event) => {
                    setModo(event.target.value);
                  }}
                >
                  <MenuItem value={"nfcapd"}>{"Nfcapd"}</MenuItem>
                  <MenuItem value={"sfcapd"}>{"Sfcapd"}</MenuItem>
                </Select>
              </FormControl>
              <TextField
                sx={styles.TextField}
                id="textfield-ip"
                label="Source IP"
                value={ipFlows}
                error={ipFlows ? !validateIp(ipFlows) : false}
                helperText={handleHelperText(ipFlows)}
                onChange={(e) => {
                  setIpFlows(e.target.value);
                }}
              />
            </Stack>
            <Stack direction="row" spacing={"20px"}>
              <TextField
                sx={styles.TextField}
                id="textfield-taxa-amostragem-v4"
                label="Amostragem V4"
                type="number"
                value={taxaAmostragemV4}
                onChange={(e) => setTaxaAmostragemV4(e.target.value)}
              />
              <TextField
                sx={styles.TextField}
                id="textfield-taxa-amostragem-v6"
                label="Amostragem V6"
                type="number"
                value={taxaAmostragemV6}
                onChange={(e) => setTaxaAmostragemV6(e.target.value)}
              />
            </Stack>
            <Stack direction="row" spacing={"20px"}>
              <TextField
                sx={{ width: "100%" }}
                id="textfield-snmp-community"
                label="SNMP Community"
                value={snmpCommunity}
                onChange={(e) => setSnmpCommunity(e.target.value)}
              />
            </Stack>
            <Stack direction="row" spacing={"20px"}>
              <TextField
                sx={styles.TextField}
                id="textfield-snmp-ip"
                label="SNMP IP"
                value={snmpIp}
                error={snmpIp ? !validateIp(snmpIp) : false}
                helperText={handleHelperText(snmpIp)}
                onChange={(e) => {
                  setSnmpIp(e.target.value);
                }}
              />
              <TextField
                sx={styles.TextField}
                id="textfield-snmp-port"
                label="SNMP Port"
                type="number"
                value={snmpPort}
                onKeyDown={handleKeyDown}
                onChange={(e) => setSnmpPort(e.target.value)}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              onClick={() => {
                setOpenCreateModal(false);
                clearModal();
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={disableCreateButton}
              onClick={() => {
                handleApiPostRouter();
              }}
            >
              Criar
            </Button>
          </DialogActions>
        </Paper>
      </Modal>
    </>
  );
}
