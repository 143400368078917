import { bgcolor, display, height, margin, padding, width } from "@mui/system";

const styles = (theme) => ({
  container: {
    width: "450px",
  },
  containerForm: {
    padding: "20px",
    display: "flex",
    flexDirection: "column",
  },
  textfield: {
    margin: "5px 0px",
  },
  containerTitulo: {
    marginBottom: "20px",
  },
  buttonCancel: {
    color: "secondary.main",
  },
  containerButtons: {
    padding: "10px",
    width: "100%",
    display: "flex",
    justifyContent: "end",
  },
});

export default styles;
