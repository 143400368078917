import {
  Box,
  Paper,
  Snackbar,
  Alert,
  Stack,
  Button,
  Tooltip,
  IconButton,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import api from "../../../../../../services/api";
import { Add, Delete, Edit } from "@mui/icons-material";
import EditModalBlocos from "./editModalBlocos";
import CreateModalBlocos from "./createModalBlocos";
import { ptBR } from "@mui/x-data-grid/locales";
import useStyles from "./styles";
import InternoModalDeleteCliente from "../../../../../../components/internoModalDeleteCliente";
import { useOutletContext, useParams } from "react-router-dom";
export default function Blocos(params) {
  const [si, clienteInfo, sb] = useOutletContext();
  const clienteId = clienteInfo?.id || "";

  const rows = [{ id: 14 }];
  //modal
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  //modal delete

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  //tabela
  const [blocos, setBlocos] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const [loadingTable, setLoadingTable] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const theme = useTheme();
  const styles = useStyles(theme);
  const deleteBody =
    "O item será deletado permanentemente. Deseja deletar o item?";
  const confirmDelete = "Excluir";
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      hide: true,
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 1,
    },

    {
      field: "blocos",
      headerName: "Blocos",
      flex: 2,
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,

      renderCell: (params) => {
        const onClick = (e) => {
          const thisRow = params.row;

          hanldeUpDateSelectRow(thisRow);
          setOpenEditModal(true);
        };
        function hanldeUpDateSelectRow(thisRow) {
          setSelectedRow(thisRow);
        }
        const onDelete = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const thisRow = params.row;
          setOpenDeleteModal(true);
          setSelectedRow(thisRow);
        };

        return (
          <Box key={params.id}>
            <Tooltip title={"Editar"} id="basic-button" onClick={onClick}>
              <IconButton size="small">
                <Edit color="primary" />
              </IconButton>
            </Tooltip>

            <IconButton onClick={onDelete}>
              <Delete /*  color={"secondary"} */ />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const comparaNome = (novoNome) => {
    return blocos.some(
      (bloco) =>
        bloco?.nome?.replace(/\s/g, "") === novoNome?.replace(/\s/g, "")
    );
  };
  const comparaOutrosNomes = (novoNome, nomeAtual) => {
    const filtraBlocos = blocos.filter(
      (bloco) =>
        bloco?.nome?.replace(/\s/g, "") !== nomeAtual?.replace(/\s/g, "")
    );
    return filtraBlocos.some(
      (bloco) =>
        bloco?.nome?.replace(/\s/g, "") === novoNome?.replace(/\s/g, "")
    );
  };

  function handleNewBlocos(newBlocos = []) {
    setBlocos((blocos) => {
      const newArray = [...blocos, ...newBlocos];
      return newArray.flat();
    });
  }
  function handleEditBlocos(blocoEditado) {
    setBlocos((blocos) =>
      blocos.map((bloco) => {
        if (bloco.id === blocoEditado.id) {
          return blocoEditado;
        } else {
          return bloco;
        }
      })
    );
  }
  function handleDeleteBloco(id) {
    setBlocos((blocos) => blocos.filter((bloco) => bloco.id !== id));
  }
  useEffect(() => {
    handleApiGetBlocos();
  }, [clienteId]);

  async function handleApiGetBlocos() {
    try {
      setLoadingTable(true);
      const response = await api.post("/interno/findMany/blocos", {
        clienteId,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar dados",
          severity: "error",
        });
      } else {
        if (response.data) {
          setBlocos(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingTable(false);
    }
  }

  async function handleApiDeleteBlocos() {
    try {
      setLoadingTable(true);

      const response = await api.post("/interno/delete/blocos", {
        id: selectedRow.id,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível excluir dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados excluídos com sucesso",
          severity: "success",
        });
        if (response.data) {
          handleDeleteBloco(response.data.id);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingTable(false);

      setOpenDeleteModal(false);
    }
  }
  return (
    <>
      <Box sx={styles.boxContainer}>
        <Paper
          sx={styles.paperContainer}
          elevation={3}
          variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
        >
          <Stack direction={"row"} alignItems={"center"} justifyContent={"end"}>
            <Button
              variant="contained"
              disabled={loadingTable}
              startIcon={<Add />}
              onClick={() => {
                setOpenCreateModal(true);
              }}
            >
              Adicionar
            </Button>
          </Stack>
          <DataGrid
            sx={styles.datagrid}
            autoHeight={true}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={blocos}
            getRowId={(row) => row.id}
            columns={columns}
            initialState={{
              ...rows.initialState,
              columns: {
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  id: false,
                },
              },
            }}
            loading={loadingTable}
            disableColumnMenu
            //checkboxSelection
            disableRowSelectionOnClick={true}
          />{" "}
        </Paper>
      </Box>{" "}
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
      <CreateModalBlocos
        setOpenCreateModal={setOpenCreateModal}
        openCreateModal={openCreateModal}
        setSnackbar={setSnackbar}
        setLoadingTable={setLoadingTable}
        styles={styles}
        setErrorName={setErrorName}
        errorName={errorName}
        comparaNome={comparaNome}
        handleNewBlocos={handleNewBlocos}
        clienteId={clienteId}
      />
      <EditModalBlocos
        setOpenEditModal={setOpenEditModal}
        openEditModal={openEditModal}
        setSnackbar={setSnackbar}
        setLoadingTable={setLoadingTable}
        styles={styles}
        selectedRow={selectedRow}
        setErrorName={setErrorName}
        errorName={errorName}
        comparaOutrosNomes={comparaOutrosNomes}
        handleEditBlocos={handleEditBlocos}
      />
      <InternoModalDeleteCliente
        openDeleteModal={openDeleteModal}
        load={loadingDelete}
        setOpenDeleteModal={setOpenDeleteModal}
        execute={handleApiDeleteBlocos}
        severity={"error"}
        contentText={deleteBody}
        confirmText={confirmDelete}
      />
    </>
  );
}
