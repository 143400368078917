import {
  Box,
  Paper,
  Snackbar,
  Alert,
  Stack,
  Button,
  Tooltip,
  IconButton,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import api from "../../../../../services/api";
import { Add, Delete, Edit } from "@mui/icons-material";
import EditModalRouter from "./editModalRouter";
import CreateModalRouter from "./createModalRouter";
import useStyles from "./styles";
import { ptBR } from "@mui/x-data-grid/locales";
import InternoModalDeleteCliente from "../../../../../components/internoModalDeleteCliente";
import validarIp from "../../../../../utils/validadorIp";
export default function RelatoriosFlow(params) {
  const { clienteId } = params;
  const rows = [{ id: 14 }];
  //modal
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  //modal delete
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  //tabela
  const [routers, setRouters] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const [loadingTable, setLoadingTable] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [routerEdit, setRouterEdit] = useState({});
  const theme = useTheme();
  const styles = useStyles(theme);
  const deleteBody =
    "O item será deletado permanentemente. Deseja deletar o item?";
  const confirmDelete = "Excluir";
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      hide: true,
    },
    {
      field: "nome_bgp",
      headerName: "Nome Router",
      width: 200,
      flex: 1,
    },
    {
      field: "modo",
      headerName: "Modo",
      width: 90,
    },
    {
      field: "ip_flows",
      headerName: "Source IP",
      width: 200,
    },
    {
      field: "taxa_amostragem_v4",
      headerName: "Amostragem V4",
      width: 150,
    },
    {
      field: "taxa_amostragem_v6",
      headerName: "Amostragem V6",
      width: 150,
    },
    {
      field: "snmp_ip",
      headerName: "SNMP IP",
      width: 90,
    },
    {
      field: "snmp_community",
      headerName: "SNMP Community",
      width: 200,
    },
    {
      field: "snmp_port",
      headerName: "SNMP Port",
      width: 120,
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,

      renderCell: (params) => {
        const onClick = (e) => {
          const thisRow = params.row;

          hanldeUpDateSelectRow(thisRow);
          setOpenEditModal(true);
        };
        function hanldeUpDateSelectRow(thisRow) {
          setSelectedRow(thisRow);
          setRouterEdit(thisRow);
        }
        const onDelete = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const thisRow = params.row;

          setOpenDeleteModal(true);
          setSelectedRow(thisRow);
          setRouterEdit(thisRow);
        };
        //
        return (
          <Box key={params.id}>
            <Tooltip title={"Editar"} id="basic-button" onClick={onClick}>
              <IconButton size="small">
                <Edit color="primary" />
              </IconButton>
            </Tooltip>

            <IconButton onClick={onDelete}>
              <Delete color={"secondary"} />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    handleApiGetRouters();
  }, []);

  const comparaNome = (novoNome) => {
    return routers.some(
      (router) =>
        router.nome_bgp?.replace(/\s/g, "") === novoNome?.replace(/\s/g, "")
    );
  };
  const comparaOutrosNomes = (novoNome, nomeAtual) => {
    const filtraRouters = routers.filter(
      (router) =>
        router.nome_bgp?.replace(/\s/g, "") !== nomeAtual?.replace(/\s/g, "")
    );
    return filtraRouters.some(
      (router) =>
        router.nome_bgp?.replace(/\s/g, "") === novoNome?.replace(/\s/g, "")
    );
  };

  function handleNewRouter(newRouter) {
    setRouters((routers) => [...routers, newRouter]);
  }
  function handleEditRouters(routerEditado) {
    setRouters((routers) =>
      routers.map((router) => {
        if (router.id === routerEditado.id) {
          return routerEditado;
        } else {
          return router;
        }
      })
    );
  }
  function handleDeleteRouter(id) {
    setRouters((routers) => routers.filter((router) => router.id !== id));
  }

  async function handleApiGetRouters() {
    try {
      setLoadingTable(true);

      const response = await api.get("/interno/findMany/router");
      setRouters(response.data);
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingTable(false);
    }
  }
  async function handleApiDeleteRouter() {
    setLoadingDelete(true);
    try {
      setLoadingTable(true);

      const response = await api.post("/interno/delete/router", {
        id: selectedRow.id,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível excluir dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados excluídos com sucesso",
          severity: "success",
        });
        if (response.data) {
          handleDeleteRouter(response.data.id);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingTable(false);
      setOpenDeleteModal(false);
      setLoadingDelete(false);
    }
  }
  return (
    <>
      <Box sx={styles.boxContainer}>
        <Paper sx={styles.paperContainer} variant={"outlined"}>
          {" "}
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            {/*   <Typography variant="h6">Routers Flow</Typography>*/}
            <Box></Box>
            <Button
              variant="contained"
              disabled={loadingTable}
              startIcon={<Add />}
              onClick={() => {
                setOpenCreateModal(true);
              }}
            >
              Adicionar
            </Button>
          </Stack>
          <DataGrid
            sx={styles.datagrid}
            autoHeight={true}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={routers}
            columns={columns}
            initialState={{
              ...rows.initialState,
              columns: {
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  id: false,
                },
              },
            }}
            loading={loadingTable}
            disableColumnMenu
            disableRowSelectionOnClick={true}
          />{" "}
        </Paper>
      </Box>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}{" "}
      <CreateModalRouter
        setOpenCreateModal={setOpenCreateModal}
        openCreateModal={openCreateModal}
        setSnackbar={setSnackbar}
        setLoadingTable={setLoadingTable}
        styles={styles}
        selectedRow={selectedRow}
        setErrorName={setErrorName}
        errorName={errorName}
        handleNewRouter={handleNewRouter}
        validarIp={validarIp}
        comparaNome={comparaNome}
        clienteId={clienteId}
      />
      <EditModalRouter
        setOpenEditModal={setOpenEditModal}
        openEditModal={openEditModal}
        setSnackbar={setSnackbar}
        setLoadingTable={setLoadingTable}
        styles={styles}
        selectedRow={selectedRow}
        setErrorName={setErrorName}
        errorName={errorName}
        comparaOutrosNomes={comparaOutrosNomes}
        handleEditRouters={handleEditRouters}
        validarIp={validarIp}
        routerEdit={routerEdit}
        setRouterEdit={setRouterEdit}
      />
      <InternoModalDeleteCliente
        openDeleteModal={openDeleteModal}
        load={loadingDelete}
        setOpenDeleteModal={setOpenDeleteModal}
        execute={handleApiDeleteRouter}
        severity={"error"}
        contentText={deleteBody}
        confirmText={confirmDelete}
      />
    </>
  );
}
