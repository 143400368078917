import {
  IconButton,
  Modal,
  Paper,
  Tooltip,
  Box,
  Badge,
  Avatar,
  Typography,
  Button,
  TextField,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Menu,
  InputAdornment,
} from "@mui/material";
import { Stack, useTheme } from "@mui/system";
import Resizer from "react-image-file-resizer";
import useStyles from "./styles";
import apiCliente from "../../../../services/apiCliente";
import {
  AddAPhoto,
  MoreVertOutlined,
  Person,
  PersonOff,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import InternoModalDeleteCliente from "../../../../components/internoModalDeleteCliente";
export default function ModalEditUserCliente({
  openModalEditUser,
  setOpenModalEditUser = () => {},
  selectedRow,
  setUserEdit = () => {},
  userEdit,
  idCliente,
  setSnackbar = () => {},
  setUserCliente = () => {},
  userCliente,
  permissoes,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [editModalPerfil, setEditModalPerfil] = useState(false);
  const [changeSenha, setChangSenha] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [senhaValid, setSenhaValid] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [confirmSenha, setConfirmSenha] = useState("");
  const [Null, setNull] = useState(false);
  const [senha, setSenha] = useState("");
  const [codigoPermissao, setCodigoPermissao] = useState("");
  const [disableSaveButtom, setDisableSaveButtom] = useState(false);
  const [emailValido, setEmailValido] = useState(false);
  const theme = useTheme();
  const styles = useStyles(theme);
  const open = Boolean(anchorEl);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const deleteBody = `Você está alterando a senha do usuário "${
    selectedRow?.login || ""
  }". Deseja confirmar a alteração da senha?`;
  const confirmDelete = "CONFIRMAR";

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  useEffect(() => {
    setUserEdit({
      id: selectedRow?.id,
      nome: selectedRow?.nome,
      sobrenome: selectedRow?.sobrenome,
      login: selectedRow?.login,
      email: selectedRow?.email,
      status: selectedRow?.status,
      foto: selectedRow?.foto,
    });
    let permissao =
      Array.isArray(permissoes) &&
      permissoes?.filter(
        (permissao) => permissao?.id === selectedRow?.permissoes?.permissaoId
      );
    setCodigoPermissao(
      (Array.isArray(permissao) && permissao[0]?.codigoPermissao) || ""
    );
  }, [selectedRow]);
  useEffect(() => {
    if (senha.length >= 6 && /[a-zA-Z]/.test(senha) && /\d/.test(senha))
      setSenhaValid(true);
    else setSenhaValid(false);
  }, [senha]);

  useEffect(() => {
    if (emailRegex.test(userEdit?.email)) {
      setEmailValido(true);
    } else {
      setEmailValido(false);
    }
  }, [userEdit?.email]);

  const handleUpdate = () => {
    if (!senha || !confirmSenha || confirmSenha !== senha || !senhaValid) {
      setNull(true);
      return;
    }
    setDisableSaveButtom(true);
    setOpenDeleteModal(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        handleUpdate();
      }
    };
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleUpdate();
    }
  };
  const handleResetSenha = async () => {
    try {
      const response = await apiCliente.post("/resetSenhaUserCliente", {
        usuarioId: selectedRow?.id,
        password: senha,
      });
      setChangSenha(false);

      setSnackbar({
        children: "Senha alterada com sucesso",
        severity: "success",
      });
    } catch (error) {
      console.error("Erro ao trocar senha");
      setSnackbar({
        children: "Erro ao alterar senha",
        severity: "error",
      });
    } finally {
      setOpenDeleteModal(false);
      setDisableSaveButtom(false);
    }
  };
  const handleCancelChangeSenha = () => {
    setChangSenha(false);
    setSenhaValid(false);
    setNull(false);
  };

  const handleCancelEditPerfil = () => {
    setEditModalPerfil(false);
    setUserEdit({
      id: selectedRow?.id,
      nome: selectedRow?.nome,
      sobrenome: selectedRow?.sobrenome,
      login: selectedRow?.login,
      email: selectedRow?.email,
      status: selectedRow?.status,
      foto: selectedRow?.foto,
    });
    let permissao =
      Array.isArray(permissoes) &&
      permissoes?.filter(
        (permissao) => permissao?.id === selectedRow?.permissoes?.permissaoId
      );
    setCodigoPermissao(
      (Array.isArray(permissao) && permissao[0]?.codigoPermissao) || ""
    );
  };

  const handleUpdateCliente = () => {
    if (!userEdit?.login || !emailValido) {
      setSnackbar({
        children: "Necessário informar login e e-mail",
        severity: "error",
      });
      setNull(true);
    } else {
      updateUserCliente();
    }
  };

  const updateUserCliente = async () => {
    setDisableSaveButtom(true);
    try {
      let permissaoSelect = permissoes.filter(
        (permissao) => permissao.codigoPermissao === codigoPermissao
      );

      const response = await apiCliente.post("/updateUserCliente", {
        usuarioId: userEdit?.id,
        nome: userEdit?.nome,
        sobrenome: userEdit?.sobrenome,
        login: userEdit?.login,
        email: userEdit?.email,
        status: userEdit?.status,
        foto: userEdit?.foto,
        clienteId: idCliente,
        permissao: (Array.isArray(permissaoSelect) && {
          permissaoId: permissaoSelect[0]?.id || "",
          usuarioId: userEdit?.id,
        }) || {
          permissaoId: selectedRow?.permissoes?.permissaoId,
          usuarioId: userEdit?.id,
        },
      });
      setSnackbar({
        children: "Perfil atualizado com sucesso",
        severity: "success",
      });
      setEditModalPerfil(false);

      const userFilter = userCliente?.filter(
        (user) => user?.id === userEdit?.id
      );
      let objetoUserClient = userFilter[0];

      objetoUserClient = {
        ...objetoUserClient,
        login: userEdit?.login,
        email: userEdit?.email,
        nome: userEdit?.nome,
        sobrenome: userEdit?.sobrenome,
        status: userEdit?.status,
        foto: userEdit?.foto,
        permissoes: (Array.isArray(permissaoSelect) && {
          permissaoId: permissaoSelect[0]?.id,
          usuarioId: userEdit?.id,
        }) || {
          permissaoId: selectedRow?.permissoes?.permissaoId,
          usuarioId: userEdit?.id,
        },
      };
      const newDataUserClient = userCliente?.filter(
        (user) => user?.id !== userEdit?.id
      );
      newDataUserClient.push(objetoUserClient);
      setUserCliente(newDataUserClient);
    } catch (error) {
      let dataError = error?.response?.data;

      if (dataError) {
        if (dataError === "Email já existente") {
          setSnackbar({
            children: "E-mail existente",
            severity: "error",
          });
        } else if (dataError === "Login já existente") {
          setSnackbar({
            children: "Login existente",
            severity: "error",
          });
        } else {
          setSnackbar({
            children: "Erro ao atualizar informações do perfil",
            severity: "error",
          });
        }
      } else {
        setSnackbar({
          children: "Erro ao atualizar informações do perfil",
          severity: "error",
        });
      }
    } finally {
      setDisableSaveButtom(false);
    }
  };

  const handleclose = () => {
    setOpenModalEditUser(false);
    setEditModalPerfil(false);
    setChangSenha(false);
    setSenhaValid(false);
    setNull(false);

    setUserEdit({
      id: selectedRow?.id,
      nome: selectedRow?.nome,
      sobrenome: selectedRow?.sobrenome,
      login: selectedRow?.login,
      email: selectedRow?.email,
      status: selectedRow?.status,
      foto: selectedRow?.foto,
    });
    let permissao =
      Array.isArray(permissoes) &&
      permissoes?.filter(
        (permissao) => permissao?.id === selectedRow?.permissoes?.permissaoId
      );
    setCodigoPermissao(
      (Array.isArray(permissao) && permissao[0]?.codigoPermissao) || ""
    );
  };

  return (
    <Modal
      sx={styles.containerModal}
      open={openModalEditUser}
      onClose={handleclose}
    >
      <Paper
        variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
        sx={styles.container}
      >
        <Stack sx={styles.StackFoto}>
          <Box sx={styles.BoxAvatar}>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                editModalPerfil ? (
                  <Tooltip title={"adicionar imagem"}>
                    <IconButton
                      variant="outlined"
                      component="label"
                      color="primary"
                      sx={styles.containerIconButton}
                    >
                      <AddAPhoto />
                      <input
                        hidden
                        accept=".png, .jpg, .jpeg"
                        type="file"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file?.size < 1024 * 1000) {
                            Resizer.imageFileResizer(
                              file,
                              500,
                              500,
                              "JPEG",
                              80,
                              0,
                              (uri) => {
                                setUserEdit((prev) => ({ ...prev, foto: uri }));
                              },
                              "base64",
                              500,
                              500
                            );
                            const fileReader = new FileReader();
                            fileReader.readAsDataURL(file);
                            fileReader.onload = () => {};
                            fileReader.onerror = (error) => {
                              console.error("Error:", error);
                            };
                          } else {
                            setSnackbar({
                              children: "Tamanho máximo 1MB",
                              severity: "error",
                            });
                          }
                        }}
                      />{" "}
                    </IconButton>
                  </Tooltip>
                ) : (
                  <></>
                )
              }
            >
              <Avatar
                src={userEdit?.foto}
                color="secondary"
                sx={styles.Avatar}
              />
            </Badge>
          </Box>
        </Stack>
        <Box sx={styles.boxAreaModalPerfil}>
          <Box sx={styles.boxAreaTituloModalPerfil}>
            <Typography variant="title">Perfil</Typography>
          </Box>
          <Box sx={styles.boxAreaConteudoModalPerfil}>
            <Box sx={styles.boxAreaButtomModalPerfil}>
              <Box sx={styles.containerButtonSalvar}>
                {!editModalPerfil && !changeSenha && (
                  <Button onClick={handleClick}>
                    <MoreVertOutlined />
                  </Button>
                )}
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setEditModalPerfil(true);
                    }}
                  >
                    Editar
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setChangSenha(true);
                    }}
                  >
                    Alterar senha
                  </MenuItem>
                </Menu>
                {editModalPerfil && (
                  <>
                    <Button
                      onClick={() => handleCancelEditPerfil()}
                      sx={styles.buttonCancelar}
                      variant="text"
                    >
                      {" "}
                      Cancelar
                    </Button>
                    <Button
                      disabled={disableSaveButtom}
                      onClick={handleUpdateCliente}
                      sx={styles.buttonSave}
                      variant="text"
                    >
                      Salvar
                    </Button>
                  </>
                )}
                {changeSenha && (
                  <>
                    <Button
                      onClick={handleCancelChangeSenha}
                      sx={styles.buttonCancelar}
                      variant="text"
                    >
                      {" "}
                      Cancelar
                    </Button>
                    <Button
                      disabled={disableSaveButtom}
                      onClick={() => handleUpdate()}
                      sx={styles.buttonSave}
                      variant="text"
                    >
                      Alterar senha
                    </Button>
                  </>
                )}
              </Box>
            </Box>
            {!changeSenha && (
              <>
                <Stack spacing={1} direction="row">
                  <TextField
                    variant="filled"
                    label="Nome"
                    size="small"
                    value={userEdit?.nome}
                    disabled={!editModalPerfil || !userEdit?.status}
                    sx={styles.TextField}
                    onChange={(e) =>
                      setUserEdit((prev) => ({ ...prev, nome: e.target.value }))
                    }
                  />

                  <TextField
                    variant="filled"
                    label="Sobrenome"
                    size="small"
                    value={userEdit?.sobrenome}
                    disabled={!editModalPerfil || !userEdit?.status}
                    sx={styles.TextField}
                    onChange={(e) =>
                      setUserEdit((prev) => ({
                        ...prev,
                        sobrenome: e.target.value,
                      }))
                    }
                  />
                </Stack>
                <Divider orientation="horizontal" sx={styles.divider} />
                <Stack spacing={1} direction="row">
                  <TextField
                    variant="filled"
                    label="Login"
                    size="small"
                    value={userEdit?.login}
                    disabled={!editModalPerfil || !userEdit?.status}
                    sx={styles.TextField}
                    onChange={(e) =>
                      setUserEdit((prev) => ({
                        ...prev,
                        login: e.target.value,
                      }))
                    }
                    helperText={
                      !userEdit?.login && Null
                        ? "Necessário informar um login"
                        : ""
                    }
                    error={!userEdit?.login && Null}
                  />

                  <TextField
                    variant="filled"
                    label="E-mail"
                    size="small"
                    disabled={!editModalPerfil || !userEdit?.status}
                    value={userEdit?.email}
                    sx={styles.TextField}
                    onChange={(e) =>
                      setUserEdit((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }))
                    }
                    helperText={
                      (!userEdit?.email || !emailValido) && Null
                        ? "E-mail válido"
                        : ""
                    }
                    error={(!userEdit?.email || !emailValido) && Null}
                  />
                </Stack>
                <Divider orientation="horizontal" sx={styles.divider} />

                <Stack spacing={1} direction="row" sx={{ width: "100%" }}>
                  <FormControl
                    variant="filled"
                    size="small"
                    sx={styles.TextField}
                    fullWidth
                    disabled={!editModalPerfil}
                  >
                    <InputLabel>Status</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      value={!userEdit?.status ? "DESATIVADO" : "ATIVO"}
                      onChange={(e) => {
                        setUserEdit((prev) => ({
                          ...prev,
                          status: e.target.value === "DESATIVADO" ? 0 : 1,
                        }));
                      }}
                      name="status"
                      color="primary"
                    >
                      <MenuItem value={"ATIVO"}>
                        <Box sx={styles.boxStatusItem}>
                          <Person sx={styles.person} />
                          <Typography>ATIVO</Typography>
                        </Box>
                      </MenuItem>
                      <MenuItem value={"DESATIVADO"}>
                        <Box sx={styles.boxStatusItem}>
                          <PersonOff sx={styles.person} />
                          <Typography>DESATIVADO</Typography>
                        </Box>
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="filled"
                    size="small"
                    sx={styles.TextField}
                    fullWidth
                    disabled={!editModalPerfil || !userEdit?.status}
                  >
                    <InputLabel>Permissão</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      value={codigoPermissao}
                      label="Permissão"
                      onChange={(event) => {
                        setCodigoPermissao(event.target.value);
                      }}
                      name="status"
                      color="primary"
                    >
                      <MenuItem value={""}>SEM PERMISSÕES</MenuItem>
                      {Array.isArray(permissoes) &&
                        permissoes?.map((permissao, index) => (
                          <MenuItem
                            key={index}
                            value={permissao?.codigoPermissao}
                          >
                            {permissao?.codigoPermissao?.toUpperCase()}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Stack>
              </>
            )}
            {changeSenha && (
              <>
                <Stack
                  direction={"column"}
                  padding={"10px"}
                  gap={"25px"}
                  justifyContent={"flex-start"}
                  alignItems={"flex-start"}
                  width={"100%"}
                >
                  <Stack direction={"column"} width={"100%"}>
                    <Typography variant="title">Alteração de senha</Typography>
                    <Stack direction={"column"} width={"100%"}>
                      <Box>
                        <Typography sx={styles.textBold} variant="mySubtitle1">
                          Login:{" "}
                        </Typography>
                        <Typography variant="mySubtitle1">
                          {selectedRow?.login}
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack direction={"column"} width={"100%"}>
                      <Box>
                        <Typography sx={styles.textBold} variant="mySubtitle1">
                          E-mail:{" "}
                        </Typography>
                        <Typography variant="mySubtitle1">
                          {selectedRow?.email}
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                  <Divider orientation="horizontal" sx={styles.divider} />
                  <Stack direction={"column"} spacing={"10px"} width={"100%"}>
                    <Typography sx={styles.title}>
                      Digite a nova senha e confirme em seguida.
                    </Typography>
                    <Stack spacing={"10px"}>
                      <TextField
                        variant="filled"
                        label="Senha"
                        type={showPassword ? "text" : "password"}
                        error={(!senha || !senhaValid) && Null}
                        onChange={(event) => {
                          setSenha(event.target.value);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword((show) => !show)}
                                onMouseDown={(event) => {
                                  event.preventDefault();
                                }}
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        helperText={
                          (!senha || !senhaValid) && Null
                            ? "A senha precisa ter no mínimo 6 digítos, uma letra e um número."
                            : ""
                        }
                      />
                      <TextField
                        sx={styles.TextField}
                        variant="filled"
                        label="Confirmar Senha"
                        error={
                          (!senha && Null) || (Null && senha !== confirmSenha)
                        }
                        type={showPassword ? "text" : "password"}
                        onKeyDown={handleKeyDown}
                        onChange={(event) => {
                          setConfirmSenha(event.target.value);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword((show) => !show)}
                                onMouseDown={(event) => {
                                  event.preventDefault();
                                }}
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        helperText={
                          (!senha || !senhaValid) && Null
                            ? "A senha precisa ter no mínimo 6 digítos, uma letra e um número."
                            : ""
                        }
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </>
            )}
          </Box>
        </Box>
        <InternoModalDeleteCliente
          openDeleteModal={openDeleteModal}
          deleteLoad={false}
          setOpenDeleteModal={setOpenDeleteModal}
          execute={handleResetSenha}
          severity={"warning"}
          contentText={deleteBody}
          confirmText={confirmDelete}
        />
      </Paper>
    </Modal>
  );
}
