import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../../../../services/api";
import { Add, Delete } from "@mui/icons-material";

export default function CreateModalInterfaces(params) {
  const {
    setOpenCreateModal,
    openCreateModal,
    setSnackbar,
    handleNewInterface,
    setLoadingTable,
    styles,
    routers,
    clienteId,
  } = params;
  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const [listaIndex, setListaIndex] = useState([""]);
  const [interfaceFlow, setInterfaceFlow] = useState("");
  const [router, setRouter] = useState({});
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && disableCreateButton === false) {
      handleApiPostInterface();
    }
  };
  const handleChangeIndex = (e, index) => {
    let auxIndex = [...listaIndex];
    auxIndex[index] = e.target.value.replace(/\s/g, "");
    setListaIndex(auxIndex);
  };
  const addIndex = () => {
    setListaIndex([...listaIndex, ""]);
  };
  const removeIndex = (index) => {
    const newIndex = [...listaIndex];
    newIndex.splice(index, 1);
    setListaIndex(newIndex);
  };
  useEffect(() => {
    if (
      !interfaceFlow ||
      !listaIndex.every((indexValue) => indexValue) ||
      !router
    ) {
      setDisableCreateButton(true);
    } else {
      setDisableCreateButton(false);
    }
  }, [interfaceFlow, listaIndex, router]);
  function clearModal() {
    setListaIndex([""]);
    setInterfaceFlow("");
  }

  async function handleApiPostInterface() {
    try {
      setLoadingTable(true);
      setDisableCreateButton(true);
      const response = await api.post("/interno/create/interface", {
        routerId: router.id,
        interface: interfaceFlow,
        index: listaIndex.join(),
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados Salvos com sucesso",
          severity: "success",
        });
        if (response.data) {
          handleNewInterface(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingTable(false);
      setDisableCreateButton(false);
      setOpenCreateModal(false);
      clearModal();
    }
  }
  return (
    <>
      <Modal
        open={openCreateModal}
        onClose={() => {
          setOpenCreateModal(false);
          clearModal();
        }}
        sx={{ alignItems: "center", justifyContent: "center", display: "flex" }}
      >
        <Paper sx={styles.paperModal} elevation={3}>
          <DialogTitle color="primary">Nova Interface </DialogTitle>{" "}
          <DialogContent sx={styles.dialogContent}>
            <Stack direction="row" spacing={"20px"} sx={{ mt: 1 }}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel>Exportador</InputLabel>
                <Select
                  value={router}
                  label="Exportador"
                  onChange={(event) => {
                    setRouter(event.target.value);
                  }}
                >
                  {routers?.map((router, index) => (
                    <MenuItem key={index} value={router}>
                      {router.nome_bgp ? router.nome_bgp : "Indefinido"}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>{" "}
            </Stack>
            <Stack direction="column" spacing={"20px"}>
              <TextField
                sx={styles.TextField}
                id="textfield-interface"
                label="Interface"
                onKeyDown={handleKeyDown}
                value={interfaceFlow}
                onChange={(e) =>
                  setInterfaceFlow(e.target.value.replace(/\s/g, ""))
                }
              />
              {listaIndex?.map((indexValue, index) => (
                <Stack direction="row" key={index}>
                  <TextField
                    sx={{ width: "100%" }}
                    id="textfield-lista-index"
                    label={"Index " + (index + 1)}
                    type="number"
                    onKeyDown={handleKeyDown}
                    value={indexValue}
                    onChange={(event) => handleChangeIndex(event, index)}
                  />{" "}
                  {listaIndex.length > 1 && (
                    <IconButton
                      onClick={() => removeIndex(index)}
                      sx={{ mr: -1 }}
                    >
                      <Delete color="secondary" />
                    </IconButton>
                  )}
                  <IconButton
                    size="small"
                    onClick={addIndex}
                    sx={{ ml: 1, mr: -1 }}
                  >
                    <Add color="primary" />
                  </IconButton>
                </Stack>
              ))}
              {/*
              <TextField
                sx={styles.TextField}
                id="textfield-index"
                label="Index"
                
                type="number"
                value={listaIndex}
                onChange={(e) => setListaIndex(e.target.value)}
                  />*/}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              onClick={() => {
                setOpenCreateModal(false);
                clearModal();
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={disableCreateButton}
              onClick={() => {
                handleApiPostInterface();
              }}
            >
              Criar
            </Button>
          </DialogActions>
        </Paper>
      </Modal>
    </>
  );
}
