import {
  Box,
  Paper,
  Snackbar,
  Alert,
  Stack,
  Button,
  Tooltip,
  IconButton,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import api from "../../../../../../services/api";
import { Add, Delete, Edit } from "@mui/icons-material";
import EditModalASN from "./editModalASN";

import CreateModalASN from "./createModalASN";
import { ptBR } from "@mui/x-data-grid/locales";
import useStyle from "./styles";
import InternoModalDeleteCliente from "../../../../../../components/internoModalDeleteCliente";
import { useOutletContext } from "react-router-dom";

export default function ASNs(params) {
  const [si, clienteInfo, sb] = useOutletContext();
  const clienteId = clienteInfo?.id || "";
  const rows = [{ id: 14 }];
  //modal
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [errorName, setErrorName] = useState(false);
  //modal delete
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  //tabela
  const [asns, setAsns] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const [loadingTable, setLoadingTable] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const deleteBody =
    "O item será deletado permanentemente. Deseja deletar o item?";
  const confirmDelete = "Excluir";
  const theme = useTheme();
  const styles = useStyle(theme);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      hide: true,
    },
    {
      field: "nome_asn",
      headerName: "Nome",
      width: 200,
    },
    {
      field: "asn",
      headerName: "ASN",
      flex: 3,
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,

      renderCell: (params) => {
        const onClick = (e) => {
          const thisRow = params.row;
          hanldeUpDateSelectRow(thisRow);
          setOpenEditModal(true);
        };
        function hanldeUpDateSelectRow(thisRow) {
          setSelectedRow(thisRow);
        }
        const onDelete = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const thisRow = params.row;
          setOpenDeleteModal(true);
          setSelectedRow(thisRow);
        };
        //
        return (
          <Box key={params.id}>
            <Tooltip title={"Editar"} id="basic-button" onClick={onClick}>
              <IconButton size="small">
                <Edit color="primary" />
              </IconButton>
            </Tooltip>

            <IconButton onClick={onDelete}>
              <Delete /*  color={"secondary"} */ />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const comparaNome = (novoNome) => {
    return asns.some(
      (asn) =>
        asn?.nome_asn?.replace(/\s/g, "") === novoNome?.replace(/\s/g, "")
    );
  };
  const comparaOutrosNomes = (novoNome, nomeAtual) => {
    const filtraAsns = asns.filter(
      (asn) =>
        asn?.nome_asn?.replace(/\s/g, "") !== nomeAtual?.replace(/\s/g, "")
    );
    return filtraAsns.some(
      (asn) =>
        asn?.nome_asn?.replace(/\s/g, "") === novoNome?.replace(/\s/g, "")
    );
  };

  function handleNewAsn(newAsn) {
    setAsns((asns) => [...asns, newAsn]);
  }
  function handleEditAsn(asnEditada) {
    setAsns((asns) =>
      asns.map((asn) => {
        if (asn.id === asnEditada.id) {
          return asnEditada;
        } else {
          return asn;
        }
      })
    );
  }
  function handleDeleteAsn(id) {
    setAsns((asns) => asns.filter((asn) => asn.id !== id));
  }
  useEffect(() => {
    handleApiGetAsns();
  }, [clienteId]);

  async function handleApiGetAsns() {
    try {
      setLoadingTable(true);
      const response = await api.post("/interno/findMany/asn", {
        clienteId,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar dados",
          severity: "error",
        });
      } else {
        if (response.data) {
          setAsns(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingTable(false);
    }
  }

  async function handleApiDeleteAsn() {
    setLoadingDelete(true);
    try {
      setLoadingTable(true);

      const response = await api.post("/interno/delete/asn", {
        id: selectedRow?.id,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível excluir dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados excluídos com sucesso",
          severity: "success",
        });
        if (response.data) {
          handleDeleteAsn(response.data.id);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingTable(false);
      setOpenDeleteModal(false);
      setLoadingDelete(false);
    }
  }
  return (
    <>
      <Box sx={styles.boxContainer}>
        <Paper
          sx={styles.paperContainer}
          elevation={3}
          variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            {/*<Typography variant="h6">ASNs Flow</Typography>*/}
            <Box></Box>
            <Button
              variant="contained"
              disabled={loadingTable}
              startIcon={<Add />}
              onClick={() => {
                setOpenCreateModal(true);
              }}
            >
              Adicionar
            </Button>
          </Stack>
          <DataGrid
            sx={styles.dataGrid}
            //getRowHeight={() => "auto"}
            autoHeight={true}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={asns}
            columns={columns}
            initialState={{
              ...rows.initialState,
              columns: {
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  id: false,
                },
              },
            }}
            loading={loadingTable}
            disableColumnMenu
            // checkboxSelection
            disableRowSelectionOnClick={true}
          />{" "}
        </Paper>
      </Box>{" "}
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
      <CreateModalASN
        setOpenCreateModal={setOpenCreateModal}
        openCreateModal={openCreateModal}
        setSnackbar={setSnackbar}
        setLoadingTable={setLoadingTable}
        styles={styles}
        selectedRow={selectedRow}
        setErrorName={setErrorName}
        errorName={errorName}
        comparaNome={comparaNome}
        handleNewAsn={handleNewAsn}
        //acessoClientesId={acessoClientesId}
        cliente={clienteId}
      />
      <EditModalASN
        setOpenEditModal={setOpenEditModal}
        openEditModal={openEditModal}
        setSnackbar={setSnackbar}
        setLoadingTable={setLoadingTable}
        styles={styles}
        selectedRow={selectedRow}
        setErrorName={setErrorName}
        errorName={errorName}
        comparaOutrosNomes={comparaOutrosNomes}
        handleEditAsn={handleEditAsn}
      />
      <InternoModalDeleteCliente
        openDeleteModal={openDeleteModal}
        load={loadingDelete}
        setOpenDeleteModal={setOpenDeleteModal}
        execute={handleApiDeleteAsn}
        severity={"error"}
        contentText={deleteBody}
        confirmText={confirmDelete}
      />
      {/*  <AlertModal
        openModal={openDeleteModal}
        setOpenModal={setOpenDeleteModal}
        confirmar={confirmarDelete}
        handleButton={handleApiDeleteAsn}
        severity={"error"}
        singleButton={false}
      /> */}
    </>
  );
}
