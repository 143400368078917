import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Modal,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../../../../../services/api";
import { Add, Delete } from "@mui/icons-material";

export default function EditModalASN(params) {
  const {
    setOpenEditModal,
    openEditModal,
    setSnackbar,
    setLoadingTable,
    styles,
    selectedRow,
    setErrorName,
    errorName,
    comparaOutrosNomes,
    handleEditAsn,
  } = params;
  const [disableEditButton, setDisableEditButton] = useState(false);
  const [nomeAsn, setNomeAsn] = useState("");
  const [asn, setAsn] = useState([""]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && disableEditButton === false) {
      handleApiPutAsn();
    }
  };
  const handleChangeAsn = (e, index) => {
    let auxAsn = [...asn];
    auxAsn[index] = e.target.value.trim();
    setAsn(auxAsn);
  };
  const addAsn = () => {
    setAsn([...asn, ""]);
  };
  const removeAsn = (index) => {
    const newAsn = [...asn];
    newAsn.splice(index, 1);
    setAsn(newAsn);
  };
  useEffect(() => {
    if (selectedRow) {
      setAsn(selectedRow.asn ? selectedRow.asn?.split(",") : [""]);
      setNomeAsn(selectedRow.nome_asn);
    }
  }, [selectedRow]);
  useEffect(() => {
    if (
      !nomeAsn?.trim() ||
      comparaOutrosNomes(nomeAsn, selectedRow?.nome_asn) ||
      !asn.every((asnValue) => asnValue)
    ) {
      setDisableEditButton(true);
    } else {
      setDisableEditButton(false);
    }
  }, [asn, nomeAsn]);
  async function handleApiPutAsn() {
    try {
      setLoadingTable(true);
      setDisableEditButton(true);
      const response = await api.post("/interno/update/asn", {
        id: selectedRow.id,
        nome_asn: nomeAsn,
        asn: asn.join(),
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados Salvos com sucesso",
          severity: "success",
        });
        if (response.data) {
          handleEditAsn(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingTable(false);
      setDisableEditButton(false);
      setOpenEditModal(false);
    }
  }
  const handleCancelar = () => {
    setOpenEditModal(false);
    setErrorName(false);
    setAsn(selectedRow.asn ? selectedRow.asn?.split(",") : [""]);
    setNomeAsn(selectedRow.nome_asn);
  };
  return (
    <>
      <Modal
        open={openEditModal}
        onClose={handleCancelar}
        sx={{ alignItems: "center", justifyContent: "center", display: "flex" }}
      >
        <Paper sx={styles.paperModal} elevation={3}>
          <DialogTitle color="primary">Edite ASN </DialogTitle>{" "}
          <DialogContent sx={styles.dialogContent}>
            {" "}
            <Stack direction="column" spacing={"20px"} sx={{ mt: 1 }}>
              <TextField
                sx={styles.TextField}
                id="textfield-nome"
                label="Nome"
                value={nomeAsn}
                error={errorName}
                helperText={errorName ? "Nome é uma chave única" : null}
                onChange={(e) => {
                  e.stopPropagation();
                  if (
                    comparaOutrosNomes(e?.target?.value, selectedRow?.nome_asn)
                  ) {
                    setErrorName(true);
                  } else {
                    setErrorName(false);
                  }
                  setNomeAsn(e.target.value);
                }}
              />
              {asn?.map((asnValue, index) => (
                <Stack direction="row" key={index}>
                  <TextField
                    sx={{ width: "100%" }}
                    id="textfield-asn"
                    label={"ASN " + (index + 1)}
                    type="number"
                    onKeyDown={handleKeyDown}
                    value={asnValue}
                    onChange={(event) => handleChangeAsn(event, index)}
                  />{" "}
                  {asn.length > 1 && (
                    <IconButton
                      onClick={() => removeAsn(index)}
                      sx={{ mr: -1 }}
                    >
                      <Delete color="secondary" />
                    </IconButton>
                  )}
                  <IconButton
                    size="small"
                    onClick={addAsn}
                    sx={{ ml: 1, mr: -1 }}
                  >
                    <Add color="primary" />
                  </IconButton>
                </Stack>
              ))}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={handleCancelar}>
              Cancelar
            </Button>
            <Button
              disabled={disableEditButton}
              onClick={() => {
                handleApiPutAsn();
              }}
            >
              Editar
            </Button>
          </DialogActions>
        </Paper>
      </Modal>
    </>
  );
}
