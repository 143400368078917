import React, { useContext, useEffect, useState } from "react";

import {
  AppBar,
  Avatar,
  Box,
  Container,
  CssBaseline,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
  Tabs,
  Tab,
} from "@mui/material";

import MobileSliderBar from "./mobileDrawer";

import { Outlet, useNavigate } from "react-router-dom";
import useStyles from "./styles";
import { socketCliente as socket } from "../../../services/socket";

import logoComputizeDark from "../../../assets/imgs/logos/logocomputizeDark.webp";

import {
  AttachMoneyOutlined,
  BarChart,
  DarkModeOutlined,
  LightModeOutlined,
  LogoutOutlined,
  MenuOutlined,
  PersonOutline,
  Settings,
  SupervisorAccountOutlined,
} from "@mui/icons-material";
import ModalPerfil from "../../../components/modalPerfil";

export default function LayoutClient(params) {
  const { colorMode, setColorMode } = params;
  const [anchorEl, setAnchorEl] = useState(null);
  const [baseImage, setBaseImage] = useState(
    JSON.parse(localStorage.getItem("perfil"))?.foto || ""
  );
  const [windowWith, setWindowWith] = useState(window.innerWidth);
  const [openModalPerfil, setOpenModalPerfil] = useState(false);

  const [isConnected, setIsConnected] = useState(socket.connected);
  const [value, setValue] = useState(0);
  const [administrador, setAdministrador] = useState(false);
  const [financeiro, setFinanceiro] = useState(false);

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
      socket.emit(
        "first_connection",
        `Servidor juntou-se ao grupo id conection: ${socket.id}`
      );
    }
    function onDisconnect() {
      setIsConnected(false);
    }

    function connectErrorEvent(error) {
      console.log("Socket error - erro de conexão:", error);
    }
    function reconnectAttemptEvent(attempt) {
      console.log(
        `Socket warn - o servido esta tentando ser reconectar. N de tentativas: ${attempt}`
      );
    }
    function reconnectErrorEvent(error) {
      console.log("Socket error - erro de reeconexão:", error);
    }
    function reconnectEvent(attempt) {
      console.log("Socket success: servidor reeconectado com sucesso");
    }

    socket.connect();
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("connect_error", connectErrorEvent);
    socket.io.on("reconnect_attempt", reconnectAttemptEvent);
    socket.io.on("reconnect_error", reconnectErrorEvent);
    socket.io.on("reconnect", reconnectEvent);
    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("connect_error", connectErrorEvent);
      socket.io.off("reconnect_attempt", reconnectAttemptEvent);
      socket.io.off("reconnect_error", reconnectErrorEvent);
      socket.io.off("reconnect", reconnectEvent);
    };
  }, []);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const styles = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    setBaseImage(
      localStorage.getItem("perfil") &&
        JSON.parse(localStorage.getItem("perfil")) != "undefined"
        ? JSON.parse(localStorage.getItem("perfil"))?.foto
        : ""
    );
  }, [localStorage.getItem("perfil")]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWith(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    let result = localStorage.getItem("userCliente");
    result = JSON.parse(result);

    const handleUsuarioId = () => {
      let result = JSON.parse(localStorage.getItem("userCliente"));
      // Verifica se a propriedade rlPermissoesUsuario existe e é um array
      if (Array.isArray(result?.usuarioCliente?.RlPermissoesUsuarioCliente)) {
        // Itera sobre o array para verificar se algum objeto contém a propriedade codigoPermissao com valor "ADM"
        for (let permissao of result?.usuarioCliente
          ?.RlPermissoesUsuarioCliente) {
          if (permissao?.permissao?.codigoPermissao.toUpperCase() === "ADM") {
            setAdministrador(true);
          }
          if (
            permissao?.permissao?.codigoPermissao.toUpperCase() === "FINANCEIRO"
          ) {
            setFinanceiro(true);
          }
        }
        return false;
      }
    };

    handleUsuarioId();

    const pages = ["/home", "/financeiro", "/administracao"];

    const pageName = window.location.pathname;
    pages.map((page, index) => {
      if (pageName === page) {
        setValue(index);
      }
    });
  }, []);

  //funcoes de controle do menu
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const handleCloseModalPerfil = () => {
    setOpenModalPerfil(false);
  };

  return (
    <Box display={"flex"}>
      <CssBaseline />

      <Box>
        <AppBar
          position="fixed"
          elevation={0}
          // color="primary"
        >
          <Container maxWidth="100vw">
            <Toolbar sx={styles.toolbar}>
              {windowWith <= 744 && (
                <IconButton
                  sx={{
                    padding: "0px",
                    transform: "translateX(-20px)",
                    color: "#ffffff",
                  }}
                  onClick={() => {
                    setDrawerOpen(!drawerOpen);
                  }}
                >
                  <MenuOutlined fontSize="large" />
                </IconButton>
              )}
              <Box sx={styles.toolbarNavigate}>
                <IconButton href={"/home"} sx={styles.IconButtonLogo}>
                  <Icon style={styles.logo}>
                    <img
                      src={logoComputizeDark}
                      style={styles.logo}
                      alt="logo-computize-home"
                    />
                  </Icon>
                </IconButton>
                {windowWith > 744 && (
                  <Tabs
                    orientation="horizontal"
                    value={value}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                    textColor={"secondary"}
                    TabIndicatorProps={{
                      style: {
                        background: "secondary.main",
                        display: "none",
                      },
                    }}
                  >
                    <Tab
                      icon={<BarChart />}
                      label={windowWith > 1100 ? "DASHBOARD" : ""}
                      iconPosition="start"
                      value={0}
                      sx={styles.tab}
                      onClick={(event) => {
                        navigate("/home");
                      }}
                    />

                    {(administrador || financeiro) && (
                      <Tab
                        icon={<AttachMoneyOutlined />}
                        label={windowWith > 1100 ? "FINANCEIRO" : ""}
                        iconPosition="start"
                        value={1}
                        sx={styles.tab}
                        onClick={(event) => {
                          navigate("/financeiro");
                        }}
                      />
                    )}
                    {administrador && (
                      <Tab
                        icon={<SupervisorAccountOutlined />}
                        label={windowWith > 1100 ? "ADMINISTRAÇÃO" : ""}
                        iconPosition="start"
                        value={2}
                        sx={styles.tab}
                        onClick={(event) => {
                          navigate("/administracao");
                        }}
                      />
                    )}
                  </Tabs>
                )}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 3,
                  alignItems: "center",
                }}
              >
                <Tooltip title="Abrir Opções">
                  <IconButton id="basic-button" onClick={handleClick}>
                    <Avatar src={baseImage} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>

        <Box sx={{ height: "80px", width: "99vw" }} />
        <Stack direction={"row"} sx={{ backgroundColor: "drawer.main" }}>
          {
            //windowWith > 760 && <SliderBar />
          }
          <MobileSliderBar open={drawerOpen} setOpen={setDrawerOpen} />
          <Box component="main" sx={styles.main}>
            <Box mt={1}>
              <Outlet context={[]} />
            </Box>
          </Box>
        </Stack>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        disableScrollLock={true}
      >
        <MenuItem
          onClick={() => setOpenModalPerfil(true)} //</Menu>onClick={handlePerfil}]
        >
          <ListItemIcon>
            <PersonOutline />
          </ListItemIcon>
          <ListItemText>Perfil</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => setColorMode((prev) => !prev)}>
          {colorMode ? (
            <>
              <ListItemIcon>
                <DarkModeOutlined />
              </ListItemIcon>
              <ListItemText>Modo Noturno</ListItemText>
            </>
          ) : (
            <>
              <ListItemIcon>
                <LightModeOutlined />
              </ListItemIcon>
              <ListItemText>Modo Claro</ListItemText>
            </>
          )}
        </MenuItem>

        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutOutlined />
          </ListItemIcon>
          <ListItemText>Sair</ListItemText>
        </MenuItem>
      </Menu>
      <ModalPerfil
        open={openModalPerfil}
        handleClose={handleCloseModalPerfil}
      />
    </Box>
  );
}
