import {
  Box,
  Button,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import validarIp from "../../../../../../utils/validadorIp";
import useStyles from "./styles";
import api from "../../../../../../services/api";
import axios from "axios";

export default function ModalAdicionar(params) {
  const {
    openAdicionar = false,
    setOpenAdicionar = () => {},
    setSnackbar = () => {},
    handleAddVlan = () => {},
    clienteId = "",
    suggestedTitle = "",
    clienteInfo = {},
  } = params;
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [checouAs, setChecouAs] = useState(true);
  const [loadingASN, setLoadingASN] = useState(false);

  const regexNums = /-(\d{3})-/;
  const nums = suggestedTitle?.match(regexNums) || "";
  const num = parseInt(nums[1]);
  const newNum = (num + 1).toString().padStart(3, "0");
  const title = suggestedTitle?.replace(regexNums, "-" + newNum + "-");
  const [formData, setFormData] = useState({
    titulo: title || "",
    vlanId: "", //{0-9}
    ipLocal: "", //{ipv4}
    ipCliente: "", //{ipv4}
    asnVlan: "",
  });
  useEffect(() => {
    if (openAdicionar) {
      setFormData({
        titulo: title || "",
        vlanId: "", //{0-9}
        ipLocal: "", //{ipv4}
        ipCliente: "", //{ipv4}
        asnVlan: "",
      });
    }
    setErrors({});
  }, [openAdicionar]);

  function handleInputChange(e) {
    const { name, value } = e.target;
    if (name === "asnVlan") {
      // impede submit sem checar validade do as
      setChecouAs(false);
      // permite apenas numeros
      const regexNums = /\d+/g;
      const nums = value?.match(regexNums)?.join("") || "";
      setFormData((prev) => ({ ...prev, [name]: nums }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
    if (errors[name]) setErrors((prev) => ({ ...prev, [name]: "" }));
  }

  async function validarAs(asnVlan) {
    try {
      setLoadingASN(true);
      //Checa se é vazio
      if (asnVlan?.trim() === "") {
        throw new Error("ASN não encontrado");
      }
      const response = await axios.get(
        "https://stat.ripe.net/data/as-overview/data.json",
        {
          params: {
            resource: asnVlan,
          },
        }
      ); //268659

      if (!response?.data?.data?.announced) {
        throw new Error("ASN não encontrado");
      }
      //sucesso
    } catch (error) {
      console.error(error);
      setErrors((prev) => ({
        ...prev,
        asnVlan:
          error?.message === "ASN não encontrado"
            ? error?.message
            : error?.message === "ASN é obrigatório"
            ? error?.message
            : "ASN inválido",
      }));
    } finally {
      setLoadingASN(false);
      setChecouAs(true);
    }
  }

  function handleBlur(value, name) {
    if (name === "titulo") {
      if (value?.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          [name]: "Título obrigatório",
        }));
      }
    } else if (name === "ipLocal") {
      if (value?.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          [name]: "IP obrigatório",
        }));
      } else {
        const isValid = validarIp(value, "justIpv4");

        if (isValid === 1) {
          setErrors((prev) => ({ ...prev, [name]: "" }));
        } else {
          setErrors((prev) => ({
            ...prev,
            [name]: "IP inválido",
          }));
        }
      }
    } else if (name === "ipCliente") {
      if (value?.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          [name]: "IP obrigatório",
        }));
      } else {
        const isValid = validarIp(value, "justIpv4");

        if (isValid === 1) {
          setErrors((prev) => ({ ...prev, [name]: "" }));
        } else {
          setErrors((prev) => ({
            ...prev,
            [name]: "IP inválido",
          }));
        }
      }
    } else if (name === "vlanId") {
      if (value?.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          [name]: "ID obrigatório",
        }));
      }
    } else if (name === "asnVlan") {
      if (value?.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          [name]: "ASN obrigatório",
        }));
      } else {
        validarAs(value);
      }
    }
  }

  async function handleSubmit() {
    try {
      setLoading(true);
      let formError = false;
      let isValid;

      if (formData?.titulo?.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          titulo: "Título obrigatório",
        }));
        formError = true;
      }

      if (formData?.vlanId?.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          vlanId: "ID obrigatório",
        }));
        formError = true;
      }

      if (formData?.asnVlan?.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          asnVlan: "ASN obrigatório",
        }));
        formError = true;
      } else {
        validarAs(formData?.asnVlan);
      }

      if (formData?.ipLocal?.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          ipLocal: "IP obrigatório",
        }));
        formError = true;
      } else {
        isValid = validarIp(formData?.ipLocal, "justIpv4");
        if (isValid === 1) {
          setErrors((prev) => ({ ...prev, ipLocal: "" }));
        } else {
          setErrors((prev) => ({
            ...prev,
            ipLocal: "IP inválido",
          }));
          formError = true;
        }
      }

      if (formData?.ipCliente?.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          ipCliente: "IP obrigatório",
        }));
        formError = true;
      } else {
        isValid = validarIp(formData?.ipCliente, "justIpv4");
        if (isValid === 1) {
          setErrors((prev) => ({ ...prev, ipCliente: "" }));
        } else {
          setErrors((prev) => ({
            ...prev,
            ipCliente: "IP inválido",
          }));
          formError = true;
        }
      }

      if (formError || !checouAs) {
        return;
      }

      const response = await api.post("/interno/create/vlan", {
        titulo: formData?.titulo,
        ipLocal: formData?.ipLocal,
        ipCliente: formData?.ipCliente,
        vlanId: formData?.vlanId,
        asnVlan: formData?.asnVlan,
        clienteId: clienteId,
      });
      // on success
      handleAddVlan(formData);
      setErrors({});

      setSnackbar({
        children: "Vlan criada com sucesso!",
        severity: "success",
      });
      handleClose();
    } catch (error) {
      console.log(error);
      const newErrors = {};
      error?.errors?.forEach((err) => {
        newErrors[err.path[0]] = err.message;
      });
      setErrors(newErrors);
      setSnackbar({
        children: "Error: Não foi possivel se conectar ao servidor",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  const handleClose = () => {
    setErrors({});
    setOpenAdicionar(false);
    setFormData({
      titulo: "",
      vlanId: "", //{0-9}
      ipLocal: "", //{ipv4}
      ipCliente: "", //{ipv4}
      asnVlan: "",
    });
  };

  return (
    <Modal open={openAdicionar} onClose={handleClose} sx={styles.modal}>
      <Paper sx={styles.paper}>
        <Box sx={styles.boxPaper}>
          <Typography variant="mySubtitle">Nova VLAN</Typography>
          <Box sx={styles.boxPaper}>
            <Stack direction={"row"} sx={styles.cardStack}>
              <TextField
                autoComplete="off"
                variant="filled"
                id="titulo"
                name="titulo"
                label="Título da Vlan"
                value={formData?.titulo}
                onChange={handleInputChange}
                onBlur={(e) => handleBlur(e.target.value, e.target.name)}
                error={Boolean(errors?.titulo)}
                helperText={errors?.titulo}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    document.getElementById("id").focus();
                  }
                }}
              />
              <TextField
                autoComplete="off"
                variant="filled"
                id="id"
                name="vlanId"
                label="Vlan ID"
                value={formData?.vlanId}
                onChange={handleInputChange}
                onBlur={(e) => handleBlur(e.target.value, e.target.name)}
                error={Boolean(errors?.vlanId)}
                helperText={errors?.vlanId}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    document.getElementById("ipLocal").focus();
                  }
                }}
              />
            </Stack>

            <Stack direction={"row"} sx={styles.cardStack}>
              <TextField
                autoComplete="off"
                variant="filled"
                id="ipLocal"
                name="ipLocal"
                label="IP Local"
                value={formData?.ipLocal}
                onChange={handleInputChange}
                onBlur={(e) => handleBlur(e.target.value, e.target.name)}
                error={Boolean(errors?.ipLocal)}
                helperText={errors?.ipLocal}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    document.getElementById("ipCliente").focus();
                  }
                }}
              />
              <TextField
                autoComplete="off"
                variant="filled"
                id="ipCliente"
                name="ipCliente"
                label="IP Cliente"
                value={formData?.ipCliente}
                onChange={handleInputChange}
                onBlur={(e) => handleBlur(e.target.value, e.target.name)}
                error={Boolean(errors?.ipCliente)}
                helperText={errors?.ipCliente}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    document.getElementById("asnVlan").focus();
                  }
                }}
              />
            </Stack>
            <Stack direction={"row"} sx={styles.cardStack}>
              <TextField
                autoComplete="off"
                variant="filled"
                id="asnVlan"
                name="asnVlan"
                label="ASN Vlan"
                sx={styles.asTextField}
                value={formData?.asnVlan}
                onChange={handleInputChange}
                onBlur={(e) => handleBlur(e.target.value, e.target.name)}
                error={Boolean(errors?.asnVlan)}
                helperText={errors?.asnVlan}
                disabled={loadingASN}
              />
            </Stack>
          </Box>
          <Stack direction={"row"} sx={styles.buttonStack}>
            <Button onClick={handleClose} disabled={loading}>
              Cancelar
            </Button>
            <Button
              onClick={() => {
                handleSubmit();
              }}
              variant="contained"
              disabled={loading}
            >
              Salvar
            </Button>
          </Stack>
        </Box>
      </Paper>
    </Modal>
  );
}
