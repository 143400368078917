import { Box, minWidth, Stack } from "@mui/system";
import { useTheme } from "@mui/system";
import useStyles from "./styles";
import dayjs from "dayjs";
import {
  Alert,
  Avatar,
  Button,
  Chip,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  Menu,
  Select,
  InputLabel,
  FormControl,
  Autocomplete,
} from "@mui/material";
import {
  AccessTimeFilled,
  Add,
  Clear,
  Delete,
  Edit,
  Person,
  PersonOff,
  Search,
  Settings,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import api from "../../../../services/api";
import InternoModalDeleteCliente from "../../../../components/internoModalDeleteCliente";
import ModalAddUserClient from "./ModalAddUserCliente";
import ModalEditUserCliente from "./ModalEditUserCliente";
export default function Clientes() {
  const [userCliente, setUserCliente] = useState([]);
  const [buscarUsuario, setBuscarUsuario] = useState("");
  const [snackbar, setSnackbar] = useState(null);
  const [permissoes, setPermissoes] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openModalEditUser, setOpenModalEditUser] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [openModalAddUser, setOpenModalAddUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [createButton, setCreateButton] = useState(false);
  const [allClientes, setAllClientes] = useState([]);
  const [selectCliente, setSelectCliente] = useState("");
  const [newUserCliente, setNewUserClient] = useState({
    login: "",
    email: "",
    senha: "",
    cliente: "",
  });
  const [userEdit, setUserEdit] = useState({
    id: "",
    nome: "",
    sobrenome: "",
    login: "",
    email: "",
    status: "",
    foto: "",
    cliente: {},
  });

  const theme = useTheme();
  const styles = useStyles(theme);
  const open = Boolean(anchorEl);
  const deleteBody =
    "O usuário será deletado permanentemente. Deseja deletar excluir o usuário?";
  const confirmDelete = "Excluir";

  const filteredRows = userCliente
    ?.filter((row, index) => {
      row.numero = index;

      return (
        row.login?.toLowerCase()?.includes(buscarUsuario?.toLowerCase()) ||
        row.nome?.toLowerCase()?.includes(buscarUsuario?.toLowerCase()) ||
        row.email?.toLowerCase()?.includes(buscarUsuario?.toLowerCase()) ||
        row.sobrenome?.toLowerCase()?.includes(buscarUsuario?.toLowerCase()) ||
        row?.cliente?.nomeFantasia
          ?.toLowerCase()
          ?.includes(buscarUsuario?.toLowerCase())
      );
    })
    ?.filter((cliente) =>
      selectCliente ? cliente?.cliente?.nomeFantasia === selectCliente : cliente
    );

  useEffect(() => {
    getUserClient();
    getPermissoes();
    getAllClientes();
  }, []);

  const getUserClient = async () => {
    setLoading(true);
    try {
      const response = await api.get("/interno/findUserCliente");

      setUserCliente(response?.data);
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possivel se conectar ao servidor",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };
  const getAllClientes = async () => {
    try {
      const response = await api.get("/interno/clientes");
      setAllClientes(response?.data);
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possivel buscar clientes",
        severity: "error",
      });
      console.error(error);
    } finally {
    }
  };

  const deleteUserClient = async (idUserCliente) => {
    setLoadingDelete(true);
    try {
      const response = await api.post("/interno/deleteUserCliente", {
        idUserCliente: idUserCliente,
      });
      setOpenDeleteModal(false);
      const userClientes = userCliente.filter(
        (userCliente) => userCliente?.id !== idUserCliente
      );
      setUserCliente(userClientes);
      setSnackbar({
        children: "Usuário excluído com sucesso!",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possivel se conectar ao servidor",
        severity: "error",
      });
    } finally {
      setLoadingDelete(false);
    }
  };

  async function getPermissoes() {
    try {
      const response = await api.get("/interno/getPermissoesCliente");

      setPermissoes(response?.data);
    } catch (error) {
      console.error("Erro ao puxar permissões dos clientes");
    } finally {
    }
  }
  const createUserCliente = async () => {
    try {
      const response = await api.post("/interno/createUserCliente", {
        login: newUserCliente?.login,
        senha: newUserCliente?.senha,
        email: newUserCliente?.email,
        clienteId: newUserCliente?.cliente,
      });
      setOpenModalAddUser(false);

      setUserCliente((prev) => [...prev, response?.data]);
      setNewUserClient({
        login: "",
        email: "",
        senha: "",
        cliente: "",
      });
      setSnackbar({
        children: "Usuário criado com sucesso!",
        severity: "success",
      });
    } catch (error) {
      let menssegeErro = error?.response?.data.error;
      if (menssegeErro === "Email already exists!") {
        setSnackbar({
          children: "E-mail existente",
          severity: "error",
        });
      } else if (menssegeErro === "User already exists!") {
        setSnackbar({
          children: "Usuário existente",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Error: Não foi possivel se conectar ao servidor",
          severity: "error",
        });
      }
    } finally {
      setCreateButton(false);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: "avatar",
      headerName: "",
      maxWidth: 70,
      minWidth: 70,
      flex: 1,
      sortable: false,

      renderCell: (params) => {
        const foto = params?.row?.foto ? params?.row?.foto : "";
        return (
          <Box sx={styles.containerFotoUser}>
            <Avatar
              sx={styles.containerAvatar}
              alt={params.row.foto ? params.row.nome : ""}
              src={foto ? foto : "/"}
            />
          </Box>
        );
      },
    },
    {
      field: "login",
      headerName: "Login",
      width: 60,
      minWidth: 100,
      flex: 1,
    },
    {
      field: "nome",
      headerName: "Nome",
      width: 80,
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return `${params?.row?.nome || ""} ${params?.row?.sobrenome || ""}`;
      },
    },
    {
      field: "email",
      headerName: "E-mail",
      width: 60,
      minWidth: 120,
      flex: 1,
      valueGetter: (params) => {
        return `${params || ""}`;
      },
    },
    {
      field: "cliente",
      headerName: "Cliente",
      width: 60,
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => {
        return `${params?.nomeFantasia || ""}`;
      },
    },
    {
      field: "permissoes",
      headerName: "Permissões",
      width: 40,
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => {
        return params?.permissaoId;
      },
      renderCell: (params) => {
        return (
          <>
            {permissoes
              .filter(
                (permissao) =>
                  permissao?.id === params?.row?.permissoes?.permissaoId
              )
              .map((perm, index) => (
                <Box sx={styles.conatinerChip}>
                  <Chip
                    key={index}
                    label={perm?.codigoPermissao?.toUpperCase()}
                    sx={
                      perm?.codigoPermissao?.toUpperCase() === "ADM"
                        ? {
                            ...styles.chipPermissoes,
                            backgroundColor: "chip.waitingReview",
                          }
                        : {
                            ...styles.chipPermissoes,
                            backgroundColor: "chip.waitingReturn",
                          }
                    }
                  />
                </Box>
              ))}
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 40,
      minWidth: 70,
      flex: 1,
      valueGetter: (params) => {
        return params;
      },
      renderCell: (params) => {
        return (
          <>
            {params?.row?.status === 1 && params?.row?.ultimoAcesso ? (
              <Box sx={styles.containerPrimeiroAcesso}>
                <Tooltip title="Perfil ativo">
                  <Person sx={styles.person} />
                </Tooltip>
              </Box>
            ) : !params?.row?.status ? (
              <Box sx={styles.containerPrimeiroAcesso}>
                <Tooltip title="Perfil desativado">
                  <PersonOff sx={styles.personOff} />
                </Tooltip>
              </Box>
            ) : params?.row?.status === 1 && !params?.row?.ultimoAcesso ? (
              <Box sx={styles.containerPrimeiroAcesso}>
                <Tooltip title="Aguardando primeiro login">
                  <Box>
                    <Person sx={styles.personOff} />
                    <AccessTimeFilled sx={styles.containerIconTime} />
                  </Box>
                </Tooltip>
              </Box>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
    {
      field: "ultimoAcesso",
      headerName: "Último Acesso",
      width: 60,
      minWidth: 120,
      flex: 1,
      valueGetter: (params) => {
        return (
          (params?.createAt &&
            dayjs(params?.createAt).format("DD/MM/YYYY HH:mm")) ||
          ""
        );
      },
    },

    {
      field: "detalhes",
      headerName: "Detalhes",
      maxWidth: 70,
      minWidth: 70,
      flex: 1,
      sortable: false,

      renderCell: (params) => {
        return (
          <Tooltip title="Visualizar perfil">
            <IconButton
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                setSelectedRow(params.row);
              }}
              id="basic-button"
              disabled={
                JSON.parse(localStorage.getItem("user"))?.usuario?.id ===
                params.row.id
              }
            >
              <Settings
                mode="view"
                params={params}
                sx={{
                  color:
                    JSON.parse(localStorage.getItem("user"))?.usuario?.id ===
                    params.row.id
                      ? "chip.arquive"
                      : "primary.main",
                }}
              />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <Box sx={styles.container}>
      <Paper
        sx={styles.paper}
        variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
        elevation={3}
      >
        <Stack sx={styles.BoxStack}>
          <Box sx={styles.containerBuscar}>
            <TextField
              variant="filled"
              label="Buscar usuário"
              value={buscarUsuario}
              size="small"
              onChange={(event) => {
                setBuscarUsuario(event.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: buscarUsuario ? (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setBuscarUsuario("")} edge="end">
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
          </Box>
          <Box sx={styles.containerAcoes}>
            <Box sx={styles.containerSelect}>
              <Autocomplete
                size="small"
                disablePortal
                options={allClientes}
                getOptionLabel={(option) => option?.nomeFantasia}
                sx={{ width: "200px" }}
                onChange={(event, cliente) => {
                  setSelectCliente(cliente?.nomeFantasia);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Cliente" />
                )}
              />
            </Box>

            <Box sx={styles.containerButton}>
              <Button
                endIcon={<Add sx={styles.containerAdd} />}
                onClick={() => setOpenModalAddUser(true)}
                sx={styles.buttomAddUser}
                variant="contained"
              >
                Novo Usuário Cliente
              </Button>
            </Box>
          </Box>
        </Stack>
        <DataGrid
          rows={filteredRows}
          columns={columns}
          rowsPerPageOptions={[25, 50, 100]}
          autoHeight={true}
          pageSizeOptions={[5, 10, 20]}
          initialState={{
            pagination: {
              paginationModel: { page: 1, pageSize: 10 },
            },
          }}
          disableRowSelectionOnClick
          sx={styles.dataGrid}
          disableColumnMenu
          loading={loading}
        />
      </Paper>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        disableScrollLock={true}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setOpenModalEditUser(true);
          }}
        >
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText>Editar</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenDeleteModal(true);
            setAnchorEl(null);
          }}
          disabled={loadingDelete}
        >
          <ListItemIcon>
            <Delete color="error" />
          </ListItemIcon>
          <ListItemText>Excluir</ListItemText>
        </MenuItem>
      </Menu>
      <InternoModalDeleteCliente
        openDeleteModal={openDeleteModal}
        load={loadingDelete}
        setOpenDeleteModal={setOpenDeleteModal}
        execute={() => {
          deleteUserClient(selectedRow?.id);
        }}
        severity={"error"}
        contentText={deleteBody}
        confirmText={confirmDelete}
      />
      <ModalAddUserClient
        open={openModalAddUser}
        setOpenModalAddUser={setOpenModalAddUser}
        setNewUserClient={setNewUserClient}
        createUserCliente={createUserCliente}
        createButton={createButton}
        newUserCliente={newUserCliente}
        setSnackbar={setSnackbar}
      />
      <ModalEditUserCliente
        openModalEditUser={openModalEditUser}
        setOpenModalEditUser={setOpenModalEditUser}
        selectedRow={selectedRow}
        setUserEdit={setUserEdit}
        userEdit={userEdit}
        setSnackbar={setSnackbar}
        snackbar={snackbar}
        setUserCliente={setUserCliente}
        userCliente={userCliente}
        permissoes={permissoes}
        allClientes={allClientes}
      />
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
