import { React, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Tab,
  Typography,
  useTheme,
  Paper,
  Tooltip,
  Menu,
  MenuItem,
  Select,
  Tabs,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import validarIp from "../../../../utils/validadorIp";
import useStyles from "./styles";
import apiCliente from "../../../../services/apiCliente";
export default function SessoesCliente(params) {
  const theme = useTheme();
  const [selectedMenuId, setSelectedMenuId] = useState("basic-buttom");
  const [snackbar, setSnackbar] = useState(null);
  const [geralValue, setGeralValue] = useState(0);
  const [valueSessoes, setValueSessoes] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tuneis, setTuneis] = useState({});
  const [vlans, setVlans] = useState({});
  const [loading, setLoading] = useState(true);
  const styles = useStyles(theme);
  const open = Boolean(anchorEl);

  useEffect(() => {
    getAllTunneis();
    getAllVlans();
  }, []);

  async function getAllTunneis() {
    setLoading(true);
    try {
      const response = await apiCliente.post("/solicitacoes/get/tuneis", {
        clienteId: JSON.parse(
          localStorage.getItem("userCliente")
        )?.usuarioCliente?.clientId?.toString(),
      });
      setTuneis(response?.data);
      setLoading(false);
    } catch (e) {
      console.error("erro ao buscar prefixos ss", e);
    }
  }

  async function getAllVlans() {
    try {
      const response = await apiCliente.post("/solicitacoes/get/vlan", {
        clienteId: JSON.parse(
          localStorage.getItem("userCliente")
        )?.usuarioCliente?.clientId?.toString(),
      });
      setVlans(response?.data);
    } catch (e) {
      console.error("erro ao buscar prefixos ss", e);
    }
  }
  function a12yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleGeralChange = (event, newValue) => {
    setGeralValue(newValue);
  };

  const handleChangeSessoes = (event) => {
    setValueSessoes(event.target.value);
  };

  ////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////

  //////////////////TUNEIS////////////////////
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={styles.header}>
        <Typography variant="mySubtitle">Configurações de Sessão</Typography>
      </Box>
      {loading ? (
        <Box sx={styles.containerLoading}>
          {" "}
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box sx={styles.containerTabs}>
            <Tabs
              value={geralValue}
              onChange={handleGeralChange}
              aria-label="basics tabs config"
              sx={{ marginBottom: "20px" }}
            >
              <Tab label="Túnel" {...a12yProps(0)} sx={{ fontSize: "14px" }} />
              <Tab label="Vlan" {...a12yProps(1)} sx={{ fontSize: "14px" }} />
            </Tabs>

            {geralValue === 1 && (
              <Box>
                <Select
                  size="small"
                  value={valueSessoes}
                  defaultValue={valueSessoes}
                  onChange={handleChangeSessoes}
                >
                  <MenuItem value={0}>IPV4</MenuItem>
                  <MenuItem value={1}>IPV6</MenuItem>
                </Select>
              </Box>
            )}
          </Box>

          {geralValue === 0 && (
            <Grid container spacing={2}>
              {Array.isArray(tuneis) &&
                tuneis.map((tunel, index) => (
                  <Grid item lg={12} xl={6}>
                    <Paper
                      variant="outlined"
                      key={tunel}
                      value={index}
                      sx={styles.paper}
                    >
                      <Box sx={styles.cardHeader}>
                        <Box>
                          <Typography
                            variant="capition"
                            sx={styles.cardSubTitle}
                          >
                            Título
                          </Typography>
                          <Typography>{tunel?.nome}</Typography>
                        </Box>
                      </Box>
                      <Box sx={styles.cardContent}>
                        <Box sx={styles.cardRow}>
                          <Stack direction={"column"} sx={styles.cardStack}>
                            <Typography sx={styles.cardSubTitle}>
                              Source
                            </Typography>
                            <Tooltip>
                              <span
                              /* onClick={() => {
                                  handleCopy(tunel?.ipSource);
                                }} */
                              >
                                <Typography sx={styles.cardText}>
                                  {tunel?.ipSource}
                                </Typography>
                              </span>
                            </Tooltip>
                          </Stack>
                          <Stack direction={"column"} sx={styles.cardStack}>
                            <Typography sx={styles.cardSubTitle}>
                              Destination
                            </Typography>
                            <Tooltip>
                              <span
                              /*  onClick={() => {
                                  handleCopy(tunel?.ipDestiny);
                                }} */
                              >
                                <Typography sx={styles.cardText}>
                                  {tunel?.ipDestiny}
                                </Typography>
                              </span>
                            </Tooltip>
                          </Stack>
                        </Box>
                        <Box sx={styles.cardRow}>
                          <Stack direction={"column"} sx={styles.cardStack}>
                            <Typography sx={styles.cardSubTitle}>
                              Tunnel ID
                            </Typography>
                            <Tooltip>
                              <span
                              /* onClick={() => {
                                  handleCopy(tunel?.idTunnelCliente);
                                }} */
                              >
                                <Typography sx={styles.cardText}>
                                  {tunel?.idTunnelCliente}
                                </Typography>
                              </span>
                            </Tooltip>
                          </Stack>
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                ))}
            </Grid>
          )}
          {geralValue === 1 && (
            <>
              {valueSessoes === 0 && (
                <Grid container spacing={2}>
                  {Array.isArray(vlans) &&
                    vlans.map(
                      (vlan, index) =>
                        validarIp(vlan?.ipLocal, "justIpv4") === 1 &&
                        validarIp(vlan?.ipCliente, "justIpv4") === 1 && (
                          <Grid item lg={12} xl={6}>
                            <Paper
                              variant="outlined"
                              key={vlan}
                              value={index}
                              sx={styles.paper}
                            >
                              <Box sx={styles.cardHeader}>
                                <Box>
                                  <Typography
                                    variant="capition"
                                    sx={styles.cardSubTitle}
                                  >
                                    Título
                                  </Typography>
                                  <Typography>{vlan?.titulo}</Typography>
                                </Box>
                              </Box>
                              <Box sx={styles.cardContent}>
                                <Box sx={styles.cardRow}>
                                  <Stack
                                    direction={"column"}
                                    sx={styles.cardStack}
                                  >
                                    <Typography sx={styles.cardSubTitle}>
                                      Source
                                    </Typography>
                                    <Tooltip>
                                      <span
                                      /*  onClick={() => {
                                          handleCopy(vlan?.ipLocal);
                                        }} */
                                      >
                                        <Typography sx={styles.cardText}>
                                          {vlan?.ipLocal}
                                        </Typography>
                                      </span>
                                    </Tooltip>
                                  </Stack>
                                  <Stack
                                    direction={"column"}
                                    sx={styles.cardStack}
                                  >
                                    <Typography sx={styles.cardSubTitle}>
                                      Destination
                                    </Typography>
                                    <Tooltip>
                                      <span
                                      /*  onClick={() => {
                                          handleCopy(vlan?.ipCliente);
                                        }} */
                                      >
                                        <Typography sx={styles.cardText}>
                                          {vlan?.ipCliente}
                                        </Typography>
                                      </span>
                                    </Tooltip>
                                  </Stack>
                                </Box>
                                <Box sx={styles.cardRow}>
                                  <Stack
                                    direction={"column"}
                                    sx={styles.cardStack}
                                  >
                                    <Typography sx={styles.cardSubTitle}>
                                      ASN Vlan
                                    </Typography>
                                    <Tooltip>
                                      <span
                                      /*  onClick={() => {
                                          handleCopy(vlan?.asnVlan);
                                        }} */
                                      >
                                        <Typography sx={styles.cardText}>
                                          {vlan?.asnVlan}
                                        </Typography>
                                      </span>
                                    </Tooltip>
                                  </Stack>
                                  <Stack
                                    direction={"column"}
                                    sx={styles.cardStack}
                                  >
                                    <Typography sx={styles.cardSubTitle}>
                                      Vlan ID
                                    </Typography>
                                    <Tooltip>
                                      <span
                                      /*  onClick={() => {
                                          handleCopy(vlan?.vlanId);
                                        }} */
                                      >
                                        <Typography sx={styles.cardText}>
                                          {vlan?.vlanId}
                                        </Typography>
                                      </span>
                                    </Tooltip>
                                  </Stack>
                                </Box>
                              </Box>
                            </Paper>
                          </Grid>
                        )
                    )}
                </Grid>
              )}
              {valueSessoes === 1 && (
                <Grid container spacing={2}>
                  {Array.isArray(vlans) &&
                    vlans.map(
                      (ipv6, index) =>
                        validarIp(ipv6?.ipLocal, "justIpv6") === 1 &&
                        validarIp(ipv6?.ipCliente, "justIpv6") === 1 && (
                          <Grid item lg={12} xl={6}>
                            <Paper
                              variant="outlined"
                              key={ipv6}
                              value={index}
                              sx={styles.paper}
                            >
                              <Box sx={styles.cardHeader}>
                                <Box>
                                  <Typography
                                    variant="capition"
                                    sx={styles.cardSubTitle}
                                  >
                                    Título
                                  </Typography>
                                  <Typography>{ipv6?.titulo}</Typography>
                                </Box>
                              </Box>
                              <Box sx={styles.cardContent}>
                                <Box sx={styles.cardRow}>
                                  <Stack
                                    direction={"column"}
                                    sx={styles.cardStack}
                                  >
                                    <Typography sx={styles.cardSubTitle}>
                                      Source
                                    </Typography>
                                    <Tooltip>
                                      <span
                                      /* onClick={() => {
                                          handleCopy(ipv6?.ipLocal);
                                        }} */
                                      >
                                        <Typography sx={styles.cardText}>
                                          {ipv6?.ipLocal}
                                        </Typography>
                                      </span>
                                    </Tooltip>
                                  </Stack>
                                  <Stack
                                    direction={"column"}
                                    sx={styles.cardStack}
                                  >
                                    <Typography sx={styles.cardSubTitle}>
                                      Destination
                                    </Typography>
                                    <Tooltip>
                                      <span
                                      /* onClick={() => {
                                          handleCopy(ipv6?.ipCliente);
                                        }} */
                                      >
                                        <Typography sx={styles.cardText}>
                                          {ipv6?.ipCliente}
                                        </Typography>
                                      </span>
                                    </Tooltip>
                                  </Stack>
                                </Box>
                                <Box sx={styles.cardRow}>
                                  <Stack
                                    direction={"column"}
                                    sx={styles.cardStack}
                                  >
                                    <Typography sx={styles.cardSubTitle}>
                                      ASN Vlan
                                    </Typography>
                                    <Tooltip>
                                      <span
                                      /* onClick={() => {
                                          handleCopy(ipv6?.asnVlan);
                                        }} */
                                      >
                                        <Typography sx={styles.cardText}>
                                          {ipv6?.asnVlan}
                                        </Typography>
                                      </span>
                                    </Tooltip>
                                  </Stack>
                                  <Stack
                                    direction={"column"}
                                    sx={styles.cardStack}
                                  >
                                    <Typography sx={styles.cardSubTitle}>
                                      Vlan ID
                                    </Typography>
                                    <Tooltip>
                                      <span
                                      /* onClick={() => {
                                          handleCopy(ipv6?.vlanId);
                                        }} */
                                      >
                                        <Typography sx={styles.cardText}>
                                          {ipv6?.vlanId}
                                        </Typography>
                                      </span>
                                    </Tooltip>
                                  </Stack>
                                </Box>
                              </Box>
                            </Paper>
                          </Grid>
                        )
                    )}
                </Grid>
              )}
            </>
          )}
        </>
      )}
      <Menu
        id={`basic-menu-${selectedMenuId}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        aria-labelledby={selectedMenuId}
      ></Menu>

      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
