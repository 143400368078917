import {
  Modal,
  Box,
  Paper,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/system";
import useStyles from "./styles";
import { useEffect, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
export default function ModalAddUserCliente({
  open,
  setOpenModalAddUser = () => {},
  setNewUserClient = () => {},
  createUserCliente = () => {},
  createButton,
  newUserCliente,
  setSnackbar = () => {},
}) {
  const [showPass, setShowPass] = useState(false);
  const [emailValido, setEmailValido] = useState(false);
  const [Null, setNull] = useState(false);
  const [senhaValid, setSenhaValid] = useState(false);
  const theme = useTheme();
  const styles = useStyles(theme);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    if (emailRegex.test(newUserCliente?.email)) {
      setEmailValido(true);
    } else {
      setEmailValido(false);
    }
  }, [newUserCliente?.email]);
  useEffect(() => {
    if (
      newUserCliente?.senha?.length >= 6 &&
      /[a-zA-Z]/.test(newUserCliente?.senha) &&
      /\d/.test(newUserCliente?.senha)
    )
      setSenhaValid(true);
    else setSenhaValid(false);
  }, [newUserCliente?.senha]);

  const handleCreateUser = () => {
    if (
      !newUserCliente?.email ||
      !newUserCliente?.login ||
      !newUserCliente?.senha ||
      !newUserCliente?.email ||
      !emailValido ||
      !senhaValid
    ) {
      setNull(true);
      setSnackbar({
        children: "Necessário preencher todos os campos",
        severity: "error",
      });
    } else {
      setNull(false);
      createUserCliente();
    }
  };
  const handleCancelar = () => {
    setNewUserClient({
      login: "",
      email: "",
      senha: "",
    });
    setOpenModalAddUser(false);
    setNull(false);
  };

  return (
    <Modal
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      open={open}
      onClose={handleCancelar}
    >
      <Paper
        variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
        sx={styles.container}
      >
        <Box sx={styles.containerForm}>
          <Box sx={styles.containerTitulo}>
            <Typography variant="title">Cadastro de usuário</Typography>
          </Box>

          <TextField
            label="Login"
            id="login"
            variant="filled"
            color="primary"
            size="small"
            sx={styles.textfield}
            autoComplete="off"
            onChange={(e) =>
              setNewUserClient((prev) => ({ ...prev, login: e?.target?.value }))
            }
            helperText={
              !newUserCliente?.login && Null
                ? "Necessário informar um login"
                : ""
            }
            error={!newUserCliente?.login && Null}
          />
          <TextField
            label="Email"
            id="email"
            variant="filled"
            type="email"
            size="small"
            color="primary"
            sx={styles.textfield}
            autoComplete="off"
            onChange={(e) =>
              setNewUserClient((prev) => ({ ...prev, email: e?.target?.value }))
            }
            helperText={
              (!newUserCliente?.email || !emailValido) && Null
                ? "Necessário informar um e-mail válido"
                : ""
            }
            error={(!newUserCliente?.email || !emailValido) && Null}
          />

          <TextField
            autoComplete="off"
            name="senha"
            label="Senha"
            size="small"
            variant="filled"
            color="primary"
            sx={styles.textfield}
            type={showPass ? "text" : "password"}
            onChange={(e) =>
              setNewUserClient((prev) => ({ ...prev, senha: e?.target?.value }))
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowPass((event) => !event)}
                  >
                    {showPass ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            helperText={
              (!newUserCliente?.senha || !senhaValid) && Null
                ? "A senha precisa ter no mínimo 6 digítos, uma letra e um número."
                : ""
            }
            error={(!newUserCliente?.senha || !senhaValid) && Null}
          />
        </Box>
        <Box sx={styles.containerButtons}>
          <Button
            /* onClick={handleCloseModalAddUser} */
            sx={styles.buttonCancel}
            variant="text"
            onClick={handleCancelar}
          >
            Cancelar
          </Button>
          <Button
            disabled={createButton}
            variant="text"
            onClick={handleCreateUser}
          >
            Cadastrar
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
}
