import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box, style, useTheme } from "@mui/system";
import { useState } from "react";
import useStyles from "./styles";
import { useEffect } from "react";
import api from "../../../../../services/api";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import ASN from "../asn";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

export default function Outros(params) {
  const { setSnackbar = () => {}, setChanged = () => {}, clienteInfo } = params;
  const theme = useTheme();
  const styles = useStyles(theme);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [planos, setPlanos] = useState([]);
  const [comunidades, setComunidades] = useState([]);
  const [comunidadesSelecionadas, setComunidadesSelecionadas] = useState([]);

  const [formData, setFormData] = useState({
    rota: "",
    planosId: "",
  });
  const [initialData, setInitialData] = useState({
    rota: "",
    planosId: "",
  });
  useEffect(() => {
    handleApiGetPlanos();
    handleApiGetComunidades();
    handleApiInitialData();
  }, [clienteInfo]);

  useEffect(() => {
    handleApiInitialData();

    if (formData?.RlJuniperComunidadesCliente) {
      const comunidadesSelecionadasIds =
        formData.RlJuniperComunidadesCliente.map(
          (comunidade) => comunidade.juniperComunidadesId
        );

      const comunidadesSincronizadas = comunidades.filter((comunidade) =>
        comunidadesSelecionadasIds.includes(comunidade.id)
      );

      setComunidadesSelecionadas(comunidadesSincronizadas);
    }
  }, [comunidades]);

  async function handleApiGetPlanos() {
    try {
      const response = await api.get("/findMany/planos");

      setPlanos(response.data);
    } catch (error) {
      setSnackbar({
        children: `Error:${"Não foi possível buscar os planos."} `,
        severity: "error",
      });
    }
  }

  async function handleApiGetComunidades() {
    try {
      const response = await api.get("/findMany/comunidades");

      setComunidades(response.data);
    } catch (error) {
      setSnackbar({
        children: `Error:${"Não foi possível buscar as comunidades."} `,
        severity: "error",
      });
    }
  }

  async function handleApiInitialData() {
    try {
      //passa clienteId
      const response = await api.post("/find/outros", {
        clienteId: clienteInfo?.id || "",
      });
      setFormData({
        rota: response?.data?.rota || "Default",
        planosId: response?.data?.planosId,
      });
      setInitialData({
        rota: response?.data?.rota || "Default",
        planosId: response?.data?.planosId,
      });
      return response.data;
    } catch (error) {
      setSnackbar({
        children: `Error:${"Não foi possível buscar as configurações"} `,
        severity: "error",
      });
    }
  }

  function handleInputChange(e) {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) setErrors((prev) => ({ ...prev, [name]: "" }));
    setChanged(true);
  }

  async function handleSubmit() {
    try {
      setLoading(true);

      if (!formData?.planosId) {
        throw new Error("Plano obrigatório.");
      }

      const response = await api.post("/upsert/outros", {
        rota: formData.rota,
        planosId: formData.planosId,
        comunidades: comunidadesSelecionadas,
        clienteId: clienteInfo?.id || "",
      });

      setChanged(false);
      setEditMode(false);
      setInitialData(formData);
      setErrors({});
      setSnackbar({
        children: "Configurações salvas com sucesso!",
        severity: "success",
      });
      setInitialData(formData);
    } catch (error) {
      console.log(error.message);
      if (error?.message === "Plano obrigatório.") {
        setErrors({ planosId: error?.message });
      } else {
        const newErrors = {};
        error?.errors?.forEach((err) => {
          newErrors[err.path[0]] = err.message;
        });
        setErrors(newErrors);
        setSnackbar({
          children: "Error: Não foi possível se conectar ao servidor.",
          severity: "error",
        });
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Paper
        sx={styles.containerPaper}
        elevation={3}
        variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
      >
        <Typography variant="mySubtitle">Configurações Adicionais</Typography>
        <Paper sx={styles.containerAdicionais} variant={"outlined"}>
          <Box sx={styles.container}>
            <Box sx={styles.content}>
              <Box sx={styles.boxSeletor}>
                <FormControl variant="filled">
                  <InputLabel id="label-planosId">Rota</InputLabel>
                  <Select
                    onChange={handleInputChange}
                    size={"small"}
                    defaultValue={formData?.rota}
                    labelId="rota-label"
                    value={formData?.rota}
                    name="rota"
                    label="rota"
                    variant="filled"
                    sx={styles.select}
                    error={Boolean(errors?.planosId)}
                    helperText={errors?.planosId}
                    disabled={!editMode}
                  >
                    <MenuItem value={"Default"}>Default</MenuItem>
                    <MenuItem value={"Fullrouting"}>Fullrouting</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  variant="filled"
                  error={Boolean(errors?.planosId)}
                  sx={{ position: "relative" }}
                >
                  <InputLabel id="label-planosId">Plano</InputLabel>
                  <Select
                    onChange={handleInputChange}
                    size={"small"}
                    defaultValue={formData?.planosId}
                    value={formData?.planosId}
                    labelId="label-planosId"
                    name="planosId"
                    label="Plano"
                    variant="filled"
                    sx={styles.select}
                    disabled={!editMode}
                  >
                    {/* <MenuItem value="">
                <em>None</em>
                </MenuItem> */}
                    {planos.map((plano, index) => (
                      <MenuItem key={index} value={plano.id}>
                        {plano.nome}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors?.planosId && (
                    <FormHelperText sx={{ position: "absolute", top: "100%" }}>
                      {errors?.planosId}
                    </FormHelperText>
                  )}
                </FormControl>
                <Autocomplete
                  multiple
                  size="small"
                  value={comunidadesSelecionadas}
                  onChange={(event, newValue) => {
                    setChanged(true);
                    setComunidadesSelecionadas(newValue);
                  }}
                  options={comunidades}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.nome}
                  renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props;
                    return (
                      <li key={key} {...optionProps}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.nome}
                      </li>
                    );
                  }}
                  style={{ width: 500 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Comunidades"
                      variant="filled"
                    />
                  )}
                  disabled={!editMode}
                />
              </Box>
            </Box>
            <Box>
              {editMode ? (
                <Stack direction={"row"} sx={styles.stack}>
                  <Button
                    disabled={loading}
                    onClick={() => {
                      setFormData(initialData);
                      setChanged(false);
                      setEditMode(false);
                      const comunidadesSelecionadasIds =
                        initialData?.RlJuniperComunidadesCliente?.map(
                          (comunidade) => comunidade.juniperComunidadesId
                        ) || [];

                      const comunidadesSincronizadas = comunidades.filter(
                        (comunidade) =>
                          comunidadesSelecionadasIds.includes(comunidade.id)
                      );

                      setComunidadesSelecionadas(comunidadesSincronizadas);
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    Salvar
                  </Button>
                </Stack>
              ) : (
                <Stack direction={"row"} sx={styles.stack}>
                  <Button
                    disabled={loading}
                    onClick={() => {
                      setEditMode(true);
                    }}
                  >
                    Editar
                  </Button>
                </Stack>
              )}
            </Box>
          </Box>
        </Paper>
      </Paper>
      <Paper
        sx={styles.containerPaper}
        elevation={3}
        variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
      >
        <Typography variant="mySubtitle">Configurações de ASN's</Typography>
        <Paper sx={styles.containerAdicionais} variant={"outlined"}>
          <ASN clienteInfo={clienteInfo} />
        </Paper>
      </Paper>
    </>
  );
}
