const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 3,
    width: "100%",
  },
  tabLabel: { fontSize: "14px" },
  containerPaper: {
    backgroundColor: theme.palette.mode === "dark" && "transparent",
    padding: "20px",
  },
});

export default styles;
