import { margin } from "@mui/system";

const styles = (theme) => ({
  container: { display: "flex", flexDirection: "column", gap: 6 },
  paper: {
    display: "flex",
    flexDirection: "column",
    bgcolor: "transparent",
    gap: 1,
    borderRadius: "10px",
    padding: "20px",
  },
  containerPaper: {
    backgroundColor: theme.palette.mode === "dark" && "transparent",
    padding: "20px",
  },
  containerAdicionais: {
    backgroundColor: theme.palette.mode === "dark" && "transparent",
    padding: "20px",
    margin: "20px 0 0 0 ",
  },
  containerPaperAsn: {
    margin: "20px 0 0 0",
    backgroundColor: theme.palette.mode === "dark" && "transparent",
    padding: "20px",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    gap: 6,
  },
  boxSeletor: {
    display: "flex",
    flexDirection: "row",
    gap: 1,
    alignItems: "center",
  },
  select: { width: "200px" },
  stack: {
    gap: 1,
    justifyContent: "flex-end",
    width: "100%",
    flexWrap: "wrap",
    mt: "10px",
  },
  textfield: {
    width: "49%",
    minWidth: "250px",
    "@media (max-width: 1040px)": {
      width: "100%",
    },
  },
});

export default styles;
