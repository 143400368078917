import {
  Box,
  Button,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import validarIp from "../../../../../../utils/validadorIp";
import useStyles from "./styles";
import api from "../../../../../../services/api";

export default function ModalEditar(params) {
  const {
    openEditar = false,
    setOpenEditar = () => {},
    setSnackbar = () => {},
    selectedCard = {},
    handleUpdated = () => {},
    clienteInfo = {},
  } = params;
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    id: selectedCard?.id || "",
    nome: selectedCard?.nome || "",
    ipTunnelLocal: selectedCard?.ipTunnelLocal || "",
    ipTunnelCliente: selectedCard?.ipTunnelCliente || "",
    ipDestiny: selectedCard?.ipDestiny || "",
    ipSource: selectedCard?.ipSource || "",
    idTunnelCliente: selectedCard?.idTunnelCliente || "",
  });
  useEffect(() => {
    if (openEditar) {
      setFormData({
        id: selectedCard?.id || "",
        nome: selectedCard?.nome || "",
        ipTunnelLocal: selectedCard?.ipTunnelLocal || "",
        ipTunnelCliente: selectedCard?.ipTunnelCliente || "",
        ipDestiny: selectedCard?.ipDestiny || "",
        ipSource: selectedCard?.ipSource || "",
        idTunnelCliente: selectedCard?.idTunnelCliente || "",
      });
    }
  }, [openEditar]);

  function handleInputChange(e) {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) setErrors((prev) => ({ ...prev, [name]: "" }));
  }

  function handleBlur(value, name) {
    if (name === "nome") {
      if (value?.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          [name]: "Título obrigatório",
        }));
      }
    } else if (name === "ipTunnelLocal") {
      if (value?.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          [name]: "IP obrigatório",
        }));
      } else {
        const isValid = validarIp(value, "justIpv4");

        if (isValid === 1) {
          setErrors((prev) => ({ ...prev, [name]: "" }));
        } else {
          setErrors((prev) => ({
            ...prev,
            [name]: "IP inválido",
          }));
        }
      }
    } else if (name === "ipTunnelCliente") {
      if (value?.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          [name]: "IP obrigatório",
        }));
      } else {
        const isValid = validarIp(value, "justIpv4");

        if (isValid === 1) {
          setErrors((prev) => ({ ...prev, [name]: "" }));
        } else {
          setErrors((prev) => ({
            ...prev,
            [name]: "IP inválido",
          }));
        }
      }
    } else if (name === "ipSource") {
      if (value?.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          [name]: "Fonte IP obrigatória",
        }));
      } else {
        const isValid = validarIp(value, "justIpv4");

        if (isValid === 1) {
          setErrors((prev) => ({ ...prev, [name]: "" }));
        } else {
          setErrors((prev) => ({
            ...prev,
            [name]: "IP inválido",
          }));
        }
      }
    } else if (name === "ipDestiny") {
      if (value?.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          [name]: "IP obrigatório",
        }));
      } else {
        const isValid = validarIp(value, "justIpv4");

        if (isValid === 1) {
          setErrors((prev) => ({ ...prev, [name]: "" }));
        } else {
          setErrors((prev) => ({
            ...prev,
            [name]: "IP inválido",
          }));
        }
      }
    } else if (name === "idTunnelCliente") {
      if (value?.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          [name]: "ID obrigatório",
        }));
      }
    }
  }

  async function handleSubmit() {
    try {
      setLoading(true);
      let formError = false;
      let isValid;

      if (formData?.nome?.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          nome: "Título obrigatório",
        }));
        formError = true;
      }

      if (formData?.ipSource?.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          ipSource: "IP obrigatório",
        }));
        formError = true;
      } else {
        isValid = validarIp(formData?.ipSource, "justIpv4");
        if (isValid === 1) {
          setErrors((prev) => ({ ...prev, ipSource: "" }));
        } else {
          setErrors((prev) => ({
            ...prev,
            ipSource: "IP inválido",
          }));
          formError = true;
        }
      }

      if (formData?.ipDestiny?.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          ipDestiny: "IP obrigatório",
        }));
        formError = true;
      } else {
        isValid = validarIp(formData?.ipDestiny, "justIpv4");
        if (isValid === 1) {
          setErrors((prev) => ({ ...prev, ipDestiny: "" }));
        } else {
          setErrors((prev) => ({
            ...prev,
            ipDestiny: "IP inválido",
          }));
          formError = true;
        }
      }

      if (formData?.ipTunnelLocal.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          ipTunnelLocal: "IP obrigatório",
        }));
        formError = true;
      } else {
        isValid = validarIp(formData?.ipTunnelLocal, "justIpv4");
        if (isValid === 1) {
          setErrors((prev) => ({ ...prev, ipTunnelLocal: "" }));
        } else {
          setErrors((prev) => ({
            ...prev,
            ipTunnelLocal: "IP inválido",
          }));
          formError = true;
        }
      }

      if (formData?.ipTunnelCliente.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          ipTunnelCliente: "IP obrigatório",
        }));
        formError = true;
      } else {
        isValid = validarIp(formData?.ipTunnelCliente, "justIpv4");
        if (isValid === 1) {
          setErrors((prev) => ({ ...prev, ipTunnelCliente: "" }));
        } else {
          setErrors((prev) => ({
            ...prev,
            ipTunnelCliente: "IP inválido",
          }));
          formError = true;
        }
      }

      if (formData?.idTunnelCliente.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          idTunnelCliente: "ID obrigatório",
        }));
        formError = true;
      }

      if (formError) {
        return;
      }

      const response = await api.put("/interno/update/tunnel", {
        id: formData?.id,
        nome: formData?.nome,
        ipSource: formData?.ipSource,
        ipDestiny: formData?.ipDestiny,
        ipTunnelLocal: formData?.ipTunnelLocal,
        ipTunnelCliente: formData?.ipTunnelCliente,
        idTunnelCliente: formData?.idTunnelCliente,
      });

      // on success
      setErrors({});
      handleClose();
      handleUpdated(formData);
      setSnackbar({
        children: "Túnel editado com sucesso!",
        severity: "success",
      });
      handleClose();
    } catch (error) {
      console.log(error);
      const newErrors = {};
      error?.errors?.forEach((err) => {
        newErrors[err.path[0]] = err.message;
      });
      setErrors(newErrors);
      setSnackbar({
        children: "Error: Não foi possivel se conectar ao servidor",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  const handleClose = () => {
    setOpenEditar(false);
    setFormData({
      nome: "",
      ipTunnelLocal: "",
      ipTunnelCliente: "",
      idTunnelCliente: "",
    });
  };

  return (
    <Modal open={openEditar} onClose={handleClose} sx={styles.container}>
      <Paper sx={styles.paper}>
        <Box sx={styles.boxPaper}>
          <Typography variant="mySubtitle">Editar Túnel</Typography>

          <Box sx={styles.boxContent}>
            <Stack direction={"row"} gap={1} flexWrap={"nowrap"}>
              <TextField
                autoComplete="off"
                sx={styles.textField}
                variant="filled"
                id="nome"
                name="nome"
                label="Título do Túnel"
                value={formData?.nome}
                onChange={handleInputChange}
                onBlur={(e) => handleBlur(e.target.value, e.target.name)}
                error={Boolean(errors?.nome)}
                helperText={errors?.nome}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    document.getElementById("idTunnelCliente").focus();
                  }
                }}
              />
              <TextField
                autoComplete="off"
                sx={styles.textField}
                variant="filled"
                id="idTunnelCliente"
                name="idTunnelCliente"
                label="Tunnel ID"
                value={formData?.idTunnelCliente}
                onChange={handleInputChange}
                onBlur={(e) => handleBlur(e.target.value, e.target.name)}
                error={Boolean(errors?.idTunnelCliente)}
                helperText={errors?.idTunnelCliente}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    document.getElementById("ipTunnelLocal").focus();
                  }
                }}
              />
            </Stack>

            <Stack direction={"row"} gap={1} flexWrap={"nowrap"}>
              <TextField
                autoComplete="off"
                sx={styles.textField}
                variant="filled"
                id="ipTunnelLocal"
                name="ipTunnelLocal"
                label="Tunnel Local"
                value={formData?.ipTunnelLocal}
                onChange={handleInputChange}
                onBlur={(e) => handleBlur(e.target.value, e.target.name)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    document.getElementById("ipTunnelCliente").focus();
                  }
                }}
                error={Boolean(errors?.ipTunnelLocal)}
                helperText={errors?.ipTunnelLocal}
              />
              <TextField
                autoComplete="off"
                sx={styles.textField}
                variant="filled"
                id="ipTunnelCliente"
                name="ipTunnelCliente"
                label="Tunnel Cliente"
                value={formData?.ipTunnelCliente}
                onChange={handleInputChange}
                onBlur={(e) => handleBlur(e.target.value, e.target.name)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    document.getElementById("ipSource").focus();
                  }
                }}
                error={Boolean(errors?.ipTunnelCliente)}
                helperText={errors?.ipTunnelCliente}
              />
            </Stack>
            <Stack direction={"row"} gap={1} flexWrap={"nowrap"}>
              <TextField
                autoComplete="off"
                sx={styles.textField}
                variant="filled"
                id="ipSource"
                name="ipSource"
                label="Source"
                value={formData?.ipSource}
                onChange={handleInputChange}
                onBlur={(e) => handleBlur(e.target.value, e.target.name)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    document.getElementById("ipDestiny").focus();
                  }
                }}
                error={Boolean(errors?.ipSource)}
                helperText={errors?.ipSource}
              />
              <TextField
                autoComplete="off"
                sx={styles.textField}
                variant="filled"
                id="ipDestiny"
                name="ipDestiny"
                label="Destination"
                value={formData?.ipDestiny}
                onChange={handleInputChange}
                onBlur={(e) => handleBlur(e.target.value, e.target.name)}
                error={Boolean(errors?.ipDestiny)}
                helperText={errors?.ipDestiny}
              />
            </Stack>
          </Box>
          <Stack
            direction={"row"}
            gap={1}
            justifyContent={"flex-end"}
            width={"100%"}
            flexWrap={"nowrap"}
          >
            <Button onClick={handleClose} disabled={loading}>
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={loading}
            >
              Salvar
            </Button>
          </Stack>
        </Box>
      </Paper>
    </Modal>
  );
}
