import React, { useEffect, useState } from "react";
import { Box, Button, Modal, Paper, Stack, Tab, Tabs } from "@mui/material";
import useStyles from "./styles";
import EditCadastroCliente from "../tabEditCadastro";
import EditAtivacaoCliente from "../tabEditAtivacao";

export default function ModalEditCadastro(params) {
  const {
    openEditModal,
    setOpenEditModal,
    selectedRow,
    setSelectedRow,
    handleUpdatedClient,
    setSnackbar,
  } = params;
  const styles = useStyles();
  const [value, setValue] = useState(0);

  //Change tabs handler
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Modal
      open={openEditModal}
      onClose={() => {
        setOpenEditModal(false);
        setValue(0);
      }}
      sx={styles.modal}
    >
      <Paper sx={styles.paper}>
        <Box sx={styles.boxPaper}>
          <Box>
            {/* <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basics tabs config"
            >
              <Tab
                label="Cadastro"
                {...a11yProps(0)}
                sx={{ fontSize: "14px" }}
              />
              <Tab
                label="Formulário de Ativação"
                {...a11yProps(1)}
                sx={{ fontSize: "14px" }}
              />
            </Tabs> */}
          </Box>
          <Box>
            <CustomTabPanel value={value} index={0}>
              <EditCadastroCliente
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                handleUpdatedClient={handleUpdatedClient}
                setSnackbar={setSnackbar}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <EditAtivacaoCliente selectedRow={selectedRow} />
            </CustomTabPanel>
          </Box>
          {/* <Box>
            <Stack
              direction={"row"}
              gap={1}
              justifyContent={"flex-end"}
              width={"100%"}
              flexWrap={"wrap"}
            >
              {editMode ? (
                <>
                  <Button sx={styles.button} onClick={changeEditMode}>
                    Cancelar
                  </Button>
                  <Button variant="contained" sx={styles.button}>
                    Salvar
                  </Button>
                </>
              ) : (
                <Button onClick={changeEditMode} color="primary">
                  Editar
                </Button>
              )}
            </Stack>
          </Box> */}
        </Box>
      </Paper>
    </Modal>
  );
}
