import { Box, Paper, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import SolicitacoesIpv4 from "./ipv4";
import SolicitacoesIpv6 from "./ipv6";
import useStyle from "./styles";
export default function Solicitacoes(params) {
  //Change tabs handler
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const styles = useStyle(theme);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <Paper
      sx={styles.containerPaper}
      elevation={3}
      variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
    >
      <Box>
        <Typography variant="mySubtitle">Solicitações de Prefixos</Typography>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basics tabs config"
          >
            <Tab label="IPV4" {...a11yProps(0)} sx={{ fontSize: "14px" }} />
            <Tab label="IPV6" {...a11yProps(1)} sx={{ fontSize: "14px" }} />
          </Tabs>
          {value === 0 && <SolicitacoesIpv4 />}
          {value === 1 && <SolicitacoesIpv6 />}
        </Box>
      </Box>
    </Paper>
  );
}
