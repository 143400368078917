import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Drawer,
  Fab,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import useStyles from "./styles";
import api from "../../../services/apiCliente";
import { RangeContext } from "../range";
import {
  ArrowForwardIosOutlined,
  FilterAltOutlined,
  InfoOutlined,
} from "@mui/icons-material";
import Dashboard from "../../../components/dashboard";
import { Box } from "@mui/system";

export default function HomeClient() {
  const [snackbar, setSnackbar] = useState(null);
  const { Range, setRange } = useContext(RangeContext);
  const [loading, setLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [rangeManipulate, setRangeManipulate] = useState(null);
  const [openDrawerMobile, setOpenDrawerMobile] = useState(false);
  const [prefix, setPrefix] = useState([]);
  const [recentEvents, setRecentEvents] = useState([]);
  const [eventsList, setEventsList] = useState([]);
  const [controller, setController] = useState(null);
  const [loadingEventsList, setLoadingEventsList] = useState(false);
  const [loadingOpTrafficPps, setLoadingOpTrafficPps] = useState(false);
  const [loadingPrefix, setLoadingPrefix] = useState(false);
  const [loadingRecentEvents, setLoadingRecentEvents] = useState(false);
  const [loadingNetworkSummary, setLoadingNetworkSummary] = useState(false);
  const [loadingOpTraffic, setLoadingOpTraffic] = useState(false);
  const [fistRender, setFistRender] = useState(false);
  //Estilização
  const theme = useTheme();
  const styles = useStyles();
  // Dados iniciais
  const clienteId =
    JSON.parse(localStorage.getItem("userCliente"))?.usuarioCliente
      ?.clienteId || "";

  const [data, setData] = useState({
    mitigation_bps: [],
    mitigation_pps: [],
  });
  const [networkSummary, setNetworkSummary] = useState([
    { networkSummary: [], networkSummaryPps: [] },
  ]);

  useEffect(() => {
    setFistRender(false);
    setRange({
      range: "hour",
      unidade: "bps",
      cliente: null,
      timer: 0,
      timerStatus: false,
      escala: "padrao",
    });
    setRangeManipulate(JSON.parse(JSON.stringify(Range)));

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    // Executa ao carregar a página
    setFistRender(true);
  }, []);

  // Requisição periódica
  useEffect(() => {
    if (fistRender) {
      fetchData("sem timer"); // Requisição inicial sem sinal
      setFistRender(false);
    }

    const timer = setInterval(() => {
      if (Math.round(Range.timer) >= 60 && !loading) {
        fetchData("com timer"); // Requisição periódica
      }
      if (!loading) {
        setRange((prevRange) => ({
          ...prevRange,
          timer: prevRange.timer + 1,
        }));
      }
    }, 1000); // Chama a cada 1 segundo

    return () => {
      clearInterval(timer); // Limpar o intervalo ao desmontar
    };
  }, [fistRender, loading, Range.timer]);

  // Requisição ao efetuar mudanças
  useEffect(() => {
    //setRange(JSON.parse(JSON.stringify(rangeManipulate)));
    setFistRender(true);
  }, [Range?.range]);

  // Solicita todos os dados da api
  const fetchData = async (verbose) => {
    if (controller) {
      controller.abort(); // Abortar a requisição anterior
    }
    const newController = new AbortController(); // Criar novo AbortController
    const signal = newController.signal; // Obter o sinal

    setController(newController); // Guardar o novo controller no estado
    try {
      // estes
      if (clienteId) {
        setLoading(true);
        setRange({ ...Range, timerStatus: true, timer: -100 });
        await Promise.all([
          apiHandleNetworkSummary(signal),
          apiHandleOpTraffic(signal),

          // apiHandleEventsList(),
          apiHandlePrefix(signal),
        ]);
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Erro durante a solicitação de rotas"
        } `,
        severity: "error",
      });
    } finally {
      //   { ...Range, timerStatus: true, timer: 0 }
      setRange((prevRange) => ({
        ...prevRange,
        timerStatus: true,
        timer: 0,
      }));
      setLoading(false);
      setFistRender(false);
    }
  };
  async function apiHandleOpTraffic(signal) {
    try {
      setLoadingOpTraffic(true);
      setLoadingRecentEvents(true);
      const response = await api.post(
        "/cliente/zabbix/findHistory",
        {
          clienteId: clienteId,
          range: Range.range || "hour",
        },
        { signal }
      );
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível carregar os valores",
          severity: "error",
        });
      } else {
        if (response.data) {
          setData((prevData) => ({
            ...prevData,
            mitigation_bps: response?.data?.traffic || [],
            mitigation_pps: response?.data?.trafficPps || [],
          }));
          setRecentEvents(response?.data?.wanguardTable || []);
        }
      }
    } catch (error) {
      if (error.name === "CanceledError") {
        console.log("Requisição de tráfego bps cancelada");
      } else {
        setData((prevData) => ({
          ...prevData,
          mitigation_bps: [],
        }));
        setSnackbar({
          children: `Error:${
            error?.response?.data?.error ||
            "Não foi possível se buscar valores de tráfego"
          } `,
          severity: "error",
        });
      }
    } finally {
      setLoadingOpTraffic(false);
      setLoadingRecentEvents(false);
    }
  }

  async function apiHandleNetworkSummary(signal) {
    try {
      setLoadingNetworkSummary(true);
      const response = await api.post(
        "/cliente/nexus/networkSummary",
        {
          clienteId: clienteId || "",
          range: Range.range || "hour",
        },
        { signal }
      );
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível carregar os valores",
          severity: "error",
        });
      } else {
        if (response.data) {
          setNetworkSummary(() => ({
            ...networkSummary,
            networkSummary: response.data.networkSummary || [],
            networkSummaryPps: response.data.networkSummaryPps || [],
          }));
        }
      }
    } catch (error) {
      if (error.name === "CanceledError") {
        console.log("Requisição resumo de rede cancelada");
      } else {
        setSnackbar({
          children: `Error:${
            error?.message || "Não foi possível buscar informações da rede"
          } `,
          severity: "error",
        });
        setNetworkSummary(() => ({
          ...networkSummary,
          networkSummary: [],
          networkSummaryPps: [],
        }));
      }
    } finally {
      setLoadingNetworkSummary(false);
    }
  }

  async function apiHandlePrefix(signal) {
    try {
      setLoadingPrefix(true);
      const response = await api.post(
        "/cliente/nexus/prefix",
        {
          clienteId: clienteId || "",
          //range: Range.range || "hour",
        },
        { signal }
      );
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível carregar os valores",
          severity: "error",
        });
      } else {
        if (response.data) {
          setPrefix(() => response.data || []);
        }
      }
    } catch (error) {
      if (error.name === "CanceledError") {
        console.log("Requisição de tráfego bps cancelada");
      } else {
        setSnackbar({
          children: `Error:${
            error?.message || "Não foi possível buscar prefixos"
          } `,
          severity: "error",
        });
        setPrefix([]);
      }
    } finally {
      setLoadingPrefix(false);
    }
  }

  // Cronometro
  function CircularProgressWithLabel({ value = 0 }) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          variant={value <= 0 || value >= 60 ? "indeterminate" : "determinate"}
          value={value * (5 / 3)}
          sx={{ color: "primary" }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" component="div" color="primary">
            {value <= 0 || value >= 60 ? 0 : `${Math.round(value)}`}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={styles.container}>
      {windowWidth > 744 && (
        <Box sx={styles.filterBar}>
          <Box sx={styles.filterGroup}>
            <Box sx={styles.filterGroupItem}>
              <Typography sx={styles.filterTypography}>
                Filtro de tempo
              </Typography>
              <FormControl sx={styles.formControl} size="small">
                {/* <InputLabel id="demo-simple-select-label">
              Filtro de tempo
            </InputLabel> */}
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  value={Range?.range || ""}
                  onChange={(event) => {
                    setRange({
                      ...Range,
                      range: event.target.value,
                    });
                  }}
                  // label="Filtro de tempo"
                >
                  <MenuItem value={"hour"}>1 Hora</MenuItem>
                  <MenuItem value={"2hours"}>2 Horas</MenuItem>
                  <MenuItem value={"4hours"}>4 Horas</MenuItem>
                  <MenuItem value={"6hours"}>6 Horas</MenuItem>
                  <MenuItem value={"12hours"}>12 Horas</MenuItem>
                  <MenuItem value={"day"}>Dia</MenuItem>
                  <MenuItem value={"week"}>Semana</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{ display: "flex", flexGrow: 1, justifyContent: "flex-end" }}
            >
              {window.location.pathname === "/home" && (
                <>
                  {windowWidth <= 1010 ? (
                    <>
                      {Range?.timerStatus && (
                        <CircularProgressWithLabel
                          value={parseInt(Range?.timer) || 0}
                        />
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Box>
          </Box>
          <Box sx={styles.filterGroup}>
            {window.location.pathname === "/home" && (
              <>
                {windowWidth > 1010 ? (
                  <>
                    {Range?.timerStatus && (
                      <CircularProgressWithLabel
                        value={parseInt(Range?.timer) || 0}
                      />
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
            <Box sx={styles.filterGroupItem}>
              <Typography sx={styles.filterTypography}>Unidade</Typography>
              <FormControl sx={styles.formControl} size="small">
                {/* <InputLabel id="demo-simple-select-label">Unidade</InputLabel> */}
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  value={rangeManipulate?.unidade || ""}
                  onChange={(event) => {
                    setRangeManipulate({
                      ...rangeManipulate,
                      unidade: event.target.value,
                    });
                    setRange({
                      ...Range,
                      unidade: event.target.value,
                    });
                  }}
                  //label="Unidade"
                >
                  <MenuItem value={"bps"}>bps</MenuItem>
                  <MenuItem value={"pps"}>pps</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <FormControl
              sx={{
                height: "40px",
                transform: "translateY(-8px)",
              }}
              onChange={(event) => {
                setRangeManipulate({
                  ...rangeManipulate,
                  escala: event.target.value,
                });
                setRange({
                  ...Range,
                  escala: event.target.value,
                });
              }}
            >
              <FormLabel sx={styles.labelCaption}>Escala</FormLabel>
              <RadioGroup
                defaultValue={Range?.escala}
                name="radio-buttons-group"
                sx={{
                  transform: "translateY(-8px)",
                }}
                row
              >
                <FormControlLabel
                  value="padrao"
                  control={<Radio />}
                  label={
                    <Box sx={styles.filterMenuRadioRow}>
                      <Typography sx={styles.filterMenuRadioTypography}>
                        Maior valor
                      </Typography>
                      <Tooltip
                        title={
                          <Box sx={styles.filterMenuTooltip}>
                            Define a escala para tráfego limpo e tráfego sujo de
                            acordo com o maior valor entre os dois.
                          </Box>
                        }
                        arrow
                      >
                        <InfoOutlined sx={styles.iconInfoOutlined} />
                      </Tooltip>
                    </Box>
                  }
                />

                <FormControlLabel
                  sx={{ marginRight: 0 }}
                  value="individual"
                  control={<Radio />}
                  label={
                    <Box sx={styles.filterMenuRadioRow}>
                      <Typography sx={styles.filterMenuRadioTypography}>
                        Individual
                      </Typography>
                      <Tooltip
                        title={
                          <Box sx={styles.filterMenuTooltip}>
                            Define a escala de exibição do gráfico
                            individualmente, com base no maior valor presente em
                            cada gráfico.
                          </Box>
                        }
                        //placement="top-start"
                        arrow
                      >
                        <InfoOutlined sx={styles.iconInfoOutlined} />
                      </Tooltip>
                    </Box>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      )}
      {/* Botao Filtro Mobile */}
      {windowWidth < 744 && (
        <Tooltip title={"Filtro"} placement="top-start">
          <Fab
            onClick={() => {
              setOpenDrawerMobile(true);
              setRangeManipulate(JSON.parse(JSON.stringify(Range)));
            }}
            size="small"
            fontSize="large"
            color="primary"
            sx={{
              position: "fixed",
              bottom: "30px",
              right: "30px",
            }}
          >
            <FilterAltOutlined />
          </Fab>
        </Tooltip>
      )}
      {/* Filtros Mobile */}
      <Drawer
        open={openDrawerMobile}
        onClose={() => {
          setOpenDrawerMobile(false);
          setRange(JSON.parse(JSON.stringify(rangeManipulate)));
        }}
        anchor={"right"}
      >
        <Paper sx={styles.mobileDrawerPaper}>
          <Box sx={styles.mobileDrawerBox}>
            <Box sx={styles.mobileDrawerHeader}>
              <IconButton
                sx={{ marginRight: "auto" }}
                onClick={() => {
                  setOpenDrawerMobile(false);
                  setRange(JSON.parse(JSON.stringify(rangeManipulate)));
                }}
              >
                <ArrowForwardIosOutlined fontSize="small" />
              </IconButton>
              <Typography
                sx={{
                  position: "absolute",
                  marginRight: "auto",
                }}
                variant="mySubtitle2"
              >
                Filtros
              </Typography>
            </Box>

            <FormControl sx={styles.mobileDrawerFormControl} size="small">
              <InputLabel
                id="demo-simple-select-label"
                sx={{ marginTop: "11px" }}
              >
                Filtro de tempo
              </InputLabel>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={rangeManipulate?.range || ""}
                onChange={(event) => {
                  setRangeManipulate({
                    ...rangeManipulate,
                    range: event.target.value,
                  });
                }}
                label="Filtro de tempo"
                sx={{ color: "primary" }}
                variant="filled"
              >
                <MenuItem value={"hour"}>1 Hora</MenuItem>
                <MenuItem value={"2hours"}>2 Horas</MenuItem>
                <MenuItem value={"4hours"}>4 Horas</MenuItem>
                <MenuItem value={"6hours"}>6 Horas</MenuItem>
                <MenuItem value={"12hours"}>12 Horas</MenuItem>
                <MenuItem value={"day"}>Dia</MenuItem>
                <MenuItem value={"week"}>Semana</MenuItem>
                {/* <MenuItem value={"month"}>Mês</MenuItem> */}
              </Select>
            </FormControl>

            <FormControl sx={styles.mobileDrawerFormControl} size="small">
              <InputLabel
                id="demo-simple-select-label"
                sx={{ marginTop: "11px" }}
              >
                Unidade
              </InputLabel>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={rangeManipulate?.unidade || ""}
                onChange={(event) => {
                  setRangeManipulate({
                    ...rangeManipulate,
                    unidade: event.target.value,
                  });
                }}
                label="Unidade"
                sx={{ color: "primary" }}
                variant="filled"
              >
                <MenuItem value={"bps"}>bps</MenuItem>
                <MenuItem value={"pps"}>pps</MenuItem>
              </Select>
            </FormControl>

            <FormControl
              sx={styles.filterMenuRadioGroup}
              onChange={(event) => {
                setRangeManipulate({
                  ...rangeManipulate,
                  escala: event.target.value,
                });
              }}
            >
              <FormLabel>Escala</FormLabel>
              <RadioGroup
                defaultValue={rangeManipulate?.escala || "padrao"}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="padrao"
                  control={<Radio />}
                  label={
                    <Box sx={styles.filterMenuRadioRow}>
                      <Typography sx={styles.filterMenuRadioTypography}>
                        Maior valor
                      </Typography>
                      <Tooltip
                        title={
                          <Box sx={styles.filterMenuTooltip}>
                            Define a escala para tráfego limpo e tráfego sujo de
                            acordo com o maior valor entre os dois.
                          </Box>
                        }
                        arrow
                      >
                        <InfoOutlined sx={styles.iconInfoOutlined} />
                      </Tooltip>
                    </Box>
                  }
                />

                <FormControlLabel
                  value="individual"
                  control={<Radio />}
                  label={
                    <Box sx={styles.filterMenuRadioRow}>
                      <Typography sx={styles.filterMenuRadioTypography}>
                        Individual
                      </Typography>
                      <Tooltip
                        title={
                          <Box sx={styles.filterMenuTooltip}>
                            Define a escala de exibição do gráfico
                            individualmente, com base no maior valor presente em
                            cada gráfico.
                          </Box>
                        }
                        //placement="top-start"
                        arrow
                      >
                        <InfoOutlined sx={styles.iconInfoOutlined} />
                      </Tooltip>
                    </Box>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Paper>
      </Drawer>
      <Dashboard
        loadingOpTraffic={loadingOpTraffic}
        loadingOpTrafficPps={loadingOpTrafficPps}
        data={data}
        prefix={prefix}
        loading={loading}
        loadingPrefix={loadingPrefix}
        loadingNetworkSummary={loadingNetworkSummary}
        networkSummary={networkSummary}
        recentEvents={recentEvents}
        loadingRecentEvents={loadingRecentEvents}
        snackbar={snackbar}
        setSnackbar={setSnackbar}
      />
    </Box>
  );
}
