import { ExpandMore, Settings } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import useStyles from "./styles";
import { useEffect, useState } from "react";
import api from "../../../../../../services/api";
import { useOutletContext } from "react-router-dom";
import { socketInterno as socket } from "../../../../../../services/socket";
export default function SolicitacoesIpv4(params) {
  const [setSnackbar, clienteInfo] = useOutletContext();
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedPrefix, setSelectedPrefix] = useState(null);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [prefixoSocket, setPrefixoSocket] = useState([]);
  const open = Boolean(anchorEl);

  // fetch pendentes e concluidos
  const [dataPendentes, setDataPendentes] = useState([]);
  const [dataArquivados, setDataArquivados] = useState([]);
  const [dataConcluidos, setDataConcluidos] = useState([]);
  useEffect(() => {
    handleApiGetSolicitacoes();
    handleApiGetConcluidos();
  }, [clienteInfo]);

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
      socket.emit(
        "first_connection",
        `Servidor juntou-se ao grupo id conection: ${socket.id}`
      );
    }
    function onDisconnect() {
      setIsConnected(false);
    }

    function connectErrorEvent(error) {
      console.log("Socket error - erro de conexão:", error);
    }
    function reconnectAttemptEvent(attempt) {
      console.log(
        `Socket warn - o servido esta tentando ser reconectar. N de tentativas: ${attempt}`
      );
    }
    function reconnectErrorEvent(error) {
      console.log("Socket error - erro de reeconexão:", error);
    }
    function reconnectEvent(attempt) {
      console.log("Socket success: servidor reeconectado com sucesso");
    }

    socket.connect();
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("connect_error", connectErrorEvent);
    socket.on("update_prefix", (data) => {
      setPrefixoSocket(data);
    });
    socket.io.on("reconnect_attempt", reconnectAttemptEvent);
    socket.io.on("reconnect_error", reconnectErrorEvent);
    socket.io.on("reconnect", reconnectEvent);
    return () => {
      socket.off("connect", onConnect);

      socket.off("disconnect", onDisconnect);
      socket.off("connect_error", connectErrorEvent);
      socket.io.off("reconnect_attempt", reconnectAttemptEvent);
      socket.io.off("reconnect_error", reconnectErrorEvent);
      socket.io.off("reconnect", reconnectEvent);
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (prefixoSocket?.clienteId === clienteInfo?.id) {
      setDataPendentes(
        prefixoSocket?.prefixos?.filter((pref) => pref?.tipo === 1)
      );
    }
  }, [prefixoSocket]);
  async function handleApiGetSolicitacoes() {
    try {
      setLoading(true);
      const response = await api.post("/interno/find/solicitacoes", {
        clienteId: clienteInfo?.id || "",
      });

      const pendentes = response?.data?.filter((item) => item?.tipo === 1);
      setDataPendentes(pendentes?.filter((item) => item?.status === 0));
      setDataArquivados(pendentes?.filter((item) => item?.status === 3));
    } catch (error) {
      if (error?.response?.status == 404) {
        setSnackbar({
          children: `${
            error?.response?.data?.error || "Alert: Cliente não possui prefixos"
          }`,
          severity: "warning",
        });
        setDataPendentes("");
        setDataArquivados("");
      } else {
        setSnackbar({
          children: "Error: Não foi possivel se conectar ao servidor",
          severity: "error",
        });
        setDataPendentes("");
        setDataArquivados("");
      }
    } finally {
      setLoading(false);
    }
  }

  async function handleApiGetConcluidos() {
    try {
      setLoading(true); ///interno/find/solicitacoes
      const response = await api.post("/interno/find/prefixos", {
        clienteId: clienteInfo?.id || "",
      });

      setDataConcluidos(response?.data?.filter((item) => item?.tipo === 1));
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possivel se conectar ao servidor",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  async function handleUpdate(status) {
    try {
      setLoading(true);
      const response = await api.post("/interno/update/solicitacao", {
        id: selectedPrefix?.id,
        prefixo: selectedPrefix?.prefixo,
        tipo: selectedPrefix?.tipo,
        status: status,
        clienteId: clienteInfo?.id || "",
      });

      // //on success
      if (status === 2) {
        // adiciona a concluidos
        let newDataConcluidos = [...dataConcluidos];
        const newArrayConcluidos = dataConcluidos;
        newArrayConcluidos.push({
          id: response?.id,
          prefixo: selectedPrefix?.prefixo,
          tipo: selectedPrefix?.tipo,
          status: status,
          clienteId: clienteInfo?.id || "",
        });
      } else if (status === 3) {
        //   // adiciona a arquivados
        let newDataArquivados = [...dataArquivados];
        const newArrayArquivados = dataArquivados;
        newArrayArquivados.push({
          id: response?.id,
          prefixo: selectedPrefix?.prefixo,
          tipo: selectedPrefix?.tipo,
          status: status,
          clienteId: clienteInfo?.id || "",
        });
      }

      // remove de pendentes
      let newData = [...dataPendentes];
      const newArray = dataPendentes;
      newData = newArray.filter((item) => item?.id !== selectedPrefix?.id);
      setDataPendentes(newData);
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possivel se conectar ao servidor",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  //anchor/menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      {/* ACCORDION PENDENTES */}
      <Accordion
      // sx={styles.accordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
          // sx={styles.accordionPendente}
        >
          <Typography variant="subtitle1"> Pendentes</Typography>
        </AccordionSummary>
        <Divider variant="fullWidth" />
        <AccordionDetails>
          <Grid container spacing={2}>
            {Array.isArray(dataPendentes) &&
              dataPendentes?.map((prefixo, index) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  xl={3}
                  key={`${prefixo?.id || Math.random()}-${index}`}
                >
                  <Stack flexGrow={1} direction={"row"}>
                    <TextField
                      id={"prefixos"}
                      name="prefixos"
                      label="Prefixo"
                      variant="filled"
                      autoComplete="off"
                      size="small"
                      fullWidth
                      value={prefixo?.prefixo}
                      disabled={true}
                    />
                    <IconButton
                      onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setSelectedPrefix(prefixo);
                      }}
                      disabled={loading}
                    >
                      <Settings />
                    </IconButton>
                  </Stack>
                </Grid>
              ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* ACCORDION ARQUIVADO*/}
      <Accordion
      // sx={styles.accordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
          // sx={styles.accordionPendente}
        >
          <Typography variant="subtitle1"> Arquivados</Typography>
        </AccordionSummary>
        <Divider variant="fullWidth" />
        <AccordionDetails>
          <Grid container spacing={2}>
            {Array.isArray(dataArquivados) &&
              dataArquivados?.map((prefixo, index) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  xl={3}
                  key={`${prefixo?.id || Math.random()}-${index}`}
                >
                  <Stack flexGrow={1} direction={"row"}>
                    <TextField
                      id={"prefixos"}
                      name="prefixos"
                      label="Prefixo"
                      variant="filled"
                      autoComplete="off"
                      size="small"
                      fullWidth
                      value={prefixo?.prefixo}
                      disabled={true}
                    />
                  </Stack>
                </Grid>
              ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* ACCORDION CONCLUIDOS */}
      <Accordion
      // sx={styles.accordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
          // sx={styles.accordionPendente}
        >
          <Typography variant="subtitle1"> Concluidos</Typography>
        </AccordionSummary>
        <Divider variant="fullWidth" />
        <AccordionDetails>
          <Grid container spacing={2}>
            {Array.isArray(dataConcluidos) &&
              dataConcluidos?.map((prefixo, index) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  xl={3}
                  key={`${prefixo?.id || Math.random()}-${index}`}
                >
                  <Stack flexGrow={1} direction={"row"}>
                    <TextField
                      id={"prefixos"}
                      name="prefixos"
                      label="Prefixo"
                      variant="filled"
                      autoComplete="off"
                      size="small"
                      fullWidth
                      value={prefixo?.prefixo}
                      disabled={true}
                    />
                  </Stack>
                </Grid>
              ))}
          </Grid>
        </AccordionDetails>
      </Accordion>

      {/* MENU */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            handleUpdate(2);
          }}
        >
          Aprovar
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            handleUpdate(3);
          }}
        >
          Recusar
        </MenuItem>
      </Menu>
    </Box>
  );
}
