import { useTheme } from "@mui/system";
import useStyles from "./styles";
import "dayjs/locale/pt-br";
import {
  Box,
  Button,
  Divider,
  FilledInput,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Tab,
  Tabs,
  Snackbar,
  Alert,
} from "@mui/material";
import api from "../../../../services/apiCliente";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useOutletContext } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import useStyle from "./styles";
import { Stack } from "@mui/system";
import { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers";
import { pdf } from "@react-pdf/renderer";
import { PageTemplateEasyReport } from "../../../../components/generateReport";
import { saveAs } from "file-saver";
import { LoadingButton } from "@mui/lab";
dayjs.extend(utc);
dayjs.extend(timezone);

export default function Relatorio() {
  // Rstilização
  const theme = useTheme();
  const styles = useStyles(theme);
  // Informações do cliente
  const cliente =
    JSON.parse(localStorage.getItem("userCliente"))?.usuarioCliente || "";
  // Configurações de data/calendário
  const today = new Date();
  today?.toLocaleString("en-US", {
    timeZoneName: "longOffset",
    timeZone: "America/Sao_paulo",
  });
  today?.setSeconds(0, 0);
  const twoMonths = new Date();
  twoMonths?.toLocaleString("en-US", {
    timeZoneName: "longOffset",
    timeZone: "America/Sao_paulo",
  });
  twoMonths?.setMonth(today.getMonth() - 2);
  twoMonths?.setHours(0, 0, 0, 0);
  const defaultDateInicial = new Date();
  defaultDateInicial.setMonth(today.getMonth() - 1);
  defaultDateInicial?.setHours(0, 0, 0, 0);
  const defaultDateFinal = new Date();
  defaultDateFinal?.setHours(0, 0, 0, 0);
  // Variáveis da manipulação
  const [percentil, setPercentil] = useState({});
  const [snackbar, setSnackbar] = useState(null);
  const [excendete, setExcedente] = useState(false);
  const [Null, setNull] = useState(false);
  const [invalidDateInicial, setInvalidDateInicial] = useState(false);
  const [invalidDateFinal, setInvalidDateFinal] = useState(false);
  const [errorDateInicial, setErrorDateInicial] = useState(null);
  const [errorDateFinal, setErrorDateFinal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    titulo: "",
    status: 0,
    tipo: 0,
    dataInicial: defaultDateInicial,
    dataFinal: defaultDateFinal,
    clienteId: cliente?.clienteId,
    tarifacaoExcedente: {
      status: false,
      valor: "0,00",
      valorExcedente: "0,00",
      limite: "0",
    },
  });

  // Requisições ao carregar a página
  useEffect(() => {
    handlePercentil();
  }, []);

  // Recebimento do percentil a partir do banco
  const handlePercentil = async () => {
    try {
      const response = await api.post("/cliente/percentilCliente", {
        clienteId: cliente?.clienteId,
      });

      setPercentil(response?.data);
    } catch (erro) {
      console.log(erro);
    } finally {
    }
  };

  // Configurações relacionadas a cobrança
  const handleExedente = (status) => {
    setExcedente(status);
    setFormData((prev) => ({
      ...prev,
      tarifacaoExcedente: { ...prev?.tarifacaoExcedente, status: status },
    }));
    if (!status) {
      setFormData((prev) => ({
        ...prev,
        tarifacaoExcedente: {
          ...prev?.tarifacaoExcedente,
          limite: "0",
          valorExcedente: "0,00",
        },
      }));
    }
  };
  const handleLimiteTráfego = (e) => {
    setFormData((prev) => ({
      ...prev,
      tarifacaoExcedente: {
        ...prev?.tarifacaoExcedente,
        limite: e?.target?.value?.replace(/\D/g, ""),
      },
    }));
  };

  // Formatação de dados
  const mascara = (value) => {
    const valorInteiro = value?.replace(/\D/g, "");
    const valorFormatado = (valorInteiro / 100)
      .toFixed(2)
      .replace(".", ",")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return valorFormatado;
  };
  const convertNumero = (value) => {
    return parseFloat(value?.replace(/\./g, "")?.replace(",", "."));
  };

  // Validação de Valores
  const handleValor = (e) => {
    setFormData((prev) => ({
      ...prev,
      tarifacaoExcedente: {
        ...prev?.tarifacaoExcedente,
        valor: mascara(e?.target?.value),
      },
    }));
  };
  const handleValorExcedente = (e) => {
    setFormData((prev) => ({
      ...prev,
      tarifacaoExcedente: {
        ...prev?.tarifacaoExcedente,
        valorExcedente: mascara(e?.target?.value),
      },
    }));
  };

  // Validação do datapicker
  const errorMensegeDateInicial = useMemo(() => {
    switch (errorDateInicial) {
      case "maxDate": {
        setInvalidDateInicial(true);
        return "Data inicial ultrapassou a data final";
      }
      case "maxTime": {
        setInvalidDateInicial(true);
        return "Data inicial ultrapassou a data final";
      }
      case "minDate": {
        setInvalidDateInicial(true);
        return "Data ultrapassou limite de 2 meses atrás";
      }
      case "minTime": {
        setInvalidDateInicial(true);
        return "Data ultrapassou limite de 2 meses atrás";
      }

      case "invalidDate": {
        setInvalidDateInicial(true);
        return "Necessário informar a data completa";
      }

      default: {
        setInvalidDateInicial(false);
        return "";
      }
    }
  }, [errorDateInicial]);
  const errorMensegeDateFinal = useMemo(() => {
    switch (errorDateFinal) {
      case "maxDate": {
        setInvalidDateFinal(true);
        return "Data final inválida!";
      }
      case "maxTime": {
        setInvalidDateFinal(true);
        return "Data final inválida!";
      }
      case "minDate": {
        setInvalidDateFinal(true);
        return "Data ultrapassou limite de 2 meses atrás";
      }
      case "minTime": {
        setInvalidDateFinal(true);
        return "Data ultrapassou limite de 2 meses atrás";
      }

      case "invalidDate": {
        setInvalidDateFinal(true);
        return "Necessário informar a data completa";
      }

      default: {
        setInvalidDateFinal(false);
        return "";
      }
    }
  }, [errorDateFinal]);

  // Requisição
  const handleDados = async () => {
    if (
      !formData?.titulo?.trim() ||
      (formData?.tarifacaoExcedente?.status &&
        (!formData?.tarifacaoExcedente?.limite ||
          !formData?.tarifacaoExcedente?.valorExcedente ||
          !formData?.tarifacaoExcedente?.valor)) ||
      !formData?.dataFinal ||
      !formData?.dataFinal ||
      invalidDateFinal ||
      invalidDateInicial
    ) {
      setNull(true);
    } else {
      setLoading(true);
      try {
        const response = await api.post("/cliente/relatorio", {
          clienteId: cliente?.clienteId,
          initial_date: formData?.dataInicial,
          final_date: formData?.dataFinal,
          tarifacao: formData?.tarifacaoExcedente?.valor,
        });

        const relatorio = {
          titulo: formData?.titulo,
          client: response?.data?.client,
          status: 0,
          tipo: 1,
          dataInicial: formData?.dataInicial,
          dataFinal: formData?.dataFinal,
          hideCharts: false,
          percentil: percentil?.percentil || 95,
          analysis: response?.data?.analysis,
          interfaces: response?.data?.interfaces,
          tarifacaoExcedente: {
            status: formData?.tarifacaoExcedente?.status,
            valor: convertNumero(formData?.tarifacaoExcedente?.valor),
            valorExcedente: convertNumero(
              formData?.tarifacaoExcedente?.valorExcedente
            ),
            limite: parseInt(formData?.tarifacaoExcedente?.limite),
          },
        };

        const MyDoc = <PageTemplateEasyReport relatorio={relatorio} />;
        let blob = await pdf(MyDoc).toBlob();
        saveAs(blob, `relatiorio ${relatorio?.client}.pdf`);

        setSnackbar({
          children: "Gerado relatório",
          severity: "success",
        });
        setNull(false);
      } catch (erro) {
        setSnackbar({
          children: "Error: Não foi possivel se conectar ao servidor",
          severity: "error",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Box sx={styles.container}>
      <Paper
        sx={styles.containerPaper}
        elevation={3}
        variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
      >
        <>
          <Typography sx={{ fontSize: "21px" }}>Relatório</Typography>
          <Paper variant="outlined" sx={styles.paper}>
            <Stack direction={"column"}>
              <Typography sx={styles.subtitle} variant="subtitle2">
                Título do relatório
              </Typography>
              <TextField
                size="small"
                label="Título"
                variant="filled"
                autoComplete="off"
                helperText={
                  !formData?.titulo?.trim() && Null
                    ? "Necessário informar um título"
                    : ""
                }
                disabled={loading}
                error={!formData?.titulo?.trim() && Null}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, titulo: e.target.value }))
                }
              />
            </Stack>
            <Divider sx={styles.divider}></Divider>
            <Box sx={styles.containerExcedente}>
              <FormControl>
                <Typography sx={styles.subtitle}>
                  Cobrança por excedente?
                </Typography>
                <RadioGroup
                  defaultValue="small"
                  name="radio-buttons-group"
                  value={excendete}
                  sx={styles.radioGrupo}
                >
                  <Radio
                    value={false}
                    label="Nao"
                    size="md"
                    onChange={() => handleExedente(false)}
                    defaultChecked
                    disabled={loading}
                  />
                  Não
                  <Radio
                    value={true}
                    label="Sim"
                    size="md"
                    onChange={() => handleExedente(true)}
                    disabled={loading}
                  />
                  Sim
                </RadioGroup>
              </FormControl>
            </Box>

            <Typography sx={styles.subtitle} variant="subtitle2">
              Adicionar valor por Mbps
            </Typography>

            <Box sx={styles.stack}>
              <Box sx={styles.containerCheck}>
                <FormControl
                  error={!formData?.tarifacaoExcedente?.valor && Null}
                  size="small"
                  variant="filled"
                  sx={styles.valorMbps}
                >
                  <InputLabel>Valor por Mbps</InputLabel>
                  <FilledInput
                    value={formData?.tarifacaoExcedente?.valor}
                    onChange={handleValor}
                    startAdornment={
                      <InputAdornment position="start">R$</InputAdornment>
                    }
                    disabled={loading}
                  />
                </FormControl>
              </Box>
              {excendete && (
                <>
                  <FormControl
                    size="small"
                    variant="filled"
                    sx={{ width: "100%" }}
                  >
                    <InputLabel
                      error={!formData?.tarifacaoExcedente?.limite && Null}
                      htmlFor="filled-adornment-amount"
                    >
                      Limite de tráfego
                    </InputLabel>
                    <FilledInput
                      value={formData?.tarifacaoExcedente?.limite}
                      onChange={handleLimiteTráfego}
                      disabled={loading}
                    />
                    <FormHelperText id="filled-weight-helper-text">
                      Em Mbps
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    size="small"
                    variant="filled"
                    sx={{ width: "100%" }}
                  >
                    <InputLabel
                      error={
                        !formData?.tarifacaoExcedente?.valorExcedente && Null
                      }
                      htmlFor="filled-adornment-amount"
                    >
                      Valor do excedente
                    </InputLabel>
                    <FilledInput
                      value={formData?.tarifacaoExcedente?.valorExcedente}
                      onChange={handleValorExcedente}
                      startAdornment={
                        <InputAdornment position="start">R$</InputAdornment>
                      }
                      disabled={loading}
                    />
                  </FormControl>
                </>
              )}
            </Box>

            <Divider sx={styles.divider}></Divider>
            <Typography sx={styles.subtitle} variant="subtitle2">
              Intervalo de coleta
            </Typography>

            <Box sx={styles.containerDate}>
              <LocalizationProvider
                adapterLocale="pt-br"
                dateAdapter={AdapterDayjs}
              >
                <DateTimePicker
                  timezone="America/Sao_paulo"
                  onError={(newErro) => {
                    setErrorDateInicial(newErro);
                  }}
                  slotProps={{
                    textField: {
                      helperText: errorMensegeDateInicial,
                    },
                  }}
                  label="Data inicial"
                  defaultValue={dayjs(defaultDateInicial)}
                  maxDateTime={dayjs(formData?.dataFinal)}
                  minDateTime={dayjs(twoMonths)}
                  onChange={(newValue) =>
                    setFormData((prev) => ({
                      ...prev,
                      dataInicial: dayjs(newValue).second(0),
                    }))
                  }
                  disabled={loading}
                />
              </LocalizationProvider>
              <LocalizationProvider
                adapterLocale="pt-br"
                dateAdapter={AdapterDayjs}
              >
                <DateTimePicker
                  timezone="America/Sao_paulo"
                  label="Data final"
                  onError={(newErro) => {
                    setErrorDateFinal(newErro);
                  }}
                  slotProps={{
                    textField: {
                      helperText: errorMensegeDateFinal,
                    },
                  }}
                  defaultValue={dayjs(defaultDateFinal)}
                  onChange={(newValue) =>
                    setFormData((prev) => ({
                      ...prev,
                      dataFinal: dayjs(newValue).second(0),
                    }))
                  }
                  maxDateTime={dayjs(today)}
                  minDateTime={dayjs(twoMonths)}
                  inputFormt={"DD/MM/YYYY"}
                  disabled={loading}
                />
              </LocalizationProvider>
            </Box>
            <Divider sx={styles.divider}></Divider>
            <Stack spacing={2} alignItems={"end"}>
              <LoadingButton
                loading={loading}
                onClick={handleDados}
                variant="contained"
                size="medium"
              >
                Gerar relatório
              </LoadingButton>
            </Stack>
          </Paper>
        </>
      </Paper>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
