import { display, margin, textAlign } from "@mui/system";

const styles = (theme) => ({
  accordion: {
    backgroundColor: theme.palette.mode === "dark" ? "transparent" : "none",
    marginTop: "14px",
  },
  accordionSummaryBox: { display: "flex", width: "100%", flexDirection: "row" },
  accordionSummaryItem: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  itemTitle: {
    fontWeight: 600,
    mr: 1,
    fontSize: "14px",
    display: "flex",
    alingItens: "center",
    flexDirection: "row",
  },
  iconTitle: {
    fontSize: "16px",
    mr: "2px",
    mb: -0.3,
  },
  itemText: {
    display: "flex",
    flexDirection: "row",
  },
  title: {
    fontSize: "24px",
    fontWeight: 900,
  },
  container: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  containerLoading: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  containerComponent: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    marginTop: "10px",
  },
  typography: {
    fontWeight: 600,
    opacity: "0.7",
    mr: "2px",
  },
  datagrid: {
    display: "grid",
    "&.MuiDataGrid-root": { borderStyle: "none" },
    "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
      outline: "none !important",
    },
    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
      py: "5px",
    },
    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
      py: "15px",
    },
    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
      py: "22px",
    },
    '&.MuiDataGrid-root .MuiDataGrid-container--top [role="row"], & .MuiDataGrid-root .MuiDataGrid-container--bottom [role="row"]':
      {
        backgroundColor: "background.paper",
      },
    "& .super-app.positivo_ataque": {
      fontWeight: "600",
    },
  },
});

export default styles;
