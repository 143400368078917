const styles = (theme) => ({
  container: {
    width: "100%",
    alignItems: "center",
    flexDirection: "column",
    display: "flex",
    gap: 6,
  },
  containerTitulo: {
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
  },
  containerFotoUser: {
    display: "flex",
    alignItems: "center",
  },
  containerAvatar: {
    width: "34px",
    height: "34px",
    margin: "5px 5px",
  },
  person: {
    color: "primary.main",
  },
  personOff: {
    color: "secondary.main",
  },
  containerIconTime: {
    fontSize: "15px",
    color: "chip.inProgress",
    zIndex: 1,
    position: "absolute",
    bottom: 4,
    left: 11,
    bottom: 16,
    borderRadius: "50%",
    backgroundColor: "background.paper",
  },
  containerPrimeiroAcesso: {
    cursor: "pointer",
    position: "relative",
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  boxHeader: { display: "flex", flexDirection: "column", gap: 1 },
  paper: {
    alignItems: "center",
    width: "83%",
    padding: "20px",
    justifyContent: "center",
    backgroundColor: theme.palette.mode === "dark" && "transparent",
    mt: "16px",
  },
  containerButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  containerAdd: {
    marginTop: "-3px",
  },
  buttomAddUser: {
    fontSize: "16px",
    height: "46px",
  },
  BoxStack: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    mb: "20px",
  },
  containerBuscar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  containerData: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  conatinerChip: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  chipPermissoes: {
    // backgroundColor: "chip.waitingReview",
    height: "22px",
    fontWeight: 500,
    color: "#ffffff",
    width: "140px",
    height: "25px",
  },
  dataGrid: {
    display: "grid",
    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
      py: "5px",
    },
    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {},
    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
      py: "22px",
    },
    fontSize: 15,
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
      outline: "none !important",
    },
  },
});

export default styles;
