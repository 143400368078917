import { useTheme } from "@mui/system";
import useStyles from "./styles";
import { Box, Tabs, Tab } from "@mui/material";

import { useState } from "react";

import AdminUsuarioCliente from "./usuario";
import Relatorio from "./relatorio";

export default function AdminPainelCliente() {
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const styles = useStyles(theme);

  //Change tabs handler
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.containerTabs}>
        <Tabs
          //sx={{ marginTop: "-11px" }}
          value={value}
          onChange={handleChange}
          aria-label="basics tabs config"
        >
          <Tab label="USUÁRIOS" {...a11yProps(0)} />
          <Tab label="RELATÓRIO" {...a11yProps(1)} disabled />
        </Tabs>
      </Box>
      {value === 0 && <AdminUsuarioCliente />}
      {value === 1 && <Relatorio />}
    </Box>
  );
}
