import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Box,
  LinearProgress,
  Paper,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import LineChart from "./charts/line";
import CountriesTable from "./tables/countries";
import RecentEventsTable from "./tables/recentEvents";
import Grid from "@mui/material/Unstable_Grid2";
import useStyles from "./styles";
import dayjs from "dayjs";
import Prefix from "./tables/prefix";
import { RangeContext } from "../../pages/client/range";

export default function Dashboard(params) {
  const {
    loadingOpTraffic,
    loadingOpTrafficPps,
    data,
    prefix,
    loading,
    loadingPrefix,
    loadingNetworkSummary,
    networkSummary,
    recentEvents,
    loadingRecentEvents,
    snackbar,
    setSnackbar,
  } = params;
  // Estilização
  const theme = useTheme();
  const styles = useStyles();

  const [tableSelected, setTableSelected] = useState("country");
  // Faixa de exibição
  const { Range } = useContext(RangeContext);

  // Seleção de informações da rede
  const handleSelectedTable = (table) => {
    setTableSelected(table);
  };

  // Escalas de exibição de Tráfego
  const maxValuebps = Math.max(
    ...(data[
      `${Range.unidade === "bps" ? "mitigation_bps" : "mitigation_pps"}`
    ]?.clean?.map((d) => d?.value || 0) || [0]),
    ...(data[
      `${Range.unidade === "bps" ? "mitigation_bps" : "mitigation_pps"}`
    ].bot?.map((d) => d?.value || 0) || [0])
  );

  const maxIndividualClean = Math.max(
    ...(data[
      `${Range.unidade === "bps" ? "mitigation_bps" : "mitigation_pps"}`
    ]?.clean?.map((d) => d?.value || 0) || [0])
  );

  const maxIndividualBot = Math.max(
    ...(data[
      `${Range.unidade === "bps" ? "mitigation_bps" : "mitigation_pps"}`
    ].bot?.map((d) => d?.value || 0) || [0])
  );

  // Quantificação para a tabela de países
  const formatPackgeAcumulate = (bytes) => {
    if (bytes === 0) return "0 pps";
    const k = 1000;
    const sizes = ["M", "K", "M", "G", "T", "P", "E"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const formatBytes = (bytes) => {
    if (bytes === 0) return "0 bps";
    const k = 1000;
    const sizes = ["bps", "Kbps", "Mbps", "Gbps", "Tbps", "Pbps", "Ebps"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const formatPackge = (bytes) => {
    if (bytes === 0) return "0 pps";
    const k = 1000;
    const sizes = ["pps", "Kpps", "Mpps", "Gbps", "Tpps", "Ppps", "Epps"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const totalSize = networkSummary?.[
    `${Range.unidade === "bps" ? "networkSummary" : "networkSummaryPps"}`
  ]?.country?.reduce((acc, country) => acc + (country.size || 0), 0);

  return (
    <Box sx={styles.container}>
      {(loadingOpTraffic || loadingOpTrafficPps) && (
        <Box sx={{ width: "100%", position: "absolute" }}>
          <LinearProgress />
        </Box>
      )}
      <Grid
        container
        spacing={2}
        columns={{ xs: 2, sm: 4, md: 6, lg: 6, xl: 6, xxl: 6 }}
        flexGrow={1}
      >
        <Grid xs={2} sm={4} md={6} lg={6} xl={3}>
          <Paper
            sx={styles.paper}
            elevation={theme.palette.mode === "dark" ? 0 : 3}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                width: "100%",
              }}
            >
              <Typography
                sx={{ ...styles.cardSubText, color: "secondary.main" }}
              >
                Tráfego DDOS
              </Typography>
              <LineChart
                type={"sujo"}
                range={Range}
                maxValuebps={
                  Range?.escala === "padrao" ? maxValuebps : maxIndividualBot
                }
                data={
                  data?.[
                    `${
                      Range.unidade === "bps"
                        ? "mitigation_bps"
                        : "mitigation_pps"
                    }`
                  ]?.bot?.map((bot, index) => {
                    let result = {};

                    (result = {
                      date: dayjs(bot.date).format(
                        Range === "hour"
                          ? "HH:mm"
                          : Range === "day"
                          ? "HH:mm"
                          : Range === "week"
                          ? "DD/MM HH:mm"
                          : "DD/MM HH:mm"
                      ),
                      dirtyTraffic: bot.value,
                    }) || (result = { date: 0, dirtyTraffic: 0 });

                    return result;
                  }) || []
                }
              />
            </Box>
          </Paper>
        </Grid>

        <Grid xs={2} sm={4} md={6} lg={6} xl={3}>
          <Paper
            sx={styles.paper}
            elevation={theme.palette.mode === "dark" ? 0 : 3}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                width: "100%",
              }}
            >
              <Typography sx={{ ...styles.cardSubText, color: "#00A76F" }}>
                Tráfego Limpo
              </Typography>
              <LineChart
                maxValuebps={
                  Range?.escala === "padrao" ? maxValuebps : maxIndividualClean
                }
                load={loading}
                type={"limpo"}
                range={Range}
                data={
                  data?.[
                    `${
                      Range.unidade === "bps"
                        ? "mitigation_bps"
                        : "mitigation_pps"
                    }`
                  ]?.clean?.map((clean, index) => {
                    let result = {};

                    (result = {
                      date: dayjs(clean.date).format(
                        Range === "hour"
                          ? "HH:mm"
                          : Range === "day"
                          ? "HH:mm"
                          : Range === "week"
                          ? "DD/MM HH:mm"
                          : "DD/MM HH:mm"
                      ),
                      cleanTraffic: clean.value,
                    }) || (result = { date: 0, cleanTraffic: 0 });

                    return result;
                  }) || []
                }
              />
            </Box>
          </Paper>
        </Grid>

        <Grid xs={2} sm={4} md={6} lg={6}>
          <Grid
            container
            spacing={2}
            columns={{ xs: 2, sm: 4, md: 8, lg: 8, xl: 8, xxl: 8 }}
            flexGrow={1}
          >
            <Grid xs={2} sm={4} md={8} lg={4}>
              <Paper
                sx={{ ...styles.paper }}
                elevation={theme.palette.mode === "dark" ? 0 : 3}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    width: "100%",
                    position: "relative",
                    height: "100%",
                  }}
                >
                  <Typography sx={styles.cardSubText}>
                    Prefixos Recebidos
                  </Typography>
                  <Prefix
                    rows={
                      prefix?.map((prefix, index) => ({
                        ...prefix,
                        id: index,
                        timestamp: dayjs(prefix.timestamp).format(
                          "DD/MM/YYYY HH:mm:ss"
                        ),
                      })) || []
                    }
                    hideFooter={true}
                    load={loadingPrefix}
                  />
                </Box>
              </Paper>
            </Grid>
            <Grid xs={2} sm={4} md={8} lg={4}>
              <Paper
                sx={styles.paper}
                elevation={theme.palette.mode === "dark" ? 0 : 3}
              >
                <Box sx={{ width: "100%" }} overflow={"auto"}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      //flexGrow: 1,
                      justifyContent: "space-between",
                      width: "97.6%",
                    }}
                  >
                    <Typography sx={styles.cardSubText}>
                      Informações da Rede
                    </Typography>
                    <ToggleButtonGroup
                      color="primary"
                      value={tableSelected}
                      exclusive
                      //onChange={handleChange}
                      aria-label="Platform"
                      size="small"
                    >
                      <ToggleButton
                        value="country"
                        onClick={() => {
                          handleSelectedTable("country");
                        }}
                      >
                        Países
                      </ToggleButton>
                      <ToggleButton
                        value="proto"
                        onClick={() => {
                          handleSelectedTable("proto");
                        }}
                      >
                        Protocolos
                      </ToggleButton>
                      <ToggleButton
                        value="as_num"
                        onClick={() => {
                          handleSelectedTable("as_num");
                        }}
                      >
                        ASN
                      </ToggleButton>
                      <ToggleButton
                        value="ip"
                        onClick={() => {
                          handleSelectedTable("ip");
                        }}
                      >
                        IP Atacado
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                  <CountriesTable
                    load={loadingNetworkSummary}
                    tableSelected={tableSelected}
                    rows={
                      networkSummary?.networkSummary?.country &&
                      Array.from(networkSummary?.networkSummary?.country)
                        ? networkSummary?.[
                            `${
                              Range.unidade === "bps"
                                ? "networkSummary"
                                : "networkSummaryPps"
                            }`
                          ]?.[`${tableSelected}`].map((country, index) => ({
                            ...country,
                            id: index,
                            quantidade: formatPackgeAcumulate(
                              country?.size || 0
                            ),
                            code: country.code,
                            pais: country.country,
                            porcentagem:
                              ((country.size || 0) / totalSize) * 100,
                          }))
                        : []
                    }
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={2} sm={4} md={6} lg={6} xl={6}>
          <Paper
            sx={{ ...styles.paper, height: "auto" }}
            elevation={theme.palette.mode === "dark" ? 0 : 3}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                width: "100%",
                position: "relative",
              }}
            >
              <Typography sx={styles.cardSubText}>Eventos Recentes</Typography>

              <RecentEventsTable
                rows={
                  recentEvents.map((event) => {
                    return {
                      dataInicial: parseInt(event?.from?.unixtime) || "",
                      dataFinal: parseInt(event?.until?.unixtime) || "",
                      impact: event?.bits || 0,
                      impactPps: event?.pkts || 0,
                      ...event,
                    };
                  }) || []
                }
                load={loadingRecentEvents}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
