import { React, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Stack,
  IconButton,
  Typography,
  useTheme,
  Paper,
  Tooltip,
  Menu,
  MenuItem,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { Edit, MoreVert } from "@mui/icons-material";
import useStyles from "./styles";
import ModalEditar from "./../modalEditTunel";
import apiCliente from "../../../../services/apiCliente";
export default function TuneisCliente(params) {
  const theme = useTheme();
  const [snackbar, setSnackbar] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [openEditar, setOpenEditar] = useState(false);
  const [tuneis, setTuneis] = useState({});
  const styles = useStyles(theme);
  const [loading, setLoading] = useState(true);
  const open = Boolean(anchorEl);
  useEffect(() => {
    getAllTunneis();
  }, []);

  async function getAllTunneis() {
    setLoading(true);
    try {
      const response = await apiCliente.post("/solicitacoes/get/tuneis", {
        clienteId: JSON.parse(
          localStorage.getItem("userCliente")
        )?.usuarioCliente?.clientId?.toString(),
      });
      setTuneis(response?.data);
      setLoading(false);
    } catch (e) {
      console.error("erro ao buscar prefixos", e);
    }
  }

  function handleUpdated(formData) {
    let newArray = tuneis?.map((data) => {
      if (data?.id === formData?.id) {
        return formData;
      }
      return data;
    });
    setTuneis(newArray);
  }

  //////////////////TUNEIS////////////////////
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {loading ? (
        <Box sx={styles.containerLoading}>
          {" "}
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          {Array.isArray(tuneis) &&
            tuneis.map((tunnel, index) => (
              <Grid item lg={12} xl={6}>
                <Paper
                  key={tunnel}
                  value={index}
                  variant="outlined"
                  sx={styles.paper}
                >
                  <Box sx={styles.cardHeader}>
                    <Box>
                      <Typography variant="caption" sx={styles.cardSubTitle}>
                        Título do Túnel
                      </Typography>
                      <Typography>{tunnel.nome}</Typography>
                    </Box>
                    <IconButton
                      onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setSelectedCard(tunnel);
                      }}
                    >
                      <MoreVert />
                    </IconButton>
                  </Box>
                  <Box sx={styles.cardContent}>
                    <Box sx={styles.cardRow}>
                      <Stack direction={"column"} sx={styles.cardStack}>
                        <Typography sx={styles.cardSubTitle}>
                          Tunnel Local
                        </Typography>
                        <Tooltip>
                          <span
                          /* onClick={() => {
                          handleCopy(tunnel?.ipTunnelLocal);
                        }} */
                          >
                            <Typography sx={styles.cardText}>
                              {tunnel.ipTunnelLocal}
                            </Typography>
                          </span>
                        </Tooltip>
                      </Stack>

                      <Stack direction={"column"} sx={styles.cardStack}>
                        <Typography sx={styles.cardSubTitle}>
                          Tunnel Cliente
                        </Typography>
                        <Tooltip>
                          <span
                          /*  onClick={() => {
                          handleCopy(tunnel?.ipTunnelCliente);
                        }} */
                          >
                            <Typography sx={styles.cardText}>
                              {tunnel.ipTunnelCliente}
                            </Typography>
                          </span>
                        </Tooltip>
                      </Stack>
                    </Box>
                    <Box sx={styles.cardRow}>
                      <Stack direction={"column"} sx={styles.cardStack}>
                        <Typography sx={styles.cardSubTitle}>Source</Typography>
                        <Tooltip>
                          <span
                          /*  onClick={() => {
                          handleCopy(tunnel?.ipSource);
                        }} */
                          >
                            <Typography sx={styles.cardText}>
                              {tunnel.ipSource}
                            </Typography>
                          </span>
                        </Tooltip>
                      </Stack>
                      <Stack direction={"column"} sx={styles.cardStack}>
                        <Typography sx={styles.cardSubTitle}>
                          Destination
                        </Typography>
                        <Tooltip>
                          <span
                          /*  onClick={() => {
                          handleCopy(tunnel?.ipDestiny);
                        }} */
                          >
                            <Typography sx={styles.cardText}>
                              {tunnel.ipDestiny}
                            </Typography>
                          </span>
                        </Tooltip>
                      </Stack>
                    </Box>
                    <Box sx={styles.cardRow}>
                      <Stack direction={"column"} sx={styles.cardStack}>
                        <Typography sx={styles.cardSubTitle}>
                          Tunnel ID
                        </Typography>
                        <Tooltip>
                          <span
                          /* onClick={() => {
                          handleCopy(tunnel?.idTunnelCliente);
                        }} */
                          >
                            <Typography sx={styles.cardText}>
                              {tunnel.idTunnelCliente}
                            </Typography>
                          </span>
                        </Tooltip>
                      </Stack>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            ))}
        </Grid>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            setOpenEditar(true);
            setAnchorEl(null);
          }}
        >
          <Edit sx={{ marginRight: "16px" }}></Edit> Editar
        </MenuItem>
      </Menu>
      <ModalEditar
        openEditar={openEditar}
        setOpenEditar={setOpenEditar}
        selectedCard={selectedCard}
        setSnackbar={setSnackbar}
        handleUpdated={handleUpdated}
      />

      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
