import {
  AccountBalance,
  AttachMoneyOutlined,
  CalendarTodayOutlined,
  DescriptionOutlined,
  EventAvailableOutlined,
  ExpandMore,
  MoneyOffOutlined,
  Person2Outlined,
  TaskOutlined,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import api from "../../../../services/api";
import useStyles from "./styles";
import dayjs from "dayjs";
import { useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

export default function Financeiro() {
  const theme = useTheme();
  const styles = useStyles(theme);
  const [contratos, setContratos] = useState([]);
  const [servicos, setServicos] = useState({});
  const [setSnackbar, selectedRow, setSelectedRow] = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [windowWith, setWindowWith] = useState(window.innerWidth);
  const [loadingService, setLoadingService] = useState(false);
  useEffect(() => {
    if (selectedRow?.cnpj) {
      getContratos(selectedRow?.cnpj);
    }
  }, [selectedRow]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWith(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
  }, []);

  async function getContratos(cnpj) {
    setLoading(true);

    try {
      const response = await api.post("/contratos/brbyte", {
        cnpj: cnpj,
      });

      setContratos(response?.data?.contratos?.contratosDetails);
      response?.data?.contratos?.contratosDetails.forEach(async (contr) => {
        const servicos = await getServicos(contr?.contract_number);

        setServicos((prevServicos) => ({
          ...prevServicos,
          [contr.contract_number]: servicos,
        }));
      });
      return response?.data?.contratos?.contratosDetails;
    } catch (error) {
      if (error?.response?.status == 404) {
        setSnackbar({
          children: `${
            error?.response?.data?.error ||
            "Error: Não foi possivel encontrar contratos"
          }`,
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Error: Não foi possivel se conectar ao servidor",
          severity: "error",
        });
      }
    } finally {
      setLoading(false);
    }
  }

  async function getServicos(numeroContrato) {
    setLoadingService(true);
    try {
      const response = await api.post("/contratos/services/brbyte", {
        numeroContrato: numeroContrato,
      });

      return response?.data?.services?.servicesDetails;
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingService(false);
    }
  }

  const converteTipo = {
    1: "Internet",
    2: "Cobrança",
    3: "Desconto",
    4: "Generico - Integração",
    5: "Generico - Equipamento",
    6: "Generico - Suporte OS",
    7: "Generico - Suporte Adicional",
    8: "Sistema - Juros",
    9: "Sistema - Desconto",
    10: "Sistema - Excedido",
    11: "Sistema - Faltante",
    12: "Generico - Outros",
    13: "Generico - Produtos",
    14: "Prefixo",
  };
  const getRows = (contratoNumber) => {
    let data =
      servicos[contratoNumber]?.map((service) => {
        const dadosRows = {
          id: service?.item_pk || "",
          descrição: service?.item_name || "-",
          autenticação: service?.cpe_pk || "-",
          tipo: converteTipo[service?.item_type] || "-",
          nome: service?.plan_name || "-",
          valor: service?.item_amount || "-",
        };

        return dadosRows;
      }) || [];
    if (data.length > 0) {
      data.sort((a, b) => a.id - b.id);
    }
    return data;
  };
  const columns = [
    { field: "id", headerName: "ID", minWidth: 60, flex: 1 },
    { field: "descrição", headerName: "Descrição", minWidth: 130, flex: 1 },
    {
      field: "autenticação",
      headerName: "Autenticação",
      minWidth: 120,
      flex: 1,
    },
    { field: "tipo", headerName: "Tipo", minWidth: 80, flex: 1 },
    { field: "nome", headerName: "Nome", minWidth: 80, flex: 1 },
    { field: "valor", headerName: "Valor", minWidth: 100, flex: 1 },
  ];

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.title}>Contratos</Typography>

      {loading ? (
        <Box sx={styles.containerLoading}>
          <CircularProgress size={60} />
        </Box>
      ) : (
        <Box sx={styles.containerComponent}>
          {contratos.map((contrato, index) => (
            <Accordion
              key="index"
              variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
              sx={styles.accordion}
              defaultExpanded
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Grid
                  container
                  direction={"row"}
                  sx={styles.accordionSummaryBox}
                  spacing={2}
                  columns={windowWith > 1400 ? 16 : windowWith > 900 ? 8 : 4}
                  display={"flex"}
                  justifyContent={"center"}
                >
                  <Grid item xs={2} sx={styles.accordionSummaryItem}>
                    <Typography sx={styles.itemTitle}>
                      <AccountBalance color="secondary" sx={styles.iconTitle} />
                      Banco
                    </Typography>
                    <Typography sx={styles.itemText}>
                      {contrato.bank_account_identification}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sx={styles.accordionSummaryItem}>
                    <Typography sx={styles.itemTitle}>
                      <DescriptionOutlined
                        color="secondary"
                        sx={styles.iconTitle}
                      />
                      Contrato
                    </Typography>
                    <Typography sx={styles.itemText}>
                      {contrato.contract_number}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sx={styles.accordionSummaryItem}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography sx={styles.itemTitle}>
                        <CalendarTodayOutlined
                          color="secondary"
                          sx={styles.iconTitle}
                        />
                        Dia
                      </Typography>
                      <Typography sx={styles.itemText}>
                        {contrato.contract_pay_day}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sx={styles.accordionSummaryItem}>
                    <Typography sx={styles.itemTitle}>
                      <AttachMoneyOutlined
                        color="secondary"
                        sx={styles.iconTitle}
                      />
                      Valor
                    </Typography>
                    <Typography sx={styles.itemText}>
                      <Typography sx={styles.typography}> R$: </Typography>
                      {parseFloat(contrato?.contract_amount)?.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sx={styles.accordionSummaryItem}>
                    <Typography sx={styles.itemTitle}>
                      <MoneyOffOutlined
                        color="secondary"
                        sx={styles.iconTitle}
                      />
                      Desconto
                    </Typography>
                    <Typography sx={styles.itemText}>
                      <Typography sx={styles.typography}> R$: </Typography>

                      {contrato.contract_pay_discount}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sx={styles.accordionSummaryItem}>
                    <Typography sx={styles.itemTitle}>
                      <Person2Outlined
                        color="secondary"
                        sx={styles.iconTitle}
                      />
                      Perfil
                    </Typography>
                    <Typography sx={styles.itemText}>
                      {contrato.profile_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sx={styles.accordionSummaryItem}>
                    <Typography sx={styles.itemTitle}>
                      <EventAvailableOutlined
                        color="secondary"
                        sx={styles.iconTitle}
                      />
                      Cadastro
                    </Typography>
                    <Typography sx={styles.itemText}>
                      {dayjs(contrato.contract_date_cad).format("DD/MM/YYYY")}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sx={styles.accordionSummaryItem}>
                    <Typography sx={styles.itemTitle}>
                      <TaskOutlined color="secondary" sx={styles.iconTitle} />
                      Ativação
                    </Typography>
                    <Typography sx={styles.itemText}>
                      {dayjs(contrato.contract_date_activation).format(
                        "DD/MM/YYYY"
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <DataGrid
                  loading={loadingService}
                  rows={getRows(contrato?.contract_number)}
                  columns={columns}
                  disableColumnMenu
                  disableRowSelectionOnClick
                  hideFooter={false}
                  autoHeight={true}
                  density="compact"
                  pageSizeOptions={[5, 10]}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 1, pageSize: 5 },
                    },
                  }}
                  sx={styles.datagrid}
                />
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      )}
    </Box>
  );
}
